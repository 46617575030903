.footer {
  padding-bottom: 0;
  background: #0A0A0A;
  border-top: 1px solid $color-pri;
  font-family: "Inter";

  &-wrap {
    position: relative;
  }
  &-flex {
    display: flex;
    margin: 0 -1.2rem;
    justify-content: space-between;
    padding: 8rem 0;

    @include max(1200px) {
      flex-wrap: wrap;
      row-gap: 2rem;
    }
    @include max(768px) {
      padding: 4rem 0;
    }
  }
  &-col {
    padding: 0 1.2rem;
    color: #F3F4F6;
    min-width: 20%;

    &:nth-child(1) {
      @include max(450px) {
        width: 100%;

        .custom-logo-link {
          width: 50%;
        }

        br {
          display: none;
        }
      }
    }

    .menu-list {
      margin-top: 1.6rem;
      .menu-item {
        margin-bottom: 0.8rem;

        .menu-link {
          width: max-content;
          display: block;
        }
      }
    }

  }
  &-logo {
    margin-bottom: 1.6rem;
    .custom-logo-link {
      display: block;
      width: calc((68/205) * 100vw);
    }
  }
  &-title {
    font-weight: 600;
    color: white;
  }

  &-info {
    margin-bottom: 1.2rem;

    &-link {
      width: max-content;
    }
  }


  &-social {
    display: flex;
    gap: 1.6rem;
    margin-top: 1.6rem;

    &-link {
      width: 2.8rem;
      height: 2.8rem;
      @include mid-flex;

      img {
        width: 100%;
        object-fit: contain;
        transition: 0.3s ease-in-out;
      }
      @include hover {
        img {
          transform: translateY(-0.5rem);
        }
      }
    }
  }

  &-sign {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 3rem 0;
    position: relative;

    &::before {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      height:0.1rem;
      width: 100vw;
      content: "";
      background-color: rgba(255, 255, 255, 0.10);;
    }

    .text {
      color: white;
      @include fz-14;
      opacity: 0.7;
    }

    .icon {
      display: flex;
      height: 2.4rem;
      margin-bottom: 0.2rem;

      img {
        object-fit: contain;
        height: 100%;
      }
    }
  }
}

.fsocial {
  position: fixed;
  bottom: 13rem;
  right: 3rem;
  z-index: 55;

  @include max(1200px) {
    bottom: 9rem;
  }

  @include max(768px) {
    right: 1.5rem;
  }
  transition: 0.5s linear;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  .icon {
    height: 8rem;
    width: 8rem;
    display: flex;
    margin-top: 2rem;
    transition: 0.3s ease-in-out;
    padding: 2rem 0.8rem;
    position: relative;
    border: 0.2rem solid white;
    border-radius: 100rem;
    background: white;

    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @include max(1200px) {
      width: 4rem;
      height: 4rem;
      padding: 1rem 0.5rem;
    }

    img {
      object-fit: contain;
    }

    .circlejs {
      animation: growAndFade 3s infinite ease-out;
      background-color: dodgerblue;
      border-radius: 50%;
      opacity: 0;
      position: absolute;
      height: 6.7rem;
      width: 6.7rem;
      left: 50%;
      top: 50%;
      z-index: -1;
    }
    .circle1 {
      animation-delay: 1s;
    }
    .circle2 {
      animation-delay: 2s;
    }
    .circle3 {
      animation-delay: 3s;
    }

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      z-index: 0;
      border-radius: 100rem;
    }
    @include hover {
      transform: scale(1.1);
    }

    img {
      width: 100%;
      height: auto;
      -webkit-animation: shake-lr 4s linear infinite both;
      animation: shake-lr 4s linear infinite both;
    }

    //   &:nth-child(1) {
    //     &:before {
    //       background-color: $color-pri;
    //     }
    //     .circlejs {
    //       background-color: #fff;
    //     }
    //   }
    //   &:nth-child(2) {
    //     &:before {
    //       background-color: rgba(0, 104, 255, 0.2);
    //     }
    //     .circlejs {
    //       background-color: rgba(0, 104, 255);
    //     }
    //   }
  }
}

@-webkit-keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  10% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
  90% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
}
@keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  10% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
  90% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-22 14:11:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-vertical
 * ----------------------------------------
 */
.shake-vertical {
  -webkit-animation: shake-vertical 4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    infinite both;
  animation: shake-vertical 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite
    both;
}
@-webkit-keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}
@keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}
