.quantity {
    display: flex;
    gap: 2.4rem;
    flex-direction: column;
  

    .count {
      display: flex;
      width: max-content;
      gap: 1rem;
      border: none;
      align-items: center;
      border:0.1rem solid #F3F3F3;
  
      @include max(1024px) {
        gap: 0.5rem;
      }
  
      @include max(550px) {
        gap: 0.5rem;
  
        &-number {
          font-size: 1.4rem;
        }
      }

      &-number {
        color: #1A1A1A;
        line-height: 1;
        font-weight: 600;
      }
  
      &-btn {
        width: 3.2rem;
        height: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background:  #F3F3F3;
  
        .icon {
          transition: 0.3s ease-in-out;
          color: #000;
          &:hover {
            color: $color-pri;
          }
        }
      }
    }
  }