.news {
    &-wrap {
        background: #FCFAF6;
        padding: 6rem 0;

        @include max(768px) {
            padding: 4rem 0;
        }
    }
    &-top {
        &-tag {
            border-radius: 0.8rem;
            background: #FEF5E7;
            @include mid-flex;
            color: #4B5563;
            height: 7rem;
            padding: 1rem 2.4rem;
            width: max-content;
            font-weight: 700;
            @include fz-20;
            margin: 0 auto;
        }
    }
    &-pagi {
        margin-top:3rem;
        padding: 3rem 0;
        border-top: 0.1rem solid  #EAECF0;

        
    }
}