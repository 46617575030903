.p-home {
  &.ourl {

    .ourl-top-list-box {
      margin-top: 6rem;
      @include max(1100px) {
        margin-top:3rem;
      }
    }
    .ourl-slide {
      &-box {
        &:not(:first-child) {
          display: none;
        }
      }
   
      .btn {
        margin: 0 auto;
        margin-top: 6rem;

        @include max(1350px) {
          margin-top: 2rem;
        }
      }
    }
  }
}
.hban {
  overflow: hidden;
  &-wrap {
    height: calc((893 / 1728) * 100vw);
    @include max(1024px) {
      height: unset;
      min-height: calc((893 / 1728) * 100vw);
    }
  }
  &-inner {
    height: 100%;
  }
  &-ctn {
    height: 100%;
    position: relative;
  }
  &-slide {
    height: 100%;
  }
  &-item {
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    );
    padding-top: var(--size-hd);

    &-wrap {
      height: 100%;
    }
    &-ctn {
      height: 100%;
    }
  }
  &-bg {
    height: 100%;
    position: absolute;
    width: 100%;
    inset: 0;
    z-index: -1;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &-desc {
    height: 100%;
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 12.5rem;
    transition: 0.3s ease-in-out;
    opacity: 0;
    transform: scale(0.9);
    width: 50%;
    @include max(1024px) {
      padding-bottom: 15.5rem;
    }
    @include max(768px) {
      width: 100%;
    }
    .title {
      color: white;
      @include fz-64;
      font-weight: 700;
      line-height: 1.2;
    }
    .txt {
      color: white;
      @include fz-22;
      margin-top: 1rem;
    }
    .btn {
      margin-top: 2rem;
    }
  }
  &-control {
    position: absolute;
    right: 5rem;
    top: 50%;
    transform: translateY(-50%);
    padding-top: var(--size-hd);
    padding-bottom: 8.5rem;
    width: 5.6rem;
    z-index: 2;

    @include max(1024px) {
      left: 1.5rem;
      width: calc(100% - 3rem);
      top: unset;
      transform: unset;
      bottom: 0;
      padding-bottom: 8.5rem;

      .hban-control-inner {
        flex-direction: row;
        width: max-content;
        margin: 0 auto;
      }
    }

    &-inner {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      backdrop-filter: blur(1rem);
      padding: 1rem;
      border-radius: 0.8rem;
    }
  }
  &-pagi {
    position: relative;
    inset: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    transform: unset !important;

    @include max(1024px) {
      flex-direction: row;
      gap: 0;
    }

    .swiper-pagination-bullet {
      margin: 0 !important;
      width: max-content;
      aspect-ratio: 1;
      height: unset;
      transition: 0.3s ease-in-out;
      font-weight: 700;
      color: white;
      font-size: 1rem;
      border: none;
      display: flex !important;
      justify-content: center;
      align-items: center;
      line-height: 1;
      padding: 0.3rem;
      position: relative;

      &:before {
        position: absolute;
        border-radius: 100rem;
        right: -0.5rem;
        top: 50%;
        transform: translateY(-50%);
        content:"" ;
        border:0.1rem solid white;
        width: 0.5rem;
        height: 0.5rem;
        opacity: 0;
        transition: 0.3s ease-in-out;
      }
      &::after {
        position: absolute;
        border-radius: 100rem;
        right: -0.3rem;
        top: 50%;
        transform: translateY(-50%);
        content:"" ;
        background-color: white;
        width: 0.3rem;
        height: 0.3rem;
        opacity: 0;
        transition: 0.3s ease-in-out;
      }

      &-active {
        color: white;
        @include fz-18;
        background-color: transparent;
        &::after,
        &::before {
          opacity: 1;
        }
        @include max(1024px) {
          @include fz-14;
        }
      }
      @include max(1024px) {
        min-width: 3rem;

        &::after {
          left: 50%;
          transform:translateX(-50%);
          right: unset;
          bottom:-0.2rem;
          top: unset;
        }
        &::before {
          left: 50%;
          transform:translateX(-50%);
          right: unset;
          bottom:-0.4rem;
          top: unset;


        }
      }
    }
  }
  &-btn {
    // @include max(1350px) {
    //   position: relative;
    //   flex-direction: row-reverse;
    //   gap: 1rem;
    //   @include mid-flex;
    //   margin-top: 3rem;

    //   &-next,
    //   &-prev {
    //     position: relative;
    //     inset: 0 !important;
    //     margin: 0 !important;
    //   }
    // }
    &-next,
    &-prev {
      height: 3.6rem;
      width: 3.6rem;
      border: 0.1rem solid $color-second;
      border-radius: 0.4rem;
      transition: 0.3s ease-in-out;
      position: relative;
      margin: 0 !important;
      inset: 0 !important;
      transform: rotate(-90deg);
      background: $color-second;
      flex-shrink: 0;

      @include max(1024px) {
        transform: unset;
        height: 2.8rem;
        width: 2.8rem;

        &::after {
          transform: rotate(180deg);
        }
      }

      &::after {
        color: $color-white;
        font-size: 1.6rem;
        transition: 0.3s ease-in-out;
      }

      @include hover {
        background: $color-second;

        &::after {
          color: white;
        }
      }
    }
  }
  .swiper {
    height: 100%;
    &-slide {
      &-active {
        .hban-desc {
          opacity: 1;
          transform: scale(1);
          // -webkit-animation: puff-in-center 0.4s
          //   cubic-bezier(0.47, 0, 0.745, 0.715) both;
          // animation: puff-in-center 0.4s cubic-bezier(0.47, 0, 0.745, 0.715)
          //   both;

          .title {
          }
        }
      }
    }
  }
  &-bot {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 1;
    height: 8.5rem;

    @include max(1024px) {
      height: 6.5rem;

      .banner-bot {
        padding: 2rem 0;
      }
    }
  }
}
.hvid {
  &-wrap {
    padding: 6rem 0;
    background: #f8f8f8;
    @include max(768px) {
      padding: 4rem 0;
    }
  }
  &-inner {
    .title {
      color: $color-pri;
      margin-bottom: 3rem;
      @include max(768px) {
        @include fz-42;
      }
    }
  }
  &-vi {
    width: 100%;
    border-radius: 1.2rem;
    overflow: hidden;
    padding-top: calc((660 / 1215) * 100%);
    position: relative;
    video,
    iframe {
      @include imgCover;
    }
  }
  &-bot {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    gap: 3rem;

    .txt {
      @include fz-28;
      color: $color-pri;
      line-height: 1;
    }
  }
}

.htech {
  overflow: hidden;
  &-wrap {
    padding: 6rem 0;
  }

  &-flex {
    margin-top: 3.2rem;
  }
  &-top {
    width: 50%;
    margin-left:auto;
    @include max(650px) {
      width: 100%;
    }
    .title {
      @include fz-36;
      line-height: 1;
      margin-bottom: 2.8rem;
    }
    .text {
      color: #374151;
      @include fz-20;
    }
    @include max(992px) {
      .title {
        @include fz-20;
      }
      .text {
        @include fz-14;
      }
    }
  }
  &-bot {
    margin-top: -24%;
    width: 50%;
    @include max(650px) {
      width: 50%;
      margin-top: 2rem;
      margin-left: -2rem;
    }
  }
  &-tag {
    background: $color-second;
    padding: 1.2rem 2.4rem;
    border-radius: 0.8rem;
    @include mid-flex;
    line-height: 1;
    color: white;
    @include fz-36;
    margin-bottom: 2.8rem;
    font-weight: 700;
    width: max-content;
    @include max(992px) {
        @include fz-20;
    }
    @include max(768px) {
      margin-bottom:1.5rem;
    }
  }
  &-img {
    position: relative;
    width: 100%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  &-item {
    position: absolute;
    cursor: pointer;
    z-index: 4;


    &.x1{
      left: 59%;
      top: 55%;
    }
    
    &.x2{
      left: 56%;
      top: 72%;
    }
    &.x3{
      left: 61%;
      top: 82%;
      .htech-item-ctn {
        left: 0.8rem;
      }
    }
    &.x4{
      left: 59%;
      top: 92%;
    }
    @include max(650px) {
      
      &.x1{
        top: 46%;
      }
      
      &.x2{
        left: 53%;
        top: 70%;
      }
      &.x3{
        left: 58%;
        top: 78%;
      }
      &.x4{
        left: 59%;
        top: 89%;
      }
    }
    &-disc {
      width: 2rem;
      height: 2rem;
      border-radius: 100rem;
      background-color: $color-second;
      position: relative;
      @include max(768px) {
        width: 1.8rem;
        height: 1.8rem;
      }
      @include max(650px) {
        
      }

      &::before,
      &:after {
        content: "";
        position: absolute;
        inset: 0;
        background: $color-second;
        border-radius: inherit;
      }
      &::before {
        animation: 2s ftCircleAni ease infinite;
      }

      &::after {
        animation: 2s ftCircleAni2 ease infinite;
      }
    }
    &-ctn {
      position: absolute;
      width: 46vw;
      min-height: 5rem;
      display: flex;
      color: #374151;
      @include fz-20;
      left: 0.5rem;
      bottom: calc(100% - 0.2rem);
      transition: 0.5s cubic-bezier(0.72, 0.04, 0.49, 0.82);
      gap: 0.5rem;
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
      p {
        margin: 0 !important;
      }
      @include max(1500px) {
        width: 55vw;
      }
      @include max(1400px) {
        width: 65vw;
      }
      @include max(992px) {
        min-height: 3rem;
        gap: 2rem;
        .text {
          font-size:1.2rem;
        }
      
      }
      @include max(768px) {
        width: 68vw;
      }
      @include max(650px) {
        top:-0.2rem;
        gap:1rem;

   
      }
      .text {
        flex: 1;
      }
      &:before {
        content: "";
        width: 18rem;
        position: relative;
        border: 0.1rem solid $color-second;
        transform: skew(-30deg) translate(-9%,1.3rem);
        border-bottom: none;
        border-right: none;
        margin-left: 4rem;
        @include max(992px) {
          width: 12rem;
          transform: skew(-60deg) translate(0,1rem);

        }
        @include max(768px) {
          width: 8rem;
          transform: skew(-65deg) translate(0,1rem);

        }
        @include max(650px) {
          width: 4rem;
          transform: none;
          border-left: none;
          margin-left: 2rem;
          top: 0.7rem;

        }
        @include max(400px) {
          width: 3rem;
          margin-left: 1rem;
        }
      }
    }
    &:hover {
      z-index: 3;
      .htech-item-ctn {
        clip-path:polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important;
      }
    }
    @include max(768px) {
      .htech-item-ctn {
        // clip-path:unset!important;
        min-height: fit-content;
      }
    }
  }
}
@keyframes ftCircleAni {
  0% {
    transform: scale(1);
  }

  90%,
  91% {
    transform: scale(1.5);
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes ftCircleAni2 {
  0% {
    transform: scale(1);
  }

  90%,
  91% {
    transform: scale(3);
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.hlo {
  overflow: hidden;
  &-flex {
    display: flex;
    margin: 0 -1.6rem;
    @include max(600px) {
      margin: 0 -0.4rem;
    }
  }
  &-left {
    width: calc((3 / 12) * 100%);
    padding: 0 1.6rem;
    @include max(600px) {
      padding: 0 0.4rem;
      display:none;
    }
    @include max(500px) {
      width: calc((4 / 12) * 100%);
    }
  }
  &-right {
    flex: 1;
    padding: 0 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @include max(600px) {
      padding: 0 0.4rem;
    }
    .title {
      @include fz-35;
      line-height: 1.2;
      @include max(768px) {
        br {
          display: none;
        }
      }
    }
  }
  &-img {
    width: 100%;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
  &-map {
    width: 100%;
    margin-top: 2rem;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.hpart {
  &-wrap {
    padding: 6rem 0;
    background: #f8f8f8;
  }
  &-ctn {
    margin-top: 6rem;
  }
  &-slide {
    position: relative;
    padding-bottom: 8rem;
    @include max(1350px) {
      padding-bottom: 4rem;
    }
  }
  &-item {
    padding: 2rem;
    border-radius: 1.2rem;
    overflow: hidden;
    background: white;
    border: 0.1rem solid #e5e7eb;
    height: 100%;
  }
  &-img {
    width: 100%;
    height: 100%;
    @include mid-flex;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
  &-btn {
    @include max(1350px) {
      position: relative;
      flex-direction: row-reverse;
      gap: 1rem;
      @include mid-flex;
      margin-top: 3rem;

      &-next,
      &-prev {
        position: relative;
        inset: 0 !important;
        margin: 0 !important;
      }
    }
    @include max(768px) {
      display: none;
    }
    &-next,
    &-prev {
      height: 3.6rem;
      width: 3.6rem;
      border: 0.1rem solid $color-second;
      border-radius: 0.4rem;
      transition: 0.3s ease-in-out;
      margin-top: -5.2rem;

      &::after {
        color: $color-second;
        font-size: 1.6rem;
        transition: 0.3s ease-in-out;
      }

      @include hover {
        background: $color-second;

        &::after {
          color: white;
        }
      }
    }
    &-next {
      right: -6rem;
    }
    &-prev {
      left: -6rem;
    }
  }
  &-pagi {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    .swiper-pagination {
      position: relative;
      inset: 0;
    }
  }
  .swiper {
    margin: 0 -1.6rem;

    @include max(1100px) {
      margin: 0 -0.8rem;
    }
    &-slide {
      width: 25%;
      padding: 0 1.6rem;
      height: auto;
      @include max(1100px) {
        padding: 0 0.8rem;
      }
      @include max(768px) {
        width: calc(100% / 3);
      }
      @include max(550px) {
        width: 50%;
      }
    }
  }
}

.team {
  &-item {
    display: flex;
    flex-direction: column;

    .repro-img {
      @include max(768px) {
        padding-top: 100%;
      }
    }
  }
  &-desc {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
  &-icon {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    .icon {
      height: 4.4rem;
      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &-txt {
    margin-top: auto;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @include fz-18;
    color: #374151;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-12-29 16:47:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
.puff-in-center {
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
    both;
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}
@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
.ourl-slide-box {
  position: relative;
  &.tabPanel {
    @include min(768px) {
      .ourl-pagi {
        display: none;
      }
    }
  }
}