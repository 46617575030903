.ip-control {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &.x2 {
    width: 50%;
  }
  &.x3 {
    width: calc(100% / 3);
  }

  label {
    font-size: 1.4rem;
    font-weight: 700;
    color: $color-pri;
    display: block;
    margin-bottom: 0.5rem;
  }
  input {
    width: 100%;
    height: 4.8rem;
    border: 1px solid #d0d5dd;
    background: #fff;
    padding: 0 1.2rem;
    border-radius: 0.8rem;

    &:focus {
      outline: none;
      border-color: black;
    }
  }
  textarea {
    border: 1px solid #d0d5dd;
    background: #fff;
    padding: 1.6rem;
    min-height: 10rem;
    border-radius: 0.8rem;
    width: 100% !important;
  }
  &-input {
    position: relative;
    display: flex;
    input {
      flex: 1;
      border-radius: 0 0.8rem 0.8rem 0 !important;
    }
  }
  &-select {
    width: 7rem;

    .select2-selection__rendered,
    .select2-container .select2-selection--single,
    .select2-container {
      border-radius:  0.8rem 0 0 0.8rem  !important;

      border-right: none !important;
    }
  }
  .select2-container {
    width: 100% !important;
    height: 4.8rem;
    border-radius: 0.8rem;
    border: 1px solid #d0d5dd;
  }
  .select2-container .select2-selection--single,
  .select2-selection__rendered {
    height: 100%;
    display: flex !important;
    align-items: center;
    background: #fff;
    width: 100%;
    border-color: transparent;
    border-radius: 0.8rem;
  }
  .select2-selection__rendered {
    border-radius: 0.8rem;
  }
  .select2-selection__arrow {
    height: 100% !important;
  }
}
