:root {
    --size-hd: 15rem;
    // distance section
    --pd-sc: 8rem;
  --size-hd-sticky: 7rem;



    @media (max-width: 1200px) {
        --pd-sc: 4rem;
    --size-hd: 8rem;

    }

    --rewidth: calc((100vw - 120rem)/2);

    @media (max-width:1230px) {
        --rewidth: 1.5rem;
    }


    --recont: calc(100vw - (100vw - 120rem)/2);

    @media (max-width:1240px) {
        --recont: calc(100%);
    }
}

$color-pri-ln: #0C1226;
$color-pri: #374151;
$color-second: #F49712;
$color-third: #101828;
$color-four: #111927;
$color-five: #f05123;
$color-black: #000000;
$color-white: #ffffff;
$color-grey:#344054;
$color-grey-3:#E5DED8;
$color-text: #121212;
$txt-hover: #626262;
$color-text-second: #000000;
$color-border-01: rgba(0, 0, 0, 0.1);
$color-border-02: rgba(0, 0, 0, 0.2);
$color-border-03: rgba(0, 0, 0, 0.3);
$color-border-04: rgba(0, 0, 0, 0.4);
$color-border-05: rgba(0, 0, 0, 0.5);
$color-border-06: rgba(0, 0, 0, 0.6);
$color-border-07: rgba(0, 0, 0, 0.7);
$color-border-08: rgba(0, 0, 0, 0.8);
$color-border-09: rgba(0, 0, 0, 0.9);
$color-opa-white-01: rgba(255, 255, 255, 0.1);
$color-opa-white-02: rgba(255, 255, 255, 0.2);
$color-opa-white-03: rgba(255, 255, 255, 0.3);
$color-opa-white-04: rgba(255, 255, 255, 0.4);
$color-opa-white-05: rgba(255, 255, 255, 0.5);
$color-opa-white-06: rgba(255, 255, 255, 0.6);
$color-opa-white-07: rgba(255, 255, 255, 0.7);
$color-opa-white-08: rgba(255, 255, 255, 0.8);
$color-opa-white-09: rgba(255, 255, 255, 0.9);
//Responsive
$res-mobile: 1199px;
$res-mobile-min: 1200px;
$mobile: 500px;
//Distance
$radius: 0.6rem;
// cubic
$cubic: cubic-bezier(0.72, 0.04, 0.49, 0.82);
// shadow
$shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
// header shadow
$shadowhd: 0px 4px 30px 0px rgba(1, 0, 35, 0.15);
// linear 
$linear:linear-gradient(90deg, #FFAF61 0.37%, #DC9A27 27.52%, #EDB31F 52.76%, #D7942A 80.98%, #B06828 100%);
$linearBlue: linear-gradient(357deg, #011224 0%, #062347 85.55%, #0C3A76 141.16%);
$linearText:linear-gradient(90deg, #B66F29 0.37%, #DC9A27 27.52%, #EDB31F 52.76%, #D7942A 80.98%, #B06828 100%);
// ellipse 
// $ellipse: ellipse(49% 35% at 50% 50%);
$ellipse:ellipse(49% 50% at 50% 50%);
// clippath
$clip:polygon(0 0, 100% 4rem, 100% 100%, 0 calc(100% - 4rem));
// filter
$filter: brightness(0) saturate(100%) invert(63%) sepia(99%) saturate(3753%) hue-rotate(166deg) brightness(108%) contrast(105%);
// Grid 
$grid-gutter-width: 2.4rem;
$grid-columns: 12;
$gutters: (
  1: 2.4rem,
);

$xxs-min: 376px;
$xs-min: 576px; // CÓ THỂ TÙY CHỈNH
$sm-min: 768px; // KHÔNG THAY ĐỔI
$md-min: 992px; // KHÔNG THAY ĐỔI
$lg-min: 1200px; // KHÔNG THAY ĐỔI
$xl-min: 1400px; // CÓ THỂ TÙY CHỈNH

$xxs-max: (
  $xxs-min - 0.02
); // Smallest Mobile
$xs-max: (
  $xs-min - 0.02
); // Small Mobile
$sm-max: (
  $sm-min - 0.02
); // Mobile
$md-max: (
  $md-min - 0.02
); // Tablet
$lg-max: (
  $lg-min - 0.02
); // Desktop
$xl-max: (
  $xl-min - 0.02
); // Large Desktop

$grid-breakpoints: (
  "xxs": $xxs-min,
  "xs": $xs-min,
  "sm": $sm-min,
  "md": $md-min,
  "lg": $lg-min,
  "xl": $xl-min,
);