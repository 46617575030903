.dateTime {
    position: relative;
    height: 4.8rem;
    background: #F9F7F5;
    z-index: 2;
    border: 1px solid  #F3EDEA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.6rem;
    input {
      border-radius: unset !important;
      border: unset !important;
      background: none !important;
    }
  
    .icon {
      width: 1.6rem;
      height: 1.6rem;
      @include mid-flex;
    }
  }
  .dateTimeItem {
    @include flex;
    justify-content: space-between;
    height: 100%;
  }
  
  .dateTimeText {
    position: absolute;
    @include full;
    left: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;
    //   background-color: white;
    z-index: 2;
    &.disable {
      opacity: 0;
      visibility: hidden;
    }
  }
  
  .dateTimeInput {
    padding: 0.8rem 1.6rem;
    @include reset-input;
    position: absolute;
    @include full;
    z-index: 1;
  }
  