.banner {
    &.aban {
        .banner-desc {
            align-items: flex-start;
            width: 50%;
            margin-right: auto;

            @include max(992px) {
                width: 80%;
            }
            @include max(768px) {
                width: 100%;
            }

            br {
                content: "";
                display: block;
                margin: 1.5rem 0;
            }
        }
    }
}
.about {
    &-ctn {
        padding: 4rem 0;
        display: flex;
        flex-direction: column;
        gap: 6rem;
        @include max(768px) {
            row-gap: 4rem;
        }
    }
    &-flex {
        display: flex;
        margin: 0 -1.6rem;

        &:nth-child(even) {
            flex-direction: row-reverse;

            @include max(768px) {
                flex-direction:column;
            }
        }

        @include max(768px) {
            flex-direction:column;
            row-gap: 2rem;
        }
    }
    &-left {
        padding: 0 1.6rem;
        width: 50%;
        @include max(768px) {
            width: 70%;
            margin: 0 auto;
        }
        @include max(600px) {
            width: 85%;
        }
        @include max(500px) {
            width: 100%;
        }
    }
    &-right {
        padding: 0 1.6rem;
        flex: 1;
        @include max(768px) {
            width: 100%;
        }

        .title {
            color: $color-pri;
            @include fz-36;
            font-weight: 700;
            text-transform: uppercase;
        }
        .desc {
            font-weight: 500;
            color: $color-third;
            @include fz-22;
            font-family: 'Univers-Light-Normal';

            @include max(768px) {
                @include fz-18;
            }
        }
    }
    &-img {
        position: relative;
        padding-top: calc((351/592) * 100%);
        overflow: hidden;
        border-radius: 1.2rem;

        img {
            @include imgCover;
            transition: 0.3s ease-in-out;
        }
        @include hover {
            img {
                transform: translate(-50%,-50%)scale(1.05);
            }
        }
    }
    &-map {
        margin-bottom:2rem;
        &-inner {
            margin-top: 1rem;
        }
        &-img {
            margin: 0 auto;

            @include min(768px) {
                width: calc((10/12) * 100%);
            }

            img {
                width: 100%;
                object-fit: contain;
            }
        }
    }
    
}