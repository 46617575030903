.banner {
    &-wrap {
        position: relative;
    }
    &-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        z-index: -1;

        img {
            width: 100%;
            height: 100% !important; 
            object-fit: cover;
        }
    }
    &-inner {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);

    }
    &-ctn {
        padding-top: var(--size-hd);
        min-height: calc((893/1728) * 100vw);
        @include mid-flex;
        position: relative;
        flex-direction: column
    }
    &-desc {
        @include mid-flex;
        flex-direction: column;
        padding: 4rem 0;

        .title {
            line-height: 1.2; 
            @include max(768px) {
                br {
                    display: none;
                }
            }
        }
        .desc {
            @include max(768px) {
                br {
                    display: none;
                }
            }
        }
        .btn {
            margin-top: 2.4rem;
        }
    }
    &-bot {
        position: relative;
        margin-top:auto;
        bottom: 0;
        display: flex;
        justify-content:space-between;
        gap: 1rem;
        width: 100%;
        padding: 3rem 0;

        &::before {
            position: absolute;
            content: "";
            height: 0.1rem;
            width: 100vw;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            background-color: #D1D5DB;
        }

        &-text {
            color: #fff;
            @include fz-22 ;
        }
        &-link {
            &-link {
                display: flex;
            }
            i {
                color: #fff;
                @include fz-22;
                -webkit-animation: shake-vertical 15s cubic-bezier(0.455, 0.03, 0.515, 0.955)
                infinite both;
              animation: shake-vertical 15s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite
                both;
            }
        }
    }
    &.option-2 {
        .banner-desc {
            margin-top: auto;

        }
        .banner-bot {
            margin-top:auto;
        }
    }
    &:not(.option-2) {
        .banner-bread {
            margin-bottom: auto;
        }
        .banner-desc {
            margin-bottom: auto;
        }
    }
    &-bread {
        padding-top: 2rem;
        margin-right: auto;
        .breadcrumb-item:before {
            color: white;
        }

        .breadcrumb-link {
            color: white;
        }
    }
}