.ourl {
  &-wrap {
    padding: 6rem 0;
  }
  &-row {
    padding-top: 6rem;
  }
  &-top {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 7rem;
    z-index: 99;

    @include max(1200px) {
      top: 7.9rem;
    }

    &-list {
      display: flex;
      gap: 1.2rem;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      &-box {
        border-radius: 0.8rem;
        background: #fef5e7;
        padding: 1.2rem;
        max-width: 100%;
        width: max-content;
        margin: 0 auto;

        @include max(768px) {
          padding: 0.7rem 1.2rem;
        }
      }
    }
    &-item {
      @include mid-flex;
      transition: 0.3s;
      border-radius: 0.4rem;
      line-height: 1;
      height: 4.6rem;
      padding: 0.5rem 2.4rem;
      font-weight: 700;
      @include fz-20;
      color: #4b5563;
      cursor: pointer;
      width: max-content;
      white-space: nowrap;

      &.active {
        background-color: $color-second;
        color: white;
      }
      @include hover {
        background-color: $color-second;
        color: white;
      }
      @include max(768px) {
        padding: 0.5rem 1rem;
        height: 3.6rem;
      }
    }
  }
  &-btn {
    @include max(1350px) {
      position: relative;
      flex-direction: row-reverse;
      gap: 1rem;
      @include mid-flex;
      margin-top: 2rem;

      &-next,
      &-prev {
        position: relative;
        inset: 0 !important;
        margin: 0;
      }
    }
    @include max(768px) {
      display:none;
    }
    &-next,
    &-prev {
      height: 3.6rem;
      width: 3.6rem;
      border: 0.1rem solid $color-second;
      border-radius: 0.4rem;
      transition: 0.3s ease-in-out;

      &::after {
        color: $color-second;
        font-size: 1.6rem;
        transition: 0.3s ease-in-out;
      }

      @include hover {
        background: $color-second;

        &::after {
          color: white;
        }
      }
    }
    &-next {
      right: -6rem;
    }
    &-prev {
      left: -6rem;
    }
  }
  &-slide {
    position: relative;
    margin-top: 6rem;
    @include max(992px) {
      margin-top: 3rem;
    }
    .swiper {
      margin: 0 -1.6rem;
      &-slide {
        width: calc(100% / 3);
        padding: 0 1.6rem;
      }
      @include max(992px) {
        margin: 0 -0.4rem;

        &-slide {
          padding: 0 0.4rem;
        }
      }
      @include max(768px) {
        &-slide {
          width: 40%;
        }
      }
      @include max(550px) {
        &-slide {
          width: 50%;
        }
      }
      @include max(450px) {
        &-slide {
          width: 70%;
        }
      }
    }
  }
  &-item {
    // padding: 0 1.6rem;
    width: 100%;
  }
  &-box {
    position: relative;
    border-radius: 1.2rem;
    overflow: hidden;
  }
  &-img {
    position: relative;
    overflow: hidden;
    padding-top: calc((480 / 384) * 100%);
    border-radius: 1.2rem;

    img {
      @include imgCover;
      transition: 0.3s ease-in-out;
    }
  }
  &-desc {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2.5rem 2rem;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      inset: 0;
      background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 50.96%,
          rgba(0, 0, 0, 0.6) 99.86%
        ),
        linear-gradient(
          179deg,
          rgba(0, 0, 0, 0.6) 0.65%,
          rgba(200, 200, 200, 0) 27.77%
        );
    }
  }
  &-name {
    // position: relative;
    z-index: 1;
    .link {
      color: #f9fafb;
      transition: 0.3s ease-in-out;
      @include fz-28;
      font-weight: 700;
      transition: 0.3s ease-in-out;
      text-shadow: 1px 1px 5px $color-black;

      @include hover {
        color: $color-second;
      }

      &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        inset: 0;
      }
    }
  }
  &-bot {
    position: relative;
    z-index: 5;

    .desc {
      color: #f9fafb;
      margin-bottom: 0.5rem;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-shadow: 1px 1px 5px $color-black;


    }
 


    .link {
      display: flex;
      justify-content: space-between;
      @include fz-24;
      color: #f9fafb;
      transition: 0.3s ease-in-out;
      position: relative;
      padding-top: 2.5rem;
      text-shadow: 1px 1px 5px $color-black;



      &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 4rem);
        height: 0.1rem;
        background-color: #f9fafb;
        opacity: 0.7;
      }

      @include hover {
        color: $color-second;

        i {
          color: $color-second;
        }
      }
    }
  }
  &-pagi {
    margin-top: 4rem;
    @include max(1350px) {
      margin-top:2rem;
    }
    .swiper-pagination {
      position: relative;
      inset: 0;
    }

    &.d-none {
      @include max(768px) {
        display:block;
      }
    }
  }
}
.deourl {
  &-main {
    background: #f8f8f8;
    padding: 6rem 0;
    @include max(992px) {
      padding: 2rem 0;
      padding-top: 1.5rem;
    }
    @include max(768px) {
    overflow: hidden;
    }
  }
  &-top {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 7rem;
    z-index: 99;
    @include max(1200px) {
      top: 7.9rem;
    }
    &:before {
      position: absolute;
      content: "";
      bottom: 0;
      width: 100%;
      height: 100%;
      left: 0;
      background: #f8f8f8;
    }

    &-list {
      display: flex;
      gap: 1.2rem;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      &-box {
        border-radius: 0.8rem;
        padding: 1.2rem;
        max-width: 100%;
        width: max-content;
        margin: 0 auto;
        position: relative;

        @include max(768px) {
          padding: 0.7rem 1.2rem;
        }
      }
    }
    &-item {
      @include mid-flex;
      transition: 0.3s;
      border-radius: 0.4rem;
      line-height: 1;
      height: 4.6rem;
      padding: 0.5rem 2.4rem;
      font-weight: 700;
      @include fz-20;
      color: #4b5563;
      cursor: pointer;
      width: max-content;
      white-space: nowrap;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        bottom: 0;
        width: 0;
        height: 0.2rem;
        left: 50%;
        transform: translateX(-50%);
        transition: 0.3s ease-in-out;
        background: $color-second;
      }

      &.active {
        color: $color-second;

        &:before {
          width: 100%;
        }
      }
      @include hover {
        color: $color-second;

        &:before {
          width: 100%;
        }
      }
      @include max(768px) {
        padding: 0.5rem 1rem;
        height: 3.6rem;
      }
    }
  }
}

.fea {
  padding-top: 6rem;
  overflow: hidden;
  @include max(768px) {
    padding-top: 4rem;
  }

  &-flex {
    display: flex;
    margin: 0 -1.6rem;
    @include max(1100px) {
      margin: 0 -0.4rem;
    }
    @include max(768px) {
      flex-direction: column;
      row-gap: 1rem;
    }
  }
  &-left {
    padding: 0 1.6rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    @include max(1100px) {
      padding: 0 0.4rem;
    }
    @include max(768px) {
      width: 100%;
    }
  }
  &-right {
    width: 50%;
    padding: 0 1.6rem;
    @include max(1100px) {
      padding: 0 0.4rem;
    }
    @include max(768px) {
      width: 100%;
    }
  }

  &-ctn {
    &-box {
      display: flex;
      flex-direction: column;
      border-radius: 1.2rem;
      overflow: hidden;
      box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.1),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    @include max(768px) {
      gap: 1rem;
    }
  }
  &-item {
    display: flex;
    gap: 1.2rem;
    align-items: center;

    .icon {
      width: 2rem;
      height: 2rem;
      flex-shrink: 0;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .txt {
      font-family: "Univers-Light-Normal";
      font-weight: 500;
      color: $color-four;
    }
  }

  &-btn {
    @include max(500px) {
      display: none;
    }
    @include max(400px) {
      position: relative;
      flex-direction: row-reverse;
      gap: 1rem;
      @include mid-flex;
      margin-top: 2rem;

      &-next,
      &-prev {
        position: relative;
        inset: 0 !important;
        margin: 0;
      }
    }
    &-next,
    &-prev {
      height: 2.8rem;
      width: 2.8rem;
      border: 0.1rem solid $color-second;
      border-radius: 0.4rem;
      transition: 0.3s ease-in-out;
      margin-top: -1.2rem;

      &::after {
        color: $color-second;
        font-size: 1.6rem;
        transition: 0.3s ease-in-out;
      }

      @include hover {
        background: $color-second;

        &::after {
          color: white;
        }
      }
    }
    &-next {
      right: -3.6rem;
    }
    &-prev {
      left: -3.6rem;
    }
  }

  &-img {
    padding-top: calc((398 / 592) * 100%);
    position: relative;
    overflow: hidden;

    img {
      @include imgCover;
    }
  }

  &-slide {
    position: relative;
  }
  &-thumb {
    padding: 2.8rem 4.8rem;
    background-color: #f3f4f6;


    @include max(500px) {
      padding: 1.8rem 0;
    }

    &-slide {
      position: relative;
      @include max(768px) {
        .fea-thumb-img {
          display: none;
        }
        .fea-btn {
          display: none;
        }
      }
    }

    &-img {
      padding-top: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 1.2rem;
      border: 0.1rem solid transparent;

      img {
        @include imgCover;
      }
    }

    .swiper {
      &-slide {
        width: 20%;

        &-thumb {
          &-active {
            .fea-thumb-img {
              border: 0.1rem solid $color-second;
            }
          }
        }
      }
    }
  }
  &-pagi {
    margin-top: 1rem;
    @include max(768px) {
      margin-top:0;
    }
    .swiper-pagination {
      position: relative;
      inset: 0;
    }
  }
  &-desc {
    ul {
      li {
        color: $color-third;
        margin-bottom: 1.6rem;
      font-family: "Univers-Light-Normal";

        @include max(768px) {
          margin-bottom:1rem;
        }
        &:last-child {
          margin-bottom:0;
        }
      }
    }
  }
  &-down {
    margin-top:6rem;
    @include max(768px) {
      margin-top:4rem;
    }
    .btn {
      margin: 0 auto;
    }
  }
}

.tech {
  padding-top: 6rem;
  overflow: hidden;
  @include max(768px) {
    padding-top: 4rem;
  }

  &-flex {
    display: flex;
    margin: 0 -1.6rem;
    @include max(1100px) {
      margin: 0 -0.4rem;
    }
    @include max(768px) {
      flex-direction: column-reverse !important;
      row-gap: 1rem;
    }
  }
  &-left {
    padding: 0 1.6rem;
    width: 50%;
    @include max(1100px) {
      padding: 0 0.4rem;
    }

    @include max(768px) {
      width: 100%;
    }
  }
  &-right {
    width: 50%;
    padding: 0 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    @include max(1100px) {
      padding: 0 0.4rem;
    }
    @include max(768px) {
      width: 100%;
    }
  }
  &-vi {
    width: 100%;
    position: relative;
    border-radius: 1.2rem;
    overflow: hidden;
    display: flex;
    padding-top: 100%;
    video,
    iframe,
    img {
      @include imgCover;
    }

    &.gItem {
      cursor: pointer;
    }
  }
  &-desc {
    ul {
      li {
        margin-bottom: 1.6rem;
        color: $color-four;
        font-family: "Univers-Light-Normal";

        @include max(768px) {
          margin-bottom: 1rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &-slide {
    position: relative;
  }
  &-pagi {
    position: relative;
    margin-top: 4rem;
  }
}

.size {
  padding-top: 6rem;
  @include max(768px) {
    padding-top: 4rem;
  }

  &-inner {
    padding: 2.4rem 1.2rem;
    border-radius: 1.2rem;
    background-color: white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  }

  &-table {
    padding-top: 3rem;
  }

  table {
    width: 100%;
    thead {
      tr {
        th {
          width: calc(100% / 5);
          font-weight: 600;
          color: $color-second;
          padding: 0 1.6rem;

          .title {
            @include mid-flex;
            width: 100%;
            height: 100%;
            border-radius: 1.2rem;
            background-color: #f9fafb;
            height: 4.4rem;
          }
        }
      }
      @include max(768px) {
        display: none;
      }
    }
    tbody {
      tr {
        @include max(768px) {
          &:not(:last-child) {
            border-bottom: 0.1rem solid #efefef;
          }
        }
        td {
          color: #374151;
          text-align: center;
          padding: 0 1.6rem;

          .txt {
            padding: 2.3rem;
            @include mid-flex;
            width: 100%;
            height: 100%;
            border-bottom: 0.1rem solid #eaecf0;

            @include max(768px) {
              border-bottom: none;
              padding: 0;
              justify-content: end;
            }
          }

          @include max(768px) {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 1.6rem 0;
            position: relative;

            &::before {
              content: attr(data-text);
              width: fit-content;
              font-weight: 600;
              font-size: 1.6rem;
              color: #031230;
              text-align: left;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  &-more {
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-top: 2rem;

    &.load-hide {
      i {
        transform: rotate(180deg);
      }
    }
  }
}

.des {
  padding: 6rem 0;
  @include max(768px) {
    padding: 4rem 0;
  }

  &-top {
    .title {
      line-height: 1.2;
    }
    .ourl-top-list-box {
      margin-top: 4rem;
    }
  }

  &-slide {
    margin-top: 4rem;
    &-box {
      position: relative;
      &:not(:first-child) {
        display: none;
      }
    }
  }

  &-btn {
    @include max(1350px) {
      position: relative;
      flex-direction: row-reverse;
      gap: 1rem;
      @include mid-flex;
      margin-top: 3rem;

      &-next,
      &-prev {
        position: relative;
        inset: 0 !important;
        margin: 0;
      }
    }
    @include max(768px) {
      display: none;
    }
    &-next,
    &-prev {
      height: 3.6rem;
      width: 3.6rem;
      border: 0.1rem solid $color-second;
      border-radius: 0.4rem;
      transition: 0.3s ease-in-out;
      margin-top: -7rem;
      @include max(1350px) {
        margin-top:0;
      }

      &::after {
        color: $color-second;
        font-size: 1.6rem;
        transition: 0.3s ease-in-out;
      }

      @include hover {
        background: $color-second;

        &::after {
          color: white;
        }
      }
    }
    &-next {
      right: -6rem;
    }
    &-prev {
      left: -6rem;
    }
  }
  &-pagi {
    margin-top: 4rem;
    .swiper-pagination {
      position: relative;
      inset: 0;
    }
    @include max(768px) {
      margin-top:2rem;
    }
  }

  &-item {
  }

  &-box {
    display:flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }
  &-img {
    position: relative;
    padding-top: 100%;
    overflow: hidden;
    border-radius: 1.2rem;
    display: block;
    width: 100%;

    img {
      @include imgContain;
    }
  }
  &-name {
    @include fz-20;
    font-weight: 500;
    color: $color-four;
    transition: 0.3s ease-in-out;

    @include hover {
      color: $color-second;
    }
  }

  .swiper {
    margin: 0 -1.6rem;

    @include max(1100px) {
      margin: 0 -0.8rem;
    }
    &-slide {
      width: 25%;
      padding: 0 1.6rem;
      @include max(1100px) {
        padding: 0 0.8rem;
      }
      @include max(768px) {
        width: calc(100% / 3);
      }
      @include max(550px) {
        width: 50%;
      }
    }
  }
}

.repro {
  overflow: hidden;

  &-wrap {
    position: relative;
    padding: 6rem 0;

    @include max(768px) {
      padding: 4rem 0;
    }

  }
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }

    &::before {
      position: absolute;
      width: 100%;
      content: "";
      bottom: 0;
      left: 0;
      height:70%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 13.02%);
    }
  }

  &-ctn {
    padding-top: 6rem;
    padding-left: 4rem;
    @include max(1200px) {
      padding-left: 1.5rem;
    }
    @include max(768px) {
      padding-top: 3rem;
    }
    @include max(550px) {
      padding: 0 1.5rem;
    }
  } 
  &-flex {
    display: flex;
    margin: 0 -1.6rem;

    @include max(768px) {
      margin: 0;
      flex-direction: column;
      row-gap: 2rem;
    }
  }
  &-left {
    width: calc((5/12) * 100%);
    padding: 0 1.6rem;

    @include max(768px) {
      width: 100%;padding: 0;
      padding-right: 1.5rem;
    }

    &-ctn {
      padding-left: calc((100vw - 123rem) / 2);
    }

    .btn {
      margin-top: 4rem;
      @include max(768px) {
        display: none;
      }
    }
  }
  &-right {
    padding: 0 1.6rem;
    width: calc((7/12) * 100%);

    @include max(768px) {
      width: 100%;padding:0;
    }
    .btn {
      margin:  0 auto;
      margin-top:2rem;
      @include min(768px) {
        display: none;
      }
    }
  }

  &-box {
    position: relative;
    border-radius: 1.2rem;
    overflow: hidden;
  }
  &-desc {
    position: absolute;
    width: 100%;
    height: 100%;inset: 0;
    border-radius: 1.2rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 53.02%, rgba(244, 151, 18, 0.60) 83.23%);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    padding: 2rem;

  }

  &-name {
    @include fz-24;
    font-weight: 700;
    color: white;

    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-txt {
    color: white;
    @include fz-20;
    text-shadow: 0px 2px 4px rgba(16, 24, 40, 0.10), 0px 4px 6px rgba(16, 24, 40, 0.10);
  }

  &-img {
    padding-top: calc((474/280) * 100%);
    position: relative;
    overflow: hidden;
    border-radius: 1.2rem;


    img {
      @include imgCover;
      transition: 0.3s ease-in-out;
    }
  }

  &-slide {
    position: relative;
  }
  &-btn {
      position: relative;
      flex-direction: row-reverse;
      gap: 1rem;
      @include mid-flex;
      margin-top: 3rem;
      @include max(768px) {
        display: none;
      }

      &-next,
      &-prev {
        position: relative;
        inset: 0 !important;
        margin: 0 !important;
      }
    &-next,
    &-prev {
      height: 3.6rem;
      width: 3.6rem;
      border: 0.1rem solid $color-second;
      border-radius: 0.4rem;
      transition: 0.3s ease-in-out;
      margin-top: -5.2rem;

      &::after {
        color: $color-second;
        font-size: 1.6rem;
        transition: 0.3s ease-in-out;
      }

      @include hover {
        background: $color-second;

        &::after {
          color: white;
        }
      }
    }
    &-next {
      right: -6rem;
    }
    &-prev {
      left: -6rem;
    }
  }
  &-pagi {
    margin-top:4rem;
    @include max(768px) {
      margin-top:2rem;
    }

    .swiper-pagination {
      position: relative;
      inset: 0;
      height: 0.2rem;
      background: rgba(237, 224, 204, 0.75);

      &-progressbar-fill {
        background: $color-second;
      }
    }
  }
  .swiper {
    @include max(550px) {
      margin: 0 -1.5rem;
    }
    &-slide {
      width:calc((100% / 3) -  4rem);

      @include max(1100px) {
        width:calc((100% / 2) -  4rem);
      }
      @include max(768px) {
        width:calc((100% / 3) -  2rem);
      }
      @include max(550px) {
        width:100%;
        padding: 0 1.5rem;
      }
   
    }
  }
}

.sre {
  &-wrap {
    padding: 6rem 0 ;
    background: #F3F4F6;
    @include max(768px) {
      padding: 4rem 0;
    }
  }
  &-top {
    margin-bottom: 6rem;
    .btn {
      margin: 0 auto;
    }
  }
  &-rate {
    padding: 2rem;
    background-color: white;
    border-radius: 0.8rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
   
  }
  .srate-rate {
    display: flex;
    padding: 2rem;
    border-bottom: 0.1rem solid #E2E8F0;

    @include max(768px) {
      flex-wrap: wrap;
      flex-direction: column;
      align-items:center;
      padding:2rem 0;
    }
    
    &-top {
      flex-direction: column;
      width: 30%;
      border-right: 0.1rem solid  #E2E8F0;
      justify-content: center;
      @include max(768px) {
        border-right: none;
        width: 100%;
      }

      .text {
        text-align: center;
        color: $color-pri;
      }
      .srate-rate-star {
        .text {
          color: #475569;
          margin-top: 1rem;
        }
      }

  

    }
    &-ctn {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items:flex-end;

      @include max(768px) {
        width: 100%;
        flex: unset;
      }
    }
    &-item {
      width: 35rem;
      max-width: 100%;

      .text {
        color: #475569;
      }
    }
  }
  &-form {
    padding-top: 2rem;
    .sform-form {
    margin-top: 2rem;
    border: 1px solid  #E5E7EB;
    }
  }
  &-rv {
    margin-top:2rem;
    >.txt {
      color: #475569;
    }
    &-top {
      display:flex;
      flex-wrap: wrap;
      gap: 1.6rem;
      margin-top: 1.2rem; 
      @include max(600px) {
        gap: 1rem;
      }
    }
    &-item {
      @include mid-flex;
      gap: 0.4rem;
      border-radius: 0.4rem;
      border: 0.1rem solid #E5E7EB;
      width: max-content;
      padding: 0.5rem 1rem;
      height: 3.6rem;
      cursor: pointer;
      transition: 0.3s ease-in-out;

      .txt {
        color: #334155;
        font-weight: 500;
        line-height: 1;
        transition: 0.3s ease-in-out;

      }
      .icon {
        display: flex;
        width:1.4rem;
        height: 1.4rem;
      }
      &.active {
        border-color: $color-second;
        background-color: #FEF5E7;
        .txt {
          color: $color-second;
        }
      }
    }
    &-main {
      margin-top:3rem;
    }
    &-ctn {
      // display: flex;
      // flex-direction: column;
      // gap: 3rem;
      &:not(:first-child){
        display:none;
      }
    }
  }
}

.swiper-pagination {
  &-bullet {
    border: 0.1rem solid #000;
    background: none;
    opacity: 1;
    transition: 0.2s ease-in-out;
    &-active {
      background: $color-second;
      border: 0.1rem solid $color-second;
    }
  }
}

.rv {
  &-item {
    display: flex;
    flex-direction: column;
    &:not(:first-child) {
      margin-top: 3rem;
    }
  }
  &-top {
    display: flex;
    justify-content:space-between;
    gap: 1rem;flex-wrap: wrap;
    align-items: center;

    &-left {
      display: flex;
      gap: 2rem;
      align-items: center;
      @include max(600px) {
        gap: 1rem;
      }
    }
  }
  &-avt {
    width: 6rem;
    height: 6rem;
    border-radius: 100rem;
    overflow: hidden;
    @include max(600px) {
      width: 4rem;
      height: 4rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-name {
    color: #041E42;
    font-weight: 600;
    display: flex;
    gap: 0.4rem;

    .icon {
      width:2rem;
      height: 2rem;
      display: flex;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &-date {
    display: flex;
    align-items: center;
    gap: 0.4rem;

    .icon {
      width: 2rem;
      height: 2rem;
      display:flex;
      align-items: center;

      i {
      color: #475569;

      }
      
    }
    .txt {
      line-height: 1;
      color: #475569;
      font-weight: 600;
    }
  }
  &-ctn {
    padding-left: 8rem;
    @include max(600px) {
      padding-left: 5rem;

    }
    @include max(420px) {
      padding-left: 0;
    }
  }
  &-control {
    .txt {
      color: #475569;
      @include fz-14;
    }
    .link {
      color: #1D4ED8;
      @include fz-14;

    }
  }
  &-sub {
    margin-top: 2rem;
    display: flex;
    gap: 2rem;
    @include max(600px) {
      gap: 1rem;
    }
    // @include max(420px) {
    //   flex-direction: column;
    // }

    &-ctn {
      padding: 1.2rem;
      border-radius: 0.8rem;
      background:  #F1F5F9;
      flex: 1;
    }
  }
}
