//general css
.no-scroll {
  overflow-y: hidden !important;
}
.event-none {
  pointer-events: none;
}
.f-title {
  font-family: 'Alumni Sans', sans-serif;
}
.t-end {
  text-align: end;
}
.o-hidden {
  overflow: hidden;
}
.c-pri {
  color: $color-pri;
}
.c-second {
  color: $color-second;
}
.c-four {
  color: $color-four;
}
.c-five {
  color: $color-five;
}
.c-text {
  color: $color-text;
}
.c-white {
  color:$color-white;
}
.c-black {
  color:$color-black;
}
.c-grey {
  color: $color-grey;
}
.c-red {
  color: #E52346;
}
.c-blue {
  color: #1454D3;
}
.c-green {
  color:#0BAE62;
}
.fw-4 {
  font-weight: 400;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.j-beweent {
  justify-content: space-between;
}
.flex-end {
  justify-content: flex-end;
}
.flex-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.d-none {
  display: none;
}
.c-gap-1 {
  column-gap: 1rem;
}
.btnc {
  cursor: pointer;
}
.btn.trans {
  border: 0.1rem solid $color-pri;
}


.mt-24 {
  margin-top:2.4rem;
}
.mt-50 {
  margin-top: 5rem;

  @include max(768px) {
    margin-top: 3rem;
  }
}
.m-auto {
  margin: 0 auto;
}
.mt-10 {
  margin-top: 1rem;
}
.mt-12 {
  margin-top: 1.2rem;
}
.mt-15 {
  margin-top: 1.5rem;
}
.mt-20 {
  margin-top: 2rem;
}
.mt-30 {
  margin-top: 3rem;
}
.mt-40 {
  margin-top: 4rem;

  @include max(768px) {
    margin-top: 2rem;
  }
}
.mt-60 {
  margin-top: 6rem;
  @include max(768px) {
    margin-top: 3rem;
  }
}
.mt-80 {
  margin-top: 8rem;
  @include max(1024px) {
    margin-top: 4rem;
  }
}
.mt-100 {
  margin-top: 10rem;
  @include max(1024px) {
    margin-top: 6rem;
  }
}
.mb-15 {
  margin-bottom: 1.5rem;
}
.mb-50 {
  margin-bottom: 5rem;
}
.mb-60 {
  margin-bottom: 6rem;

  @include max(768px) {
    margin-bottom: 3rem;
  }
}
.mb-100 {
  margin-bottom: 10rem;
  @include max(768px) {
    margin-bottom: 5rem;
  }
}
.pt-30 {
  padding-top: 3rem;
}
.pt-10 {
  padding-top: 1rem;
}
.pb-200 {
  padding-bottom: 20rem;
}
.pb-250 {
  padding-bottom: 25rem;

  @include max(768px) {
    padding-bottom: 20rem;
  }
}
.sec-py {
  padding: 10rem 0;

  @include max(1300px) {
    padding: 8rem 0;
  }

  @include max(1024px) {
    padding: 6rem 0;
  }

  @include max(768px) {
    padding: 4rem 0;
  }
}

a.under {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 0.1rem;
    width: 0;
    transition: 0.3s ease-in-out;
    background-color: black;
  }
  @include hover{
    color: black;
    &:before {
      width: 100%;
    }
  }
}

.btn {
  &.bg-sec {
    background-color: $color-second;
    transition: 0.3s ease-in-out;
    .text {
      color: $color-pri;
      transition: 0.3s ease-in-out;
    }

    &:hover {
      .text {
        color: #fff;
      }
    }
  }
}
.t36 {
  font-size: 3.6rem;

  @include max(1200px) {
    font-size: 2.8rem;
  }
}
.t52 {
  font-size: 5.2rem;
  @include max(1200px) {
    font-size: 3.5rem;
  }
}
.t64 {
  font-size: 6.4rem;

  @include max(1200px) {
    font-size: 4.8rem;
  }
}

.none-events {
  pointer-events: none;
}

.hv-item {
  position: relative;
  overflow: hidden;
}
@media (hover: hover) {
  .hv-item:hover::before {
    -webkit-animation: shine 1.25s;
    animation: shine 1.25s;
  }
}
.hv-item::before {
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.ip-control {
  width: 100%;
  position: relative;

  &.x2 {
    width: 50%;
  }
  &.x3 {
    width: calc(100% / 3);
  }
}
.wp-block-embed__wrapper {
  iframe {
    width: 100%;
    height:40rem;
    @include max(768px) {
      height:30rem;
    }
  }
}

.ourl-top-txt  {
  margin-top:2rem;
  text-align: center;
}
.fixed-nav {
  margin-right: 1.1rem !important;
}
.post-detail {
  .dpost {
    @include max(768px) {
      overflow: hidden;
    }
  }
}
