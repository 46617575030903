.btn {
    @include flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    height: 4.4rem;
    width: fit-content;
    overflow: hidden;
    min-width: 11rem;
    padding: 0 2rem;

    .inner {
        padding: 1rem 2rem;
        @include mid-flex;

    }
    img {
        position: relative;
        z-index: 3;
    }

    .icon {
        width: 2rem;
        height: 2rem;
        position: relative;
        z-index: 3;
        // margin-top: -0.2rem;
        flex-shrink: 0;

        &:first-child {
            margin-right: 0.8rem;
        }

        &:last-child {
            margin-left: 0.8rem;
        }

        img {
            width: inherit;
            height: inherit;
        }

        i {
            transition: 0.4s;
        }
    }
    &.center {
        margin: auto;
        margin-top: 2rem;
    }
    .text {
        color: $color-white;
        z-index: 3;
        @include fz-16;
        position: relative;
        display: block !important;
        white-space: nowrap;
        transition: 0.4s;
        font-weight: 700;
    }

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        transform: scaleY(0);
        transform-origin: bottom right;
        transition: transform 0.5s ease;
    }
    &::before {
        display: block;
        position: absolute;
        inset: 0.1rem;
        transform: translateY(-150%);
        width: 100%;
        z-index: 0;
        content: '';
        background: $color-white;
        transition:  0.4s ;

    }

    @include min($res-mobile-min) {
        @include hover {
            &::after {
                transform: scale(1);
                transform-origin: top right;
            }
        }
    }

    // &-pri {
    //     @extend .btn;
    //     border-radius: 0.4rem;
    //     border: 0.1rem solid $color-second;

    //     &::after {
    //         background: $color-white;
    //         // border-left: 0.1rem solid $color-second;
    //         // border-right: 0.1rem solid $color-second;
    //     border-radius: 0.3rem;
    //     }
    //     &::before {
    //         background: $color-second;
    //     border: 0.1rem solid $color-second;

    //     }

    //     .text {
    //         color: $color-white;
    //     }

    //     .icon {
    //         i {
    //             color: $color-white;
    //         }
    //     }

    //     @include hover {
    //         .text {
    //             color: $color-second;
    //         }

    //         .icon {
    //             i {
    //                 color: $color-second
    //             }
    //         }
    //     }
    // }
    &-pri {
        @extend .btn;
        border-radius: 0.4rem;
        border: 0.1rem solid $color-second;
        background-color:$color-second;
        transition: 0.3s ease-in-out;
        

        &::after {
            display: none;
        }
        &::before {
            border-radius: 0.4rem;
            // border: 0.1rem solid $color-second;
        }
       

        .text {
            color: $color-white;
        }

        .icon {
            i {
                color: $color-white;
            }
        }

        @include hover {
            background-color: $color-white;
            // &::before {
            //     transform: translateY(0);
    
            // }
            .text {
                color: $color-second;
            }

            .icon {
                i {
                    color: $color-second ;
                }
            }
        }
    }

    &-bd {
        @extend .btn;
        background:transparent;

        &::after {
            background: linear-gradient(90deg, rgba(182, 111, 41, 0.10) 0.37%, rgba(220, 154, 39, 0.10) 27.52%, rgba(237, 179, 31, 0.10) 52.76%, rgba(215, 148, 42, 0.10) 80.98%, rgba(176, 104, 40, 0.10) 100%);
        }

        .text {
            background:$linear;
        }

        .icon {
            i {
                color: $color-white;
            }
        }

        @include hover {
            .text {
                color: $color-pri;
            }

            .icon {
                i {
                    color: $color-pri
                }
            }
        }
    }
    &-second {
        @extend .btn;
        background: transparent;
        border-radius: 0.4rem;
        border: 1px solid $color-second;
        &::after {
            background: $color-second;
        }

        .text {
            color: $color-second;
        }

        .icon {
            i {
                color: $color-second;
            }
        }

        @include hover {
            .text {
                color: $color-white;
            }

            .icon {
                i {
                    color: $color-white
                }
            }
        }
    }
    &-thir {
        @extend .btn;
        background: #3A6BD0;
        border-radius: 3rem;
        border: 1px solid #3A6BD0;
        &::after {
            background: $color-white;
        }

        .text {
            color: $color-white;
        }

        .icon {
            i {
                color: $color-white;
            }
        }

        @include hover {
            .text {
                color: #3A6BD0;
            }

            .icon {
                i {
                    color: #3A6BD0
                }
            }
        }
    }

    &-four {
        @extend .btn;
        background:transparent;
        border-radius: 3rem;
        border: 1px solid $color-second;
        &::after {
            background: $color-second;
        }

        .text {
            color: $color-second;
        }

        .icon {
            i {
                color: $color-second;
            }
        }

        @include hover {
            .text {
                color: $color-white;
            }

            .icon {
                i {
                    color: $color-white
                }
            }
        }
    }
    &.full {
        width: 100%;
    }
}

.info-see {
    @include mid-flex;
    color: $color-pri;
    width: fit-content;

    i {
        transition: 0.4s;
        margin-top: -0.1rem;
    }

    .text {
        font-weight: 700;
        margin-top: 0rem;
        transition: 0.4s;

        &:last-child {
            margin-left: 0.8rem;
        }

        &:first-child {
            margin-right: 0.8rem;
        }
    }

    @include hover {
        i {
            transform: rotate(360deg);
            color: #DCA200;
        }

        .text {
            color: #DCA200;
        }
    }
}

.bd-ln {
    position: relative;
    width: fit-content;
    display: grid;
    place-items: center;
    padding: 0.2rem;
    &::before {
        content: "";
        position: absolute;
        @include full(0,0,0,0);
        padding: 0.2rem;
        background: $linear;
        border-radius: 100rem;
        -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
    }
}