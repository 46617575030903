.post-detail {
  .header-nav .menu-nav > .menu-list > .menu-item > .menu-link {
    color: #374151;
    @include hover {
      color: $color-second;
    }
  }
  .header-nav .menu-nav > .menu-list > .menu-item.current_page_item > a,
  .header-nav .menu-nav > .menu-list > .menu-item.current-menu-item > a {
    color: $color-second;
  }
  .header-search .btn-icon {
    border: 0.1rem solid #374151;

    i {
      color: #374151;
    }
  }
  .main,
  .site-main {
    padding-top: var(--size-hd);
  }
  .deourl-top {
    @include max(768px) {
      position: relative;
      top: 0;
    }
  }
}

.dpost {
  background: #f8f8f8;

  .breadcrumb {
    padding: 1.2rem 0;
  }
  .deourl-top {
    margin-top: 1.6rem;
  }
  .tech-flex {
    flex-direction: row-reverse;
  }

  &-ctn {
    padding: 4rem 0;

    &-main {
        width: calc((8/12) * 100%);
        margin: 0 auto;
        @include max(1200px) {
            width: calc((10/12) * 100%)
        }
        @include max(992px) {
            width: 100%;
        }

        .mona-content {
          font-family: "Inter";
        }
    }

    &-title {
        @include fz-36;
        font-weight: 700;
        color: $color-pri;
    }
    .wp-block-image figure {
        width: 100%;
        border-radius:1.2rem;
        overflow: hidden;
        position: relative;
        padding-top: calc((451/800) * 100%);

        img {
            @include imgCover;
        }
    }
  }
  &-link {
    display:flex;
    padding-top:2rem ;
    align-items: center;
    gap: 2.4rem;
    border-top: 0.1rem solid #E5E5E5;
    margin-top: 4rem;

    &-list {
        display:flex;
        gap: 1rem;
    }

    &-item {
        @include mid-flex;
        width: 3.2rem;
        height:3.2rem;
        img {
            width: 100%;height: 100%;
            object-fit: contain;
            transition: 0.3s linear;
            // filter: brightness(0) saturate(100%) invert(74%) sepia(0%) saturate(26%) hue-rotate(242deg) brightness(90%) contrast(82%);
        }

        @include hover {
            img {
                transform: translateY(-0.5rem);
                // filter: brightness(0) saturate(100%) invert(59%) sepia(83%) saturate(1166%) hue-rotate(355deg) brightness(100%) contrast(92%); 
            }
        }
    }
  }
}

.swhy {
  padding-top: 4rem;
  overflow: hidden;

  &-flex {
    display: flex;
    margin: 0 -1.6rem;
    margin-top: 4rem;
    @include max(1100px) {
      margin: 0 -0.4rem;
      margin-top: 3rem;
    }
    @include max(768px) {
      flex-direction: column;
      row-gap: 1rem;
    }
  }
  &-left {
    padding: 0 1.6rem;
    width: 50%;
    @include max(1100px) {
      padding: 0 0.4rem;
    }

    @include max(768px) {
      width: 100%;
    }
  }
  &-right {
    width: 50%;
    padding: 0 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    @include max(1100px) {
      padding: 0 0.4rem;
    }
    @include max(768px) {
      width: 100%;
    }
  }
  &-vi {
    width: 100%;
    position: relative;
    border-radius: 1.2rem;
    overflow: hidden;
    display: flex;
    padding-top: 100%;
    border-radius: 1.2rem;
    transition: 0s !important;
    video,
    iframe {
      @include imgContain;
    }
    img {
      @include imgCover;
      transition: 0.3s ease-in-out;
    }
    @include hover {
      img {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }

    &.tabPanel {
      &:not(:first-child) {
        display: none;
      }
    }
  }
  &-desc {
    &-item {
      margin-bottom: 3.2rem;
      cursor: pointer;
      position: relative;
      @include max(768px) {
        margin-bottom: 1.2rem;
      }

   

      &:last-child {
        margin-bottom: 0;
      }
      &.tabBtn {
        &:not(.active){
          .text {
            display: none;
          }
        }
      }
      &.active {
        .title:before {
          opacity: 1;
          transform: translateX(0);
          visibility: visible;
        }
        .title {
          padding-left: 2rem;
        }
      }
    }
    .title {
      @include fz-28;
      color: $color-second;
      font-weight: 700;
      position: relative;
      width: max-content;
      max-width: 100%;
      transition: 0.3s ease-in-out;
      
      &:before {
        content: ">";
        position: absolute;
        left: 0;
        top: 0.5rem;
        color: $color-second;
        font-weight: 700;
        @include fz-22;
        transition: 0.3s ease-in-out;
        transform: translateX(0.5rem);
        opacity: 0;
        visibility: hidden;
        @include max(768px) {
          top: 0;
        }
      }
    }
    .text {
      color: $color-four;
      @include fz-20;
      // font-family: "Univers-Light-Normal";
    }
  }
}

.safe {
  padding-top: 6rem;
  @include max(768px) {
    padding-top: 4rem;
  }
  &-wrap {
    padding: 3.4rem 0;
    background-color: #fef5e7;
  }
  &-item {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .pcontact-img {
    width: 100%;
    padding-top: calc((320 / 480) * 100%);
  }
  &-name {
    color: #000;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-in-out;
  }
  &-slide {
    &-inner {
      position: relative;
      @include max(768px) {
        padding-bottom: 3rem;
      }
    }
  }
  &-pagi {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    @include max(768px) {
      display: block !important;
    }
    .swiper-pagination {
      position: relative;
      inset: 0;
    }
  }
  .pcontact-btn {
    @include max(768px) {
      display: none;
    }
  }

  .swiper {
    &-slide {
      &-active {
        .pcontact-img {
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
        .safe-name {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.lnews {
    &-wrap {
        padding: 6rem 0;

        @include max(768px) {
            padding: 4rem 0;
        }
    }
    &-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // grid-template-rows: repeat(4, 1fr);
        grid-template-rows: auto;

        grid-column-gap: 3rem;
        grid-row-gap: 3rem;
        margin-top: 3rem;

        @include max(1100px) {
            grid-column-gap: 1rem;
            grid-row-gap: 2rem;
        }
        @include max(768px) {
            display: flex;
            flex-wrap: wrap;
            row-gap: 2rem;
            margin: 0 -0.5rem;
            margin-top: 2rem;
            grid-column-gap: 0;
        }
    }
    &-item {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        @include min(768.99px) {
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9),
            &:nth-child(10) {
                flex-direction: row;
                
                @include min(1200px) {
                    gap: 2.5rem;
                }
    
                .lnews-img {
                    width: 40%;
                }
                .lnews-desc {
                    justify-content: center;
                    gap: 0;
                }
    
                .lnews-name {
                    @include fz-16;
                }
                .lnews-txt {
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
      

        &:nth-child(1) {
            grid-area: 1 / 1 / 5 / 3;

            .lnews-img .box {
                padding-top: calc((490/800) * 100%);
            }
        }
        &:nth-child(2) {
            grid-area: 1 / 3 / 2 / 4; 
        }
        &:nth-child(3) {
            grid-area: 2 / 3 / 3 / 4;
        }
        &:nth-child(4) {
            grid-area: 3 / 3 / 4 / 4; 
        }
        &:nth-child(5) {
            grid-area: 4 / 3 / 5 / 4; 
        }
        &:nth-child(6) {
            grid-area: 5 / 2 / 9 / 4; 
            .lnews-img .box {
                padding-top: calc((490/800) * 100%);
            }
        }
        &:nth-child(7) {
            grid-area: 5 / 1 / 6 / 2;
        }
        &:nth-child(8) {
            grid-area: 6 / 1 / 7 / 2;
        }
        &:nth-child(9) {
            grid-area: 7 / 1 / 8 / 2; 
        }
        &:nth-child(10) {
            grid-area: 8 / 1 / 9 / 2; 
         
        }

        @include max(768px) {
            padding: 0 0.5rem;
            &:not(:nth-child(1)) {
                width: 50%;
            }
            &:nth-child(1) {
                width: 100%;
            }
            &:nth-child(6) {
                width: 100%;
            }
          
        }

    }
    &-img {
        .box {
            display: block;
            position: relative;
            overflow: hidden;
            border-radius: 1.2rem;
            padding-top: 100%;

            img {
                @include imgCover;
                transition: 0.3s ease-in-out;
            }
            @include hover {
                img {
                    transform: translate(-50%,-50%) scale(1.05);
                }
            }
        }
    }
    &-desc {
        display:flex;
        flex-direction:column;
        gap: 0.8rem;
        flex: 1;
    }
    &-date {
        color: $color-second;
    }
    &-name {
        @include fz-24;
        font-weight: 700;
        color: #383838;
        transition:0.3s ease-in-out;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @include hover {
            color: $color-second;
        }
    }
    &-txt {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @include fz-14;
    }
    &-btn {
        margin-top:4rem;

        .btn {
            margin: 0 auto;
        }
    }
}

#ez-toc-container {
  font-family: "Univers-Light-Normal";
  width: 100% !important;
  border-radius: 12px !important;
background:  #E6F1F2 !important;
border: none !important;

.ez-toc-title  {
  font-weight:700 !important;
  color: $color-second;
}
}