.page-numbers {
    @include flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 5;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    >li {
        &:not(:first-child) {
            margin-left: 1rem;
        }
        // &:first-child {
        //     margin-right: auto;
        // }
        // &:last-child {
        //     margin-left: auto;
        // }

        &>.page-numbers {
            height: 3.2rem;
            width: 3.2rem;
            border-radius: 0.5rem;
            @include mid-flex;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            color:rgba(133, 140, 130, 1);
            @include fz-14;
            font-weight: 400;
            transition: 0.4s ease;
            @include affter($color-second);
            line-height:1.5;
            // border: 1px solid rgba(31, 67, 151, 0.10);



            &.next,
            &.prev {
                width:  max-content;
                padding: 0.7rem 1.4rem;background-color: white;
                gap: 0.5rem;
                border: 1px solid  #D0D5DD;
                i {
                    color: $color-black;
                    font-weight: 700;
                    transition: 0.3s ease-in-out;
                }
            }

            &:after {
                z-index: -1;
                background: $color-pri;
            }

            @include hover {
                &:not(.dot) {
                    color: white;

                    i {
                        color: white;
                    }

                    &::after {
                        left: 0; 
                        width: 100%;
                    }
                }
            }

            &.current {
                color: $color-second;
                font-weight: 700;
                background-color: rgba(244, 151, 18, 0.1);

                
            }

            &.dot {
                background-color: transparent;
            }

            i {
                font-weight: 400;
            }
        }
    }
}