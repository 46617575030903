.pcontact {
    padding: 6rem 0;
    @include max(768px) {
        padding:4rem 0;
    }

    &-flex {
        display: flex;
        margin: 0 -1rem;
        @include max(768px) {
            flex-direction: column;
            row-gap: 2rem;
        }
    }
    &-left {
        padding: 0 1rem ;
        width: 50%;
        @include max(768px) {
            width: 100%;
        }
    }
    &-right {
        flex: 1;
        padding: 0 1rem;
    }
    &-top {
        .title {
            @include fz-45;
            line-height: 1.2;
        }
        .subtitle {
            font-family: 'Univers-Light-Normal';
        }
    }
    &-list {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-top: 4rem;
    }
    &-item {
        font-family: "Inter";
        color: #1F2937;
        .link {
            transition:0.3s ease-in-out;

            @include hover {
                color: $color-second;
            }
        }
    }
    &-map {
        border-radius: 0.8rem;
        border: 0.2rem solid $color-second;
        overflow: hidden;

        iframe {
            width: 100%;height: 100%;
        min-height: 39rem;


        }
    }
    &-img {
        position: relative;
        padding-top: calc((320/481) * 100%);
        overflow: hidden;
        border-radius: 0.8rem;
        transition: 0.3s ease-in-out;

        &::before {
            position:absolute;
            content: "";
            width: 100%;
            height: 100%;
            inset: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
            z-index: 1;
            transition: 0.3s ease-in-out;

        }

        img {
            @include imgCover;
        }
    }
    &-slide {
        margin-top: 4rem;
        padding: 3.5rem 0;
        @include max(768px) {
            padding: 2rem 0;
            margin-top: 2rem;
        }

        &-inner {


            .swiper {
                padding:0 calc((4/12) * 100%);

                @include max(768px) {
                    padding:0 calc((3/12) * 100%);
                }
                @include max(550px) {
                    padding:0 calc((2/12) * 100%);
                }

                &-slide {
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.3s ease-in-out;

                    &-active {
                        opacity: 1;
                        visibility: visible;
                        .pcontact-img {
                            border-radius: 0.4rem;
                            &::before {
                                opacity: 0;
                            }
                        }
                    }
                    &-prev,
                    &-next {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
    &-btn {
        @include max(550px) {
            position: relative;
            flex-direction: row-reverse;
            gap: 1rem;
            @include mid-flex;
            margin-top: 2rem;

            &-next,
            &-prev {
                position: relative;
                inset: 0;
                margin: 0;
            }
        }
        &-next,
        &-prev {
            height: 3.6rem;
            width: 3.6rem;
            border: 0.1rem solid $color-second;
            border-radius: 0.4rem;
            transition: 0.3s ease-in-out;

            &::after {
                color: $color-second;
                font-size: 1.6rem;
            transition: 0.3s ease-in-out;

            }

            @include hover {
                background: $color-second;

                &::after {
                    color: white;
                }
            }
        }
    }
}

.sform {
    overflow: hidden;
    &-wrap {
       padding: 6rem 0;
       background: #F8F8F8;
    }
    &-top {
        display: flex;
        gap: 2.4rem;

        .title {
            @include fz-45;
            line-height: 1.2;
        }
        @include max(768px) {
            gap: 1.4rem;
        }
        @include max(550px) {
            br {
                display: none;
            }
        }
        .desc {
            .txt {
                color: $color-four;
                @include fz-22;
                font-weight: 500;
                font-family: 'Univers-Light-Normal';
            }
        }
    }
    &-flex {
        display: flex;
        margin: 0 -1.5rem;
        margin-top: 1.2rem;
        @include max(992px) {
            margin: 0 -0.5rem;
            margin-top: 1.2rem;

        }
        @include max(768px) {
            flex-direction: column;
            row-gap: 1rem;
        }
    }
    &-left {
        padding: 0 1.5rem;
        width: 50%;
        @include max(992px) {
            padding: 0 0.5rem;
        }
        @include max(768px) {
            width: 50%;
            margin: 0 auto;
        }
        @include max(500px) {
            width: 60%;
        }
        @include max(400px) {
            width: 70%;
        }
    }
    &-right {
        padding: 0 1.5rem;
        width: 50%;
        @include max(992px) {
            padding: 0 0.5rem;
        }
        @include max(768px) {
            width: 100%;
        }

    }
    &-img {
        width: 100%;
        img {
            width: 100%;
            object-fit: contain;
        }
    }
    &-form {
        padding: 3.2rem;
        background: #fff;
        border-radius: 1.2rem;
        box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.10), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

        @include max(1100px) {
            padding:2rem 1rem;
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -1.6rem;
            row-gap: 2.4rem;
        }
        .ip-control {
            padding: 0 1.6rem;
        }

        &-btn {
            margin-top: 3rem;
        }
        
    }
}

.scroll-btn {
    background-color: $color-second;
    display: flex;
    flex-direction: column;
    width: 5.6rem;
    height: 10.2rem;
    border-radius: 0.8rem;
    flex-shrink: 0;    
    @include max(768px) {
        width: 4rem;
        height:8rem;
    }
    &-up,
    &-down {
        height: 50%;
        @include mid-flex;
        i {
            color: white;
            font-size: 2.4rem;
        }
    }
}

