.header {
  height: var(--size-hd);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.3s ease-in-out;
  z-index: 999;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   @include full;
  //   backdrop-filter: blur(2.5rem);
  //   z-index: -1;
  //   @include max(1200px) {
  //     backdrop-filter: unset;
  //   }
  // }

  &.sticky {
    height: calc(var(--size-hd) - 4rem);
    margin-top:-4rem;
    @include max(1200px) {
      margin-top: 0;
    }
    .header-wrapper {
      padding: 1rem 0;
      background: white;
      height: 7rem;
      @include max(1200px) {
        height:8rem;
      }
    }
    .menu-item.dropdown >.mega-menu {
      top: var(--size-hd-sticky) !important;
    }
    .header-nav .menu-nav > .menu-list > .menu-item > .menu-link,
    .header-nav .menu-item > .menu-link > i {
      color: $color-pri;
    }
    .header-nav .menu-nav > .menu-list > .menu-item.current_page_item > a, .header-nav .menu-nav > .menu-list > .menu-item.current-menu-item > a {
      color: $color-second;
    }
    .header-nav .menu-nav > .menu-list > .menu-item {
      @include hover {
        .menu-link {
          color: $color-second !important;

        }
      }
    }
    .header-search {
      .btn-icon {
        border: 0.1rem solid $color-pri;
        i {
          color: $color-pri;
        }
      }
    }
    .header-search-form {
      top: calc(100% + 1rem);
    }
  }

  &-top {
    height: 4rem;
    background-color: #000;

    @include max(1200px) {
      display: none;
    }

    &-flex {
      display: flex;
      justify-content:space-between;
      height: inherit;
    }
    &-left {
      display: flex;
      align-items:center;
    }
    &-right {
      display: flex;
      align-items:center;
      gap: 3.2rem;
    }
        
    &-lang {
      display: flex;
      column-gap:2.4rem;
      position: relative;
      margin-bottom: 1rem;
      .icon {
        display: flex;
        width: 3rem;
        height: 3rem;
        border-radius: 100rem;
        position: relative;
        padding: 0.2rem;
        cursor: pointer;


        &.active {
          &:before { 
            width: 100%;
            height: 100%;
            position: absolute;
            content:"";
            border: 0.1rem solid $color-pri;
            border-radius: 100rem;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
          }
        }

        &:first-child {
          &:after {
            position: absolute;
            content: "";
            height: 100%;
            width: 0.2rem;
            background-color: $color-pri;
            top: 0;
            left:calc(100% + 1.2rem)

          }
        }


        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  &-info {
    display: flex;
    align-items:center;
    gap: 3.2rem;
    &-icon {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .icon {
        width: 2rem;
        height: 2rem;

        img {
          width: 100%;
          object-fit: contain;
        }
      }
      .txt {
        color: white;
        font-weight: 500;
        line-height: 1;
        font-family: 'Inter';
        transition: 0.3s ease-in-out;
        @include hover {
          color: $color-second;
        }
      }
  
    }
  }
  &-wrapper {
    background: transparent;
    position: relative;
    z-index: 98;
    height: 11rem;
    padding: 2.5rem 0;
    transition: 0.3s ease-in-out;
    border-bottom: 0.1rem solid #D1D5DB;


    @include max(1200px) {
      height: 8rem;
      background: white;
      padding: 1rem 0;

    }

  }

  &-logo {
    padding-right: 2.6rem;
    height: inherit;
    @include mid-flex;

    @include max(1200px) {
      border-right: unset;
      padding: unset;
      position: absolute;
      width: max-content;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 1.5rem 0;
    }
 
  }
  .custom-logo-link {
    display: block;
    @include transitionRe;
    height: inherit;
    img {
      display: block;
      max-width: 100%;
      height: auto;
      transition: 0.3s ease-in-out;
      height: 100%;
      object-fit: contain;
    }
  }
  &-wrap {
    @include flex;
    height: inherit;
    align-items: center;
    
    @include max(1200px) {
      justify-content: flex-start;
      width: 100%;
      gap: 1.2rem;
    }
  }

  &-gr {
    @include flex;
    flex: 1;
    gap: 0.8rem;
    height: inherit;
    align-items: center;
    @include max(1200px) {
      flex: unset;
      margin-left: auto;
    }
  }
  &-nav {
    margin-left: auto;
    height: 100% !important;

    .menu {
      height: inherit;
      display: flex;
      align-items: center;
      gap: 2.8rem;
    }
  }

  &-search {
    position: relative;
    cursor: pointer;
    .ip-control {
      position: relative;
      input {
        background: transparent;
        border-color: $color-pri;
        color: #47464680;
        padding-right: 3.6rem;
        &:focus {
          border-color: $color-pri;
          background: #fff;
          color: $color-text;
        }
        &::placeholder {
          color: #47464680;
        }
      }
      .btn-icon {
        position: absolute;
        width: 3.6rem;
        height: 3.6rem;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        border-radius: 100px;
        background: rgba(255, 255, 255, 0.30);
        transition: 0.3s ease-in-out;
        @include hover {
          color: $color-pri;
        }
      }
    }
    .btn-icon {
      height: 4.5rem;
      width: 4.5rem;
      aspect-ratio: 1;
      border-radius: 100rem;
      background: rgba(255, 255, 255, 0.30);
      transition: 0.3s ease-in-out;
      border: 0.1rem solid transparent;

      .icon {
        @include mid-flex;
      }
      @include max(1200px) {
        width: 4rem;
        height: 4rem;
        border: 0.1rem solid $color-pri;

        i {
          color: $color-pri !important;
        }
      }

      i {
        font-weight: 500;
        font-size: 1.8rem;
        color: #fff;
        transition: 0.3s ease-in-out;
      }
    }

    &-form {
      position: absolute;
      top: calc(100% + 3rem);
      right: 0;
      width:30rem;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      transform: translateY(3rem);
      transition: 0.4s ease-in-out;
      opacity: 0;
      visibility: hidden;
      max-width:calc(100vw - 3rem);

      @include max(1200px) {
        top: calc(100% + 2rem) !important;
      }

      .popup-search-input {
        flex-direction: row;
        gap: 0;
        display: flex;
        border-radius: 0.4rem ;
        overflow: hidden;

        input {
          border-radius: 0;
          border: none;
        }

        .btn {
          width: 2rem;
          min-width: unset;
          margin: 0;
          border-radius: 0 0.4rem 0.4rem 0;
          &:after {
            display: none;
          }
          .icon {
            margin: 0;
            @include mid-flex;

     
          }
        }
      }
    }
    &.active {
      .header-search-form {
        transform: translate(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &-lg {
    position: relative;
    z-index: 99;
    flex-shrink: 0;
    @include max(1200px) {
      width: 3rem;
      height: 3rem;
      background-color: $color-text;
      border-radius: 100rem;
      @include mid-flex;
    }
    @include max(600px) {
      display: none;
    }
    &-it {
      @include flex;
      align-items: center;
      position: relative;
      padding: 0.4rem 0.7rem;
      overflow: hidden;

      .ic {
        width: 2.2rem;
        height: 2.2rem;
        @include mid-flex;
        position: relative;
        z-index: 2;

        img {
          width: inherit;
          height: inherit;
        }
      }

      .txt {
        color: white;
        line-height: 1;
        position: relative;
        z-index: 2;
        font-family: 'Inter';
        @include max(1200px) {
          display: none;
        }
      }
      @include max(1200px) {
        padding: 0;
        .ic {
          margin-right: 0 !important;
        }
      }
    }

    &-drop {
      position: absolute;
      top: calc(100% + 0.5rem);
      right: 0;
      transition: 0.4s;
      clip-path: inset(0 0 100% 0);
   
      .header-lg-it {
        border-radius: 0;
        position: relative;
        background-color: $color-text;

        &::after {
          content: "";
          width: 0;
          height: 0.2rem;
          background: $linear;
          transition: 0.4s;
          position: absolute;
          bottom: 0;
          left: 0;
        }

        &:first-child {
          border-radius: 0.4rem 0.4rem 0 0;
        }

        &:last-child {
          border-radius: 0 0 0.4rem 0.4rem;
        }

        @include hover {
          &::after {
            width: 100%;
          }
        }
        .txt {
          color: $color-white;
        }
      }
    }

    &:hover {
      .header-lg-drop {
        clip-path: inset(0 0 0 0);
      }
    }
  }
}
.social {
  &-list {
    display: flex;
    gap: 1.2rem;
  }
  &-item {
    width: 2rem;
    display: flex;
    align-items: center;
    img {
      width: 100%;
      object-fit: contain;
      transition: 0.3s ease-in-out;
    }
    @include hover {
      img {
        transform: translateY(0.5rem);
      }
    }
  }
}
