// --------------------------------------
.mobile {
  position: fixed;
  z-index: 140;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: 50rem;
  width: 50rem;
  background-color: white;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.05);
  @include transition(0.4s, linear);
  transform: translateX(-110%);
  overflow: hidden;

  @include max($mobile) {
    width: 70%;
    min-width: initial;
  }
  @include max(440px) {
    width: 100%;
  }

  // &.sticky {
  //   top: var(--size-hd-sticky);
  // }

  &.open {
    transform: translateX(0);
  }

  @include min($res-mobile-min) {
    display: none;
  }

  &-con {
    width: 100%;
    height: 100%;
    @include flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 4.4rem 2rem;
  }

  &-heading {
    font-size: 1.8rem;
    line-height: 1.5;
    color: $color-pri;
    font-weight: 700;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  &-overlay {
    @include full-fixed;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    @include transition(0.6s, ease);
    opacity: 0;
    visibility: hidden;
    transform: translateX(-110%);
    border-top: 1px solid $color-border-01;
    top: 0;

    // &.sticky {
    //   top: var(--size-hd-sticky);
    // }

    &.open {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }

    @include min($res-mobile-min) {
      display: none;
    }
  }

  &-wr {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative;
    @include flex;
    flex-direction: column;

    &::before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 0.1rem;
      width: calc(100% + 6rem);
      background: $color-grey-3;
    }
    .header-search {
      display: block;
      padding: 1.2rem;
      padding-bottom: 0;
    }
  }


  &-list {
    margin-bottom: 2rem;
  }

  &-content {
    padding: 2.4rem 1.6rem;
  }

  &-title {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1.6rem;
  }





  &-mxh {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    @include flex;
    align-items: center;
    border-top: 1px solid $color-border-01;

    &-link {
      @include mid-flex;
      flex: 1;
      height: 100%;
      background-color: white;
      cursor: pointer;

      &:not(:first-child) {
        border-left: 1px solid $color-border-01;
      }

      img {
        width: 3rem;
        height: 3rem;
        filter: brightness(0) saturate(100%) invert(22%) sepia(17%) saturate(767%) hue-rotate(178deg) brightness(94%) contrast(88%);

      }
    }
  }



  &-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 4.4rem;
    height: 4.4rem;
    padding: 1.5rem;
    @include mid-flex;
    cursor: pointer;
    z-index: 5;

    img {
      filter: brightness(0) invert(1);
    }

    .icon,i {
      transition: 0.6s ease-in-out;
      font-weight: 500;
      font-size: 2.2rem;
      color: $color-pri;
    }
  }

  &-contact {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .header-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .icon {
        width: 3rem;
        height: 3rem;
        display: flex;
        img {
          filter: brightness(0) saturate(100%) invert(22%) sepia(17%) saturate(767%) hue-rotate(178deg) brightness(94%) contrast(88%);
        }
      }

      .txt {
        color: $color-pri;
      }
    }

  }






}
