.header-nav {
  padding: 0 1.2rem;
  height: var(--size-hd);
  @include max($res-mobile) {
    display: none;
  }
  .menu {
    &-nav {
      height: 100%;

      > .menu-list {
        height: 100%;
        justify-content: center;
        margin: 0 -2rem;
        > .menu-item { 
          @include flex;
          align-items: center;
          height:inherit;
          padding: 0 2rem;

          &:last-child { 
            > .menu-list {
              left: initial;
              right: 0;
            }
          }

          &::after {
            @include pseudo;
            position: absolute;
            top: 100%;
            height: 1rem;
            left: 0;
            width: 100%;
          }
       

          &:hover:not(.dropdown),
          &:has(.current-menu-item) {
          }

          >.mega-menu{
            top: calc(var(--size-hd));
            position:fixed;
            left: 0;
            width: 100vw;
            max-width: 100vw;
            opacity: 0;
            visibility: hidden;
            transition: 0.2s ease-in-out;

           
          }

          &.dropdown {

            &:before {
              position: absolute;
              content: "";
              width: 100%;height: 100%;
              top: 100%;
              left: 0;
              visibility: hidden;
            }
           
            &:hover {
              &:before {
                visibility: visible;
              }
              >.mega-menu {
                top:calc(var(--size-hd));
             
                opacity: 1;
                visibility: visible;

                .mega-menu-hover.showed {
                  opacity: 1;
                  visibility: visible !important;
                  transform: translateX(0);
                  display: block;
                }
              
              }
            }
          }

          &:hover {
            > a {
              color: $color-second;
              i {
                transform: rotate(180deg);
              color: $color-second;

              }
            }
      
          
          }

          > .menu-link {
            @include flex;
            align-items: center;
            color: $color-white;
            position: relative;
            line-height: 1;
            height: 100%;
            cursor: pointer;
            font-family: "Inter";
            &::before {
              content: "";
              position: absolute;
              height: 0.1rem;
              background: $color-pri;
              top: 100%;
              left: 50%;
              transform: translateX(-50%);
              transition: 0.3s ease-in-out;
              opacity: 0;
              width: 0;
            }
          }

          &.current_page_item,
          &.current-menu-item {
            > a {
              color: $color-second;
              font-weight: 700;
            }

         
          
          }

          > .menu-list {
            top: calc(100% + 1rem);
          }
        }
      }
    }

    &-list {
      @include flex;
      align-items: center;
    }

    &-item {
      position: relative;
      @include transition(0.2s, ease-in-out);

      &:hover {
        > .menu-list {
          @include show;

          > .menu-item {
            &.current_page_item,
            &.current-menu-item {
              > a {

                color: $color-second;
              }
            }
          }
        }
      }

      .menu-link,
      a {
        @include transition(0.2s, ease-in-out);

        i {
          @include transition(0.2s, ease-in-out);
        }
      }

      > .menu-link,
      > a {
        color: $color-pri;
        display: block;
        font-size: 1.8rem;

        > i {
          display: none;
          font-weight: 600;
          margin-left: 0.4rem;
          font-size: 1.4rem;
          color: $color-white;
        }
      }

      &.dropdown {
        > a {
          > i {
            display: inline-block;
          }
        }
      }

      > .menu-list {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 20rem;
        max-width: 28rem;
        @include flex;
        flex-direction: column;
        align-items: flex-start;
        background: white;
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
          0px 8px 8px -4px rgba(16, 24, 40, 0.03);

        @include transitionRe;
        @include hidden(0, -5px);

        > .menu-item {
          margin-left: 0;
          width: 100%;

          > .menu-link,
          > a {
            padding: 0.8rem 1.6rem;
            @include flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            > i {
              transform: rotate(-90deg) translate(5px, 0);
              margin-top: 0.8rem;
              font-size: 1rem;
            }
          }

          > .menu-list {
            left: 100%;
          }
        }
      }
    }
  }
}

.mobile-nav {
  .menu {
    &-nav {
      > .menu-list {
        > .menu-item {
          &.current_page_item,
          &.current-menu-item {
            > a {
              color: $color-pri;
            }
          }

          &:has(.current-menu-item) {
            > a {
              color: $color-pri;
            }
          }

          > a {
            padding: 1.2rem;
            @include flex;
            justify-content: space-between;
            align-items: center;
            color: $color-pri;
            font-size: 1.4rem;
            font-weight: 600;
            text-transform: uppercase;
            border-bottom: 1px solid $color-border-01;

            &::before {
              display: none;
            }
          }
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      >.icon {
        padding: 1.5rem;
        height: 100%;
        cursor: pointer;
        i {
          font-size:1.6rem;
        }
      }
      &.dropdown {
        > a {
          > i {
            display: flex;
          }
        }
      }

      &.active {
        > a {
          > i {
            transform: rotate(180deg);
          }
        }
      }

      a {
        position: relative;

        i {
          width: 2rem;
          height: 2rem;
          @include mid-flex;
          display: none;
          @include transitionRe;
        }
      }

      > a {
        @include flex;
        align-items: center;
        justify-content: space-between;
        @include fz-22;
        color: $color-pri;
        font-weight: 600;
        text-transform: uppercase;
        padding: 1rem 0;
        text-transform: capitalize;
      }

      > .menu-list {
        display: none;
        margin-left: 1.6rem;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 2rem;
          bottom: 2rem;
          left: 0;
          width: 1px;
          background-color: $color-border-01;
        }

        > .menu-item {
          &.current_page_item,
          &.current-menu-item {
            > a {
              color: $color-pri;
            }
          }

          &:has(.current-menu-item) {
            > a {
              color: $color-pri;
            }
          }
        }
      }
    }
  }
  &-btn {
    .link {
      color: #474140;
      font-weight: 600;
      text-decoration-line: underline;
      font-size: 1.6rem;
    }
  }
}



.mega-menu {
  visibility: hidden;
  &-flex {
    display: flex;
    padding: 6rem;
    background: white;
    border-radius: 0.8rem;
    flex-wrap: wrap;
    row-gap: 2rem;

  }
  .mega-item {
    width: calc(100% / 3);
    display: flex;
    gap: 1.2rem;
    justify-content: center;

    &-img {
      width: 11rem;
      .box {
        padding-top: calc((342/112) * 100%);
        position: relative;
        overflow: hidden;

        img {
          @include imgContain;
        }
      }
    }

    &-name {
      @include fz-24;
      font-weight: 700;
      color: $color-second;
    }
    &-cate {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 1.2rem;
      .link {
        @include fz-20;
        color: $color-pri;

      
      }
    }
  }


}

.header.sticky .header-nav .menu-nav > .menu-list > .menu-item.dropdown:hover > .mega-menu {
  top: var(--size-hd-sticky);
}


@keyframes showmega {
  0% {
    transform: translateX(3rem);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}