@import url("../assets/font/Monsterrat/stylesheet.css");
@import url("../assets/font/Poppins/stylesheet.css");
@import url("../assets/font/Univers-font/stylesheet.css");
@import url("../assets/font/Inter/stylesheet.css");







* {
    box-sizing: border-box;
}

html {
    font-size: 10px;
    scroll-behavior: smooth;

    @media screen and (min-width: 2000px) {
        font-size: 100%;
    }
}

body {
    font-family: 'Univers LT Std';
    font-size: 16px;
    line-height: 1.55;
    font-weight: 400;
    color: #1A1A1A;
    overflow: hidden auto;

    @media screen and (min-width: 2000px) {
        font-size: 20px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

button,
select {
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    cursor: pointer;

}

button {
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    .text {
    font-family: 'Montserrat'; 

    }
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: 'Univers LT Std';
    border-radius: 0.4rem;

    &:focus {
        border: 0.1rem solid $color-pri;
        outline: none;
    }

}

.main,
.site-main {
    // min-height: 68vh;
    // padding-top: var(--size-hd);
}

.container {
    width: 100%;
    max-width: 123rem;
    height: 100%;
    margin: 0 auto;
    padding: 0 4rem;

   @media only screen and (max-width:1200px) {
    padding: 0 1.5rem;
   }
}



.no-scroll {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

.load-hidden {
    display: none;
}

iframe {
    vertical-align: middle;
}


img {
    max-width: 100%;
    height: auto;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    font-family: "Inter";
}