@charset "UTF-8";
@import url("../assets/library/fontawesome/css/all.css");
@import url("../assets/library/fontawesome/fontawesome/fontawesome.css");
@import url("../assets/library/aos/aos.css");
@import url("../assets/library/swiper/swiper-bundle.min.css");
@import url("../assets/library/select2/select2.min.css");
@import url("../assets/library/gallery/lightgallery.min.css");
@import url("../assets/library/fancybox/fancybox.css");
@import url("../assets/library/datetime/daterangepicker.css");
@import url("../assets/font/Monsterrat/stylesheet.css");
@import url("../assets/font/Poppins/stylesheet.css");
@import url("../assets/font/Univers-font/stylesheet.css");
@import url("../assets/font/Inter/stylesheet.css");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.mona-content strong,
.mona-content b {
  font-weight: bold; }

.mona-content em,
.mona-content i {
  font-style: italic; }

.mona-content h1,
.mona-content h2,
.mona-content h3,
.mona-content h4,
.mona-content h5,
.mona-content h6 {
  line-height: 1.3;
  margin-bottom: 0.5em;
  margin-top: 0.6em; }

.mona-content h1 {
  font-size: 1.7em; }

.mona-content h2 {
  font-size: 1.6em; }

.mona-content h3 {
  font-size: 1.25em; }

.mona-content h4 {
  font-size: 1.125em; }

.mona-content h5 {
  font-size: 1em; }

.mona-content h6 {
  font-size: 0.85em; }

.mona-content ul,
.mona-content ol {
  margin: 1em 0;
  list-style-position: inside; }

.mona-content ul ul,
.mona-content ul ol,
.mona-content ol ul,
.mona-content ol ol {
  margin-left: 1em; }

.mona-content ul {
  list-style-type: disc; }

.mona-content ol {
  list-style-type: decimal; }

.mona-content ul ul,
.mona-content ol ul {
  list-style-type: circle; }

.mona-content ol ol,
.mona-content ul ol {
  list-style-type: lower-latin; }

.mona-content img,
.mona-content iframe {
  max-width: 100% !important;
  height: auto; }

.mona-content blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1em 0;
  padding: 1em;
  quotes: "“" "”" "‘" "’"; }
  .mona-content blockquote::before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em; }
  .mona-content blockquote p {
    display: inline; }

.mona-content table {
  border-collapse: collapse;
  max-width: 100%;
  margin: 1em 0;
  border: 1px solid #e1e1e1; }
  .mona-content table th,
  .mona-content table td {
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    padding: 5px 10px;
    vertical-align: middle; }

.mona-content .mona-youtube-wrap {
  position: relative;
  height: 0;
  padding-top: 56.25%; }
  .mona-content .mona-youtube-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.mona-content .mona-table-wrap {
  overflow: auto; }

:root {
  --size-hd: 15rem;
  --pd-sc: 8rem;
  --size-hd-sticky: 7rem;
  --rewidth: calc((100vw - 120rem)/2);
  --recont: calc(100vw - (100vw - 120rem)/2); }
  @media (max-width: 1200px) {
    :root {
      --pd-sc: 4rem;
      --size-hd: 8rem; } }
  @media (max-width: 1230px) {
    :root {
      --rewidth: 1.5rem; } }
  @media (max-width: 1240px) {
    :root {
      --recont: calc(100%); } }

* {
  box-sizing: border-box; }

html {
  font-size: 10px;
  scroll-behavior: smooth; }
  @media screen and (min-width: 2000px) {
    html {
      font-size: 100%; } }

body {
  font-family: 'Univers LT Std';
  font-size: 16px;
  line-height: 1.55;
  font-weight: 400;
  color: #1A1A1A;
  overflow: hidden auto; }
  @media screen and (min-width: 2000px) {
    body {
      font-size: 20px; } }

a {
  text-decoration: none;
  color: inherit; }

button,
select {
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  cursor: pointer; }

button {
  padding: 0;
  background-color: transparent;
  cursor: pointer; }
  button .text {
    font-family: 'Montserrat'; }

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: 'Univers LT Std';
  border-radius: 0.4rem; }
  input:focus,
  textarea:focus {
    border: 0.1rem solid #374151;
    outline: none; }

.container {
  width: 100%;
  max-width: 123rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 4rem; }
  @media only screen and (max-width: 1200px) {
    .container {
      padding: 0 1.5rem; } }

.no-scroll {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none; }

.load-hidden {
  display: none; }

iframe {
  vertical-align: middle; }

img {
  max-width: 100%;
  height: auto; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  font-family: "Inter"; }

@-webkit-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-moz-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-ms-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-o-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@-moz-keyframes shine {
  100% {
    left: 125%; } }

@-ms-keyframes shine {
  100% {
    left: 125%; } }

@-o-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

@-webkit-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-moz-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-ms-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-o-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-moz-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-ms-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-o-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-moz-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-ms-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-o-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@-moz-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@-ms-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@-o-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@-moz-keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@-ms-keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@-o-keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@-webkit-keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@-moz-keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@-ms-keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@-o-keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@-webkit-keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@-moz-keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@-ms-keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@-o-keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@-webkit-keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@-moz-keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@-ms-keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@-o-keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@-webkit-keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@-moz-keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@-ms-keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@-o-keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@-webkit-keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@-moz-keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@-ms-keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@-o-keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@-webkit-keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@-moz-keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@-ms-keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@-o-keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@-webkit-keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@-moz-keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@-ms-keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@-o-keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-moz-keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-ms-keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-o-keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-webkit-keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@-moz-keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@-ms-keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@-o-keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@-webkit-keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

@-moz-keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

@-ms-keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

@-o-keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

@keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

.header {
  height: var(--size-hd);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.3s ease-in-out;
  z-index: 999; }
  .header.sticky {
    height: calc(var(--size-hd) - 4rem);
    margin-top: -4rem; }
    @media screen and (max-width: 1200px) {
      .header.sticky {
        margin-top: 0; } }
    .header.sticky .header-wrapper {
      padding: 1rem 0;
      background: white;
      height: 7rem; }
      @media screen and (max-width: 1200px) {
        .header.sticky .header-wrapper {
          height: 8rem; } }
    .header.sticky .menu-item.dropdown > .mega-menu {
      top: var(--size-hd-sticky) !important; }
    .header.sticky .header-nav .menu-nav > .menu-list > .menu-item > .menu-link,
    .header.sticky .header-nav .menu-item > .menu-link > i {
      color: #374151; }
    .header.sticky .header-nav .menu-nav > .menu-list > .menu-item.current_page_item > a, .header.sticky .header-nav .menu-nav > .menu-list > .menu-item.current-menu-item > a {
      color: #F49712; }
    @media (hover: hover) {
      .header.sticky .header-nav .menu-nav > .menu-list > .menu-item:hover .menu-link {
        color: #F49712 !important; } }
    .header.sticky .header-search .btn-icon {
      border: 0.1rem solid #374151; }
      .header.sticky .header-search .btn-icon i {
        color: #374151; }
    .header.sticky .header-search-form {
      top: calc(100% + 1rem); }
  .header-top {
    height: 4rem;
    background-color: #000; }
    @media screen and (max-width: 1200px) {
      .header-top {
        display: none; } }
    .header-top-flex {
      display: flex;
      justify-content: space-between;
      height: inherit; }
    .header-top-left {
      display: flex;
      align-items: center; }
    .header-top-right {
      display: flex;
      align-items: center;
      gap: 3.2rem; }
    .header-top-lang {
      display: flex;
      column-gap: 2.4rem;
      position: relative;
      margin-bottom: 1rem; }
      .header-top-lang .icon {
        display: flex;
        width: 3rem;
        height: 3rem;
        border-radius: 100rem;
        position: relative;
        padding: 0.2rem;
        cursor: pointer; }
        .header-top-lang .icon.active:before {
          width: 100%;
          height: 100%;
          position: absolute;
          content: "";
          border: 0.1rem solid #374151;
          border-radius: 100rem;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%); }
        .header-top-lang .icon:first-child:after {
          position: absolute;
          content: "";
          height: 100%;
          width: 0.2rem;
          background-color: #374151;
          top: 0;
          left: calc(100% + 1.2rem); }
        .header-top-lang .icon img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
  .header-info {
    display: flex;
    align-items: center;
    gap: 3.2rem; }
    .header-info-icon {
      display: flex;
      gap: 0.5rem;
      align-items: center; }
      .header-info-icon .icon {
        width: 2rem;
        height: 2rem; }
        .header-info-icon .icon img {
          width: 100%;
          object-fit: contain; }
      .header-info-icon .txt {
        color: white;
        font-weight: 500;
        line-height: 1;
        font-family: 'Inter';
        transition: 0.3s ease-in-out; }
        @media (hover: hover) {
          .header-info-icon .txt:hover {
            color: #F49712; } }
  .header-wrapper {
    background: transparent;
    position: relative;
    z-index: 98;
    height: 11rem;
    padding: 2.5rem 0;
    transition: 0.3s ease-in-out;
    border-bottom: 0.1rem solid #D1D5DB; }
    @media screen and (max-width: 1200px) {
      .header-wrapper {
        height: 8rem;
        background: white;
        padding: 1rem 0; } }
  .header-logo {
    padding-right: 2.6rem;
    height: inherit;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    @media screen and (max-width: 1200px) {
      .header-logo {
        border-right: unset;
        padding: unset;
        position: absolute;
        width: max-content;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 1.5rem 0; } }
  .header .custom-logo-link {
    display: block;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    height: inherit; }
    .header .custom-logo-link img {
      display: block;
      max-width: 100%;
      height: auto;
      transition: 0.3s ease-in-out;
      height: 100%;
      object-fit: contain; }
  .header-wrap {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    height: inherit;
    align-items: center; }
    @media screen and (max-width: 1200px) {
      .header-wrap {
        justify-content: flex-start;
        width: 100%;
        gap: 1.2rem; } }
  .header-gr {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex: 1;
    gap: 0.8rem;
    height: inherit;
    align-items: center; }
    @media screen and (max-width: 1200px) {
      .header-gr {
        flex: unset;
        margin-left: auto; } }
  .header-nav {
    margin-left: auto;
    height: 100% !important; }
    .header-nav .menu {
      height: inherit;
      display: flex;
      align-items: center;
      gap: 2.8rem; }
  .header-search {
    position: relative;
    cursor: pointer; }
    .header-search .ip-control {
      position: relative; }
      .header-search .ip-control input {
        background: transparent;
        border-color: #374151;
        color: #47464680;
        padding-right: 3.6rem; }
        .header-search .ip-control input:focus {
          border-color: #374151;
          background: #fff;
          color: #121212; }
        .header-search .ip-control input::placeholder {
          color: #47464680; }
      .header-search .ip-control .btn-icon {
        position: absolute;
        width: 3.6rem;
        height: 3.6rem;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        border-radius: 100px;
        background: rgba(255, 255, 255, 0.3);
        transition: 0.3s ease-in-out; }
        @media (hover: hover) {
          .header-search .ip-control .btn-icon:hover {
            color: #374151; } }
    .header-search .btn-icon {
      height: 4.5rem;
      width: 4.5rem;
      aspect-ratio: 1;
      border-radius: 100rem;
      background: rgba(255, 255, 255, 0.3);
      transition: 0.3s ease-in-out;
      border: 0.1rem solid transparent; }
      .header-search .btn-icon .icon {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center; }
      @media screen and (max-width: 1200px) {
        .header-search .btn-icon {
          width: 4rem;
          height: 4rem;
          border: 0.1rem solid #374151; }
          .header-search .btn-icon i {
            color: #374151 !important; } }
      .header-search .btn-icon i {
        font-weight: 500;
        font-size: 1.8rem;
        color: #fff;
        transition: 0.3s ease-in-out; }
    .header-search-form {
      position: absolute;
      top: calc(100% + 3rem);
      right: 0;
      width: 30rem;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      transform: translateY(3rem);
      transition: 0.4s ease-in-out;
      opacity: 0;
      visibility: hidden;
      max-width: calc(100vw - 3rem); }
      @media screen and (max-width: 1200px) {
        .header-search-form {
          top: calc(100% + 2rem) !important; } }
      .header-search-form .popup-search-input {
        flex-direction: row;
        gap: 0;
        display: flex;
        border-radius: 0.4rem;
        overflow: hidden; }
        .header-search-form .popup-search-input input {
          border-radius: 0;
          border: none; }
        .header-search-form .popup-search-input .btn, .header-search-form .popup-search-input .btn-pri, .header-search-form .popup-search-input .btn-bd, .header-search-form .popup-search-input .btn-second, .header-search-form .popup-search-input .btn-thir, .header-search-form .popup-search-input .btn-four {
          width: 2rem;
          min-width: unset;
          margin: 0;
          border-radius: 0 0.4rem 0.4rem 0; }
          .header-search-form .popup-search-input .btn:after, .header-search-form .popup-search-input .btn-pri:after, .header-search-form .popup-search-input .btn-bd:after, .header-search-form .popup-search-input .btn-second:after, .header-search-form .popup-search-input .btn-thir:after, .header-search-form .popup-search-input .btn-four:after {
            display: none; }
          .header-search-form .popup-search-input .btn .icon, .header-search-form .popup-search-input .btn-pri .icon, .header-search-form .popup-search-input .btn-bd .icon, .header-search-form .popup-search-input .btn-second .icon, .header-search-form .popup-search-input .btn-thir .icon, .header-search-form .popup-search-input .btn-four .icon {
            margin: 0;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            -ms-align-items: center;
            align-items: center;
            -webkit-align-items: center;
            justify-content: center;
            -webkit-justify-content: center; }
    .header-search.active .header-search-form {
      transform: translate(0);
      opacity: 1;
      visibility: visible; }
  .header-lg {
    position: relative;
    z-index: 99;
    flex-shrink: 0; }
    @media screen and (max-width: 1200px) {
      .header-lg {
        width: 3rem;
        height: 3rem;
        background-color: #121212;
        border-radius: 100rem;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center; } }
    @media screen and (max-width: 600px) {
      .header-lg {
        display: none; } }
    .header-lg-it {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      position: relative;
      padding: 0.4rem 0.7rem;
      overflow: hidden; }
      .header-lg-it .ic {
        width: 2.2rem;
        height: 2.2rem;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        position: relative;
        z-index: 2; }
        .header-lg-it .ic img {
          width: inherit;
          height: inherit; }
      .header-lg-it .txt {
        color: white;
        line-height: 1;
        position: relative;
        z-index: 2;
        font-family: 'Inter'; }
        @media screen and (max-width: 1200px) {
          .header-lg-it .txt {
            display: none; } }
      @media screen and (max-width: 1200px) {
        .header-lg-it {
          padding: 0; }
          .header-lg-it .ic {
            margin-right: 0 !important; } }
    .header-lg-drop {
      position: absolute;
      top: calc(100% + 0.5rem);
      right: 0;
      transition: 0.4s;
      clip-path: inset(0 0 100% 0); }
      .header-lg-drop .header-lg-it {
        border-radius: 0;
        position: relative;
        background-color: #121212; }
        .header-lg-drop .header-lg-it::after {
          content: "";
          width: 0;
          height: 0.2rem;
          background: linear-gradient(90deg, #FFAF61 0.37%, #DC9A27 27.52%, #EDB31F 52.76%, #D7942A 80.98%, #B06828 100%);
          transition: 0.4s;
          position: absolute;
          bottom: 0;
          left: 0; }
        .header-lg-drop .header-lg-it:first-child {
          border-radius: 0.4rem 0.4rem 0 0; }
        .header-lg-drop .header-lg-it:last-child {
          border-radius: 0 0 0.4rem 0.4rem; }
        @media (hover: hover) {
          .header-lg-drop .header-lg-it:hover::after {
            width: 100%; } }
        .header-lg-drop .header-lg-it .txt {
          color: #ffffff; }
    .header-lg:hover .header-lg-drop {
      clip-path: inset(0 0 0 0); }

.social-list {
  display: flex;
  gap: 1.2rem; }

.social-item {
  width: 2rem;
  display: flex;
  align-items: center; }
  .social-item img {
    width: 100%;
    object-fit: contain;
    transition: 0.3s ease-in-out; }
  @media (hover: hover) {
    .social-item:hover img {
      transform: translateY(0.5rem); } }

.footer {
  padding-bottom: 0;
  background: #0A0A0A;
  border-top: 1px solid #374151;
  font-family: "Inter"; }
  .footer-wrap {
    position: relative; }
  .footer-flex {
    display: flex;
    margin: 0 -1.2rem;
    justify-content: space-between;
    padding: 8rem 0; }
    @media screen and (max-width: 1200px) {
      .footer-flex {
        flex-wrap: wrap;
        row-gap: 2rem; } }
    @media screen and (max-width: 768px) {
      .footer-flex {
        padding: 4rem 0; } }
  .footer-col {
    padding: 0 1.2rem;
    color: #F3F4F6;
    min-width: 20%; }
    @media screen and (max-width: 450px) {
      .footer-col:nth-child(1) {
        width: 100%; }
        .footer-col:nth-child(1) .custom-logo-link {
          width: 50%; }
        .footer-col:nth-child(1) br {
          display: none; } }
    .footer-col .menu-list {
      margin-top: 1.6rem; }
      .footer-col .menu-list .menu-item {
        margin-bottom: 0.8rem; }
        .footer-col .menu-list .menu-item .menu-link {
          width: max-content;
          display: block; }
  .footer-logo {
    margin-bottom: 1.6rem; }
    .footer-logo .custom-logo-link {
      display: block;
      width: calc((68/205) * 100vw); }
  .footer-title {
    font-weight: 600;
    color: white; }
  .footer-info {
    margin-bottom: 1.2rem; }
    .footer-info-link {
      width: max-content; }
  .footer-social {
    display: flex;
    gap: 1.6rem;
    margin-top: 1.6rem; }
    .footer-social-link {
      width: 2.8rem;
      height: 2.8rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center; }
      .footer-social-link img {
        width: 100%;
        object-fit: contain;
        transition: 0.3s ease-in-out; }
      @media (hover: hover) {
        .footer-social-link:hover img {
          transform: translateY(-0.5rem); } }
  .footer-sign {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 3rem 0;
    position: relative; }
    .footer-sign::before {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      height: 0.1rem;
      width: 100vw;
      content: "";
      background-color: rgba(255, 255, 255, 0.1); }
    .footer-sign .text {
      color: white;
      font-size: 1.4rem;
      opacity: 0.7; }
    .footer-sign .icon {
      display: flex;
      height: 2.4rem;
      margin-bottom: 0.2rem; }
      .footer-sign .icon img {
        object-fit: contain;
        height: 100%; }

.fsocial {
  position: fixed;
  bottom: 13rem;
  right: 3rem;
  z-index: 55;
  transition: 0.5s linear;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center; }
  @media screen and (max-width: 1200px) {
    .fsocial {
      bottom: 9rem; } }
  @media screen and (max-width: 768px) {
    .fsocial {
      right: 1.5rem; } }
  .fsocial .icon {
    height: 8rem;
    width: 8rem;
    display: flex;
    margin-top: 2rem;
    transition: 0.3s ease-in-out;
    padding: 2rem 0.8rem;
    position: relative;
    border: 0.2rem solid white;
    border-radius: 100rem;
    background: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; }
    @media screen and (max-width: 1200px) {
      .fsocial .icon {
        width: 4rem;
        height: 4rem;
        padding: 1rem 0.5rem; } }
    .fsocial .icon img {
      object-fit: contain; }
    .fsocial .icon .circlejs {
      animation: growAndFade 3s infinite ease-out;
      background-color: dodgerblue;
      border-radius: 50%;
      opacity: 0;
      position: absolute;
      height: 6.7rem;
      width: 6.7rem;
      left: 50%;
      top: 50%;
      z-index: -1; }
    .fsocial .icon .circle1 {
      animation-delay: 1s; }
    .fsocial .icon .circle2 {
      animation-delay: 2s; }
    .fsocial .icon .circle3 {
      animation-delay: 3s; }
    .fsocial .icon:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      z-index: 0;
      border-radius: 100rem; }
    @media (hover: hover) {
      .fsocial .icon:hover {
        transform: scale(1.1); } }
    .fsocial .icon img {
      width: 100%;
      height: auto;
      -webkit-animation: shake-lr 4s linear infinite both;
      animation: shake-lr 4s linear infinite both; }

@-webkit-keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%; }
  10% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg); }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg); }
  80% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg); }
  90% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg); } }

@keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%; }
  10% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg); }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg); }
  80% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg); }
  90% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg); } }

/* ----------------------------------------------
 * Generated by Animista on 2023-9-22 14:11:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation shake-vertical
 * ----------------------------------------
 */
.shake-vertical {
  -webkit-animation: shake-vertical 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
  animation: shake-vertical 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both; }

@-webkit-keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px); }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); }
  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px); }
  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px); } }

@keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px); }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); }
  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px); }
  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px); } }

.hamburger {
  width: 4rem;
  overflow: hidden; }
  @media screen and (min-width: 1200px) {
    .hamburger {
      display: none; } }
  .hamburger-label {
    position: relative;
    display: block;
    height: 2.8rem;
    cursor: pointer; }
    .hamburger-label::before, .hamburger-label::after,
    .hamburger-label .hamburger-text {
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.2rem;
      background: #374151; }
    .hamburger-label::before, .hamburger-label::after {
      content: "";
      transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) left; }
    .hamburger-label::before {
      top: 0; }
    .hamburger-label::after {
      top: 1.2rem; }
  .hamburger-text {
    top: 2.4rem; }
    .hamburger-text::before {
      content: "CLOSE";
      position: absolute;
      top: 0.5rem;
      right: 0;
      left: 0;
      color: #374151;
      font-size: 1.1rem;
      font-weight: bold;
      text-align: center; }
  .hamburger.active .hamburger-label:before {
    left: -3.9rem; }
  .hamburger.active .hamburger-label:after {
    left: 3.9rem; }
  .hamburger.active .hamburger-label .hamburger-text:before {
    animation: moveUpThenDown 0.8s ease 0.2s forwards, shakeWhileMovingUp 0.8s ease 0.2s forwards, shakeWhileMovingDown 0.2s ease 0.8s forwards; }

@keyframes moveUpThenDown {
  0% {
    top: 0; }
  50% {
    top: -27px; }
  100% {
    top: -18px; } }

@keyframes shakeWhileMovingUp {
  0% {
    transform: rotateZ(0); }
  25% {
    transform: rotateZ(-10deg); }
  50% {
    transform: rotateZ(0deg); }
  75% {
    transform: rotateZ(10deg); }
  100% {
    transform: rotateZ(0); } }

@keyframes shakeWhileMovingDown {
  0% {
    transform: rotateZ(0); }
  80% {
    transform: rotateZ(3deg); }
  90% {
    transform: rotateZ(-3deg); }
  100% {
    transform: rotateZ(0); } }

@media screen and (min-width: 1200px) {
  .header-burger {
    display: none; } }

.mobile {
  position: fixed;
  z-index: 140;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: 50rem;
  width: 50rem;
  background-color: white;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.05);
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -ms-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transform: translateX(-110%);
  overflow: hidden; }
  @media screen and (max-width: 500px) {
    .mobile {
      width: 70%;
      min-width: initial; } }
  @media screen and (max-width: 440px) {
    .mobile {
      width: 100%; } }
  .mobile.open {
    transform: translateX(0); }
  @media screen and (min-width: 1200px) {
    .mobile {
      display: none; } }
  .mobile-con {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 4.4rem 2rem; }
  .mobile-heading {
    font-size: 1.8rem;
    line-height: 1.5;
    color: #374151;
    font-weight: 700;
    margin-bottom: 2rem;
    text-transform: uppercase; }
  .mobile-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    transition: 0.6s ease;
    -webkit-transition: 0.6s ease;
    -moz-transition: 0.6s ease;
    -ms-transition: 0.6s ease;
    -o-transition: 0.6s ease;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-110%);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    top: 0; }
    .mobile-overlay.open {
      opacity: 1;
      visibility: visible;
      transform: translateX(0); }
    @media screen and (min-width: 1200px) {
      .mobile-overlay {
        display: none; } }
  .mobile-wr {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column; }
    .mobile-wr::before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 0.1rem;
      width: calc(100% + 6rem);
      background: #E5DED8; }
    .mobile-wr .header-search {
      display: block;
      padding: 1.2rem;
      padding-bottom: 0; }
  .mobile-list {
    margin-bottom: 2rem; }
  .mobile-content {
    padding: 2.4rem 1.6rem; }
  .mobile-title {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1.6rem; }
  .mobile-mxh {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .mobile-mxh-link {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      flex: 1;
      height: 100%;
      background-color: white;
      cursor: pointer; }
      .mobile-mxh-link:not(:first-child) {
        border-left: 1px solid rgba(0, 0, 0, 0.1); }
      .mobile-mxh-link img {
        width: 3rem;
        height: 3rem;
        filter: brightness(0) saturate(100%) invert(22%) sepia(17%) saturate(767%) hue-rotate(178deg) brightness(94%) contrast(88%); }
  .mobile-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 4.4rem;
    height: 4.4rem;
    padding: 1.5rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
    z-index: 5; }
    .mobile-close img {
      filter: brightness(0) invert(1); }
    .mobile-close .icon, .mobile-close i {
      transition: 0.6s ease-in-out;
      font-weight: 500;
      font-size: 2.2rem;
      color: #374151; }
  .mobile-contact {
    display: flex;
    flex-direction: column;
    gap: 1rem; }
    .mobile-contact .header-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .mobile-contact .header-info .icon {
        width: 3rem;
        height: 3rem;
        display: flex; }
        .mobile-contact .header-info .icon img {
          filter: brightness(0) saturate(100%) invert(22%) sepia(17%) saturate(767%) hue-rotate(178deg) brightness(94%) contrast(88%); }
      .mobile-contact .header-info .txt {
        color: #374151; }

.header-nav {
  padding: 0 1.2rem;
  height: var(--size-hd); }
  @media screen and (max-width: 1199px) {
    .header-nav {
      display: none; } }
  .header-nav .menu-nav {
    height: 100%; }
    .header-nav .menu-nav > .menu-list {
      height: 100%;
      justify-content: center;
      margin: 0 -2rem; }
      .header-nav .menu-nav > .menu-list > .menu-item {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        height: inherit;
        padding: 0 2rem; }
        .header-nav .menu-nav > .menu-list > .menu-item:last-child > .menu-list {
          left: initial;
          right: 0; }
        .header-nav .menu-nav > .menu-list > .menu-item::after {
          content: '';
          position: absolute;
          position: absolute;
          top: 100%;
          height: 1rem;
          left: 0;
          width: 100%; }
        .header-nav .menu-nav > .menu-list > .menu-item > .mega-menu {
          top: calc(var(--size-hd));
          position: fixed;
          left: 0;
          width: 100vw;
          max-width: 100vw;
          opacity: 0;
          visibility: hidden;
          transition: 0.2s ease-in-out; }
        .header-nav .menu-nav > .menu-list > .menu-item.dropdown:before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          top: 100%;
          left: 0;
          visibility: hidden; }
        .header-nav .menu-nav > .menu-list > .menu-item.dropdown:hover:before {
          visibility: visible; }
        .header-nav .menu-nav > .menu-list > .menu-item.dropdown:hover > .mega-menu {
          top: calc(var(--size-hd));
          opacity: 1;
          visibility: visible; }
          .header-nav .menu-nav > .menu-list > .menu-item.dropdown:hover > .mega-menu .mega-menu-hover.showed {
            opacity: 1;
            visibility: visible !important;
            transform: translateX(0);
            display: block; }
        .header-nav .menu-nav > .menu-list > .menu-item:hover > a {
          color: #F49712; }
          .header-nav .menu-nav > .menu-list > .menu-item:hover > a i {
            transform: rotate(180deg);
            color: #F49712; }
        .header-nav .menu-nav > .menu-list > .menu-item > .menu-link {
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          align-items: center;
          color: #ffffff;
          position: relative;
          line-height: 1;
          height: 100%;
          cursor: pointer;
          font-family: "Inter"; }
          .header-nav .menu-nav > .menu-list > .menu-item > .menu-link::before {
            content: "";
            position: absolute;
            height: 0.1rem;
            background: #374151;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            transition: 0.3s ease-in-out;
            opacity: 0;
            width: 0; }
        .header-nav .menu-nav > .menu-list > .menu-item.current_page_item > a, .header-nav .menu-nav > .menu-list > .menu-item.current-menu-item > a {
          color: #F49712;
          font-weight: 700; }
        .header-nav .menu-nav > .menu-list > .menu-item > .menu-list {
          top: calc(100% + 1rem); }
  .header-nav .menu-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center; }
  .header-nav .menu-item {
    position: relative;
    transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s; }
    .header-nav .menu-item:hover > .menu-list {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0); }
      .header-nav .menu-item:hover > .menu-list > .menu-item.current_page_item > a, .header-nav .menu-item:hover > .menu-list > .menu-item.current-menu-item > a {
        color: #F49712; }
    .header-nav .menu-item .menu-link,
    .header-nav .menu-item a {
      transition: 0.2s ease-in-out;
      -webkit-transition: 0.2s ease-in-out;
      -moz-transition: 0.2s ease-in-out;
      -ms-transition: 0.2s ease-in-out;
      -o-transition: 0.2s ease-in-out;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s; }
      .header-nav .menu-item .menu-link i,
      .header-nav .menu-item a i {
        transition: 0.2s ease-in-out;
        -webkit-transition: 0.2s ease-in-out;
        -moz-transition: 0.2s ease-in-out;
        -ms-transition: 0.2s ease-in-out;
        -o-transition: 0.2s ease-in-out;
        transition-delay: 0s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -o-transition-delay: 0s; }
    .header-nav .menu-item > .menu-link,
    .header-nav .menu-item > a {
      color: #374151;
      display: block;
      font-size: 1.8rem; }
      .header-nav .menu-item > .menu-link > i,
      .header-nav .menu-item > a > i {
        display: none;
        font-weight: 600;
        margin-left: 0.4rem;
        font-size: 1.4rem;
        color: #ffffff; }
    .header-nav .menu-item.dropdown > a > i {
      display: inline-block; }
    .header-nav .menu-item > .menu-list {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 20rem;
      max-width: 28rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: white;
      box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      opacity: 0;
      visibility: hidden;
      transform: translate(0, -5px); }
      .header-nav .menu-item > .menu-list > .menu-item {
        margin-left: 0;
        width: 100%; }
        .header-nav .menu-item > .menu-list > .menu-item > .menu-link,
        .header-nav .menu-item > .menu-list > .menu-item > a {
          padding: 0.8rem 1.6rem;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative; }
          .header-nav .menu-item > .menu-list > .menu-item > .menu-link > i,
          .header-nav .menu-item > .menu-list > .menu-item > a > i {
            transform: rotate(-90deg) translate(5px, 0);
            margin-top: 0.8rem;
            font-size: 1rem; }
        .header-nav .menu-item > .menu-list > .menu-item > .menu-list {
          left: 100%; }

.mobile-nav .menu-nav > .menu-list > .menu-item.current_page_item > a, .mobile-nav .menu-nav > .menu-list > .menu-item.current-menu-item > a {
  color: #374151; }

.mobile-nav .menu-nav > .menu-list > .menu-item:has(.current-menu-item) > a {
  color: #374151; }

.mobile-nav .menu-nav > .menu-list > .menu-item > a {
  padding: 1.2rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #374151;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .mobile-nav .menu-nav > .menu-list > .menu-item > a::before {
    display: none; }

.mobile-nav .menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .mobile-nav .menu-item > .icon {
    padding: 1.5rem;
    height: 100%;
    cursor: pointer; }
    .mobile-nav .menu-item > .icon i {
      font-size: 1.6rem; }
  .mobile-nav .menu-item.dropdown > a > i {
    display: flex; }
  .mobile-nav .menu-item.active > a > i {
    transform: rotate(180deg); }
  .mobile-nav .menu-item a {
    position: relative; }
    .mobile-nav .menu-item a i {
      width: 2rem;
      height: 2rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      display: none;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }
  .mobile-nav .menu-item > a {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2.2rem;
    color: #374151;
    font-weight: 600;
    text-transform: uppercase;
    padding: 1rem 0;
    text-transform: capitalize; }
    @media only screen and (max-width: 1200px) {
      .mobile-nav .menu-item > a {
        font-size: 2rem; } }
    @media only screen and (max-width: 800px) {
      .mobile-nav .menu-item > a {
        font-size: 1.9rem; } }
  .mobile-nav .menu-item > .menu-list {
    display: none;
    margin-left: 1.6rem;
    position: relative; }
    .mobile-nav .menu-item > .menu-list::before {
      content: "";
      position: absolute;
      top: 2rem;
      bottom: 2rem;
      left: 0;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.1); }
    .mobile-nav .menu-item > .menu-list > .menu-item.current_page_item > a, .mobile-nav .menu-item > .menu-list > .menu-item.current-menu-item > a {
      color: #374151; }
    .mobile-nav .menu-item > .menu-list > .menu-item:has(.current-menu-item) > a {
      color: #374151; }

.mobile-nav-btn .link {
  color: #474140;
  font-weight: 600;
  text-decoration-line: underline;
  font-size: 1.6rem; }

.mega-menu {
  visibility: hidden; }
  .mega-menu-flex {
    display: flex;
    padding: 6rem;
    background: white;
    border-radius: 0.8rem;
    flex-wrap: wrap;
    row-gap: 2rem; }
  .mega-menu .mega-item {
    width: calc(100% / 3);
    display: flex;
    gap: 1.2rem;
    justify-content: center; }
    .mega-menu .mega-item-img {
      width: 11rem; }
      .mega-menu .mega-item-img .box {
        padding-top: calc((342/112) * 100%);
        position: relative;
        overflow: hidden; }
        .mega-menu .mega-item-img .box img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: contain; }
    .mega-menu .mega-item-name {
      font-size: 2.4rem;
      font-weight: 700;
      color: #F49712; }
      @media only screen and (max-width: 1200px) {
        .mega-menu .mega-item-name {
          font-size: 2rem; } }
      @media only screen and (max-width: 800px) {
        .mega-menu .mega-item-name {
          font-size: 1.7rem; } }
    .mega-menu .mega-item-cate {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 1.2rem; }
      .mega-menu .mega-item-cate .link {
        font-size: 2rem;
        color: #374151; }
        @media only screen and (max-width: 1200px) {
          .mega-menu .mega-item-cate .link {
            font-size: 1.8rem; } }
        @media only screen and (max-width: 800px) {
          .mega-menu .mega-item-cate .link {
            font-size: 1.6rem; } }

.header.sticky .header-nav .menu-nav > .menu-list > .menu-item.dropdown:hover > .mega-menu {
  top: var(--size-hd-sticky); }

@keyframes showmega {
  0% {
    transform: translateX(3rem);
    opacity: 0; }
  100% {
    transform: translateX(0%);
    opacity: 1; } }

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  visibility: hidden; }
  .popup.open {
    visibility: visible; }
    .popup.open .popup-overlay {
      opacity: 1;
      visibility: visible; }
    .popup.open .popup-main {
      opacity: 1;
      visibility: visible; }
  .popup-overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden; }
  .popup-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 90rem;
    max-height: 90vh;
    max-width: 95%;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    position: relative;
    border-radius: 0.4rem;
    background-color: #ffffff;
    padding: 2.4rem; }
    .popup-main.med {
      width: 90rem; }
    .popup-main.small {
      width: 80rem; }
  .popup-over {
    overflow-y: auto;
    min-height: 40vh;
    max-height: 80vh; }
  .popup-wrapper {
    width: 100%;
    min-height: 40vh;
    position: relative;
    border-radius: 6px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
  .popup-title {
    font-size: 2.4rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.6rem; }
    @media only screen and (max-width: 1200px) {
      .popup-title {
        font-size: 2rem; } }
    @media only screen and (max-width: 800px) {
      .popup-title {
        font-size: 1.7rem; } }
  .popup-close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    width: 3rem;
    height: 3rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    z-index: 100; }
    .popup-close:hover .icon {
      transform: rotate(180deg); }
    .popup-close .icon {
      color: #000000;
      font-weight: 300;
      font-size: 2.4rem;
      transition: 0.6s ease-in-out;
      -webkit-transition: 0.6s ease-in-out;
      -moz-transition: 0.6s ease-in-out;
      -ms-transition: 0.6s ease-in-out;
      -o-transition: 0.6s ease-in-out;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s; }
      @media screen and (max-width: 800px) {
        .popup-close .icon {
          font-size: 1.6rem; } }
  .popup-search {
    width: 100%;
    padding: 1rem;
    padding-top: 5rem; }
    .popup-search-box {
      width: 40rem; }
      .popup-search-box .popup-over {
        max-height: 90vh; }
    .popup-search-logo {
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: 50%; }
      .popup-search-logo img {
        width: 100%;
        object-fit: contain; }
    .popup-search-icon .icon {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem; }
    .popup-search-icon i {
      font-size: 4rem;
      font-weight: 700; }
    .popup-search-input.active::before {
      display: none; }
    .popup-search-input input {
      width: 100%;
      transition: 0.4s;
      padding: 1rem 1.6rem;
      border: 0.1rem solid #374151;
      height: 4.4rem; }
    .popup-search-input .btn, .popup-search-input .btn-pri, .popup-search-input .btn-bd, .popup-search-input .btn-second, .popup-search-input .btn-thir, .popup-search-input .btn-four {
      width: 100%;
      margin-top: 1rem;
      height: 4.4rem;
      justify-content: center; }
    .popup-search .search-rs {
      top: 27rem !important;
      z-index: 20 !important;
      position: unset !important;
      max-height: 42rem !important; }
      .popup-search .search-rs .submit-search {
        margin: auto;
        margin-top: 3rem;
        padding: 0.5rem 1rem;
        background-color: #374151;
        color: #ffffff;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center; }
  .popup-success .popup-main {
    min-height: 24rem;
    width: 50rem; }
  .popup-success .popup-over {
    min-height: 24rem; }
  .popup-success .popup-wrapper {
    min-height: 24rem; }
  .popup-success-inner {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .popup-success-inner .text {
      color: #0F172A; }

.cmini-ctn {
  margin-right: -0.5rem; }

.cmini-list {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  max-height: 20rem;
  overflow-y: auto;
  padding-right: 0.5rem; }

.cmini-item {
  display: flex;
  gap: 1.6rem; }

.cmini-img {
  width: 10rem;
  height: 10rem; }

.cmini-desc {
  flex: 1;
  display: flex;
  height: auto;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem; }
  .cmini-desc-bot {
    display: flex;
    justify-content: space-between; }

.cmini-quan {
  border: 0.1rem solid black;
  width: fit-content;
  height: fit-content;
  padding: 0 1rem; }

.btn, .btn-pri, .btn-bd, .btn-second, .btn-thir, .btn-four {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  height: 4.4rem;
  width: fit-content;
  overflow: hidden;
  min-width: 11rem;
  padding: 0 2rem; }
  .btn .inner, .btn-pri .inner, .btn-bd .inner, .btn-second .inner, .btn-thir .inner, .btn-four .inner {
    padding: 1rem 2rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
  .btn img, .btn-pri img, .btn-bd img, .btn-second img, .btn-thir img, .btn-four img {
    position: relative;
    z-index: 3; }
  .btn .icon, .btn-pri .icon, .btn-bd .icon, .btn-second .icon, .btn-thir .icon, .btn-four .icon {
    width: 2rem;
    height: 2rem;
    position: relative;
    z-index: 3;
    flex-shrink: 0; }
    .btn .icon:first-child, .btn-pri .icon:first-child, .btn-bd .icon:first-child, .btn-second .icon:first-child, .btn-thir .icon:first-child, .btn-four .icon:first-child {
      margin-right: 0.8rem; }
    .btn .icon:last-child, .btn-pri .icon:last-child, .btn-bd .icon:last-child, .btn-second .icon:last-child, .btn-thir .icon:last-child, .btn-four .icon:last-child {
      margin-left: 0.8rem; }
    .btn .icon img, .btn-pri .icon img, .btn-bd .icon img, .btn-second .icon img, .btn-thir .icon img, .btn-four .icon img {
      width: inherit;
      height: inherit; }
    .btn .icon i, .btn-pri .icon i, .btn-bd .icon i, .btn-second .icon i, .btn-thir .icon i, .btn-four .icon i {
      transition: 0.4s; }
  .btn.center, .center.btn-pri, .center.btn-bd, .center.btn-second, .center.btn-thir, .center.btn-four {
    margin: auto;
    margin-top: 2rem; }
  .btn .text, .btn-pri .text, .btn-bd .text, .btn-second .text, .btn-thir .text, .btn-four .text {
    color: #ffffff;
    z-index: 3;
    font-size: 1.6rem;
    position: relative;
    display: block !important;
    white-space: nowrap;
    transition: 0.4s;
    font-weight: 700; }
  .btn::after, .btn-pri::after, .btn-bd::after, .btn-second::after, .btn-thir::after, .btn-four::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    transform: scaleY(0);
    transform-origin: bottom right;
    transition: transform 0.5s ease; }
  .btn::before, .btn-pri::before, .btn-bd::before, .btn-second::before, .btn-thir::before, .btn-four::before {
    display: block;
    position: absolute;
    inset: 0.1rem;
    transform: translateY(-150%);
    width: 100%;
    z-index: 0;
    content: '';
    background: #ffffff;
    transition: 0.4s; }

@media screen and (min-width: 1200px) and (hover: hover) {
  .btn:hover::after, .btn-pri:hover::after, .btn-bd:hover::after, .btn-second:hover::after, .btn-thir:hover::after, .btn-four:hover::after {
    transform: scale(1);
    transform-origin: top right; } }
  .btn-pri {
    border-radius: 0.4rem;
    border: 0.1rem solid #F49712;
    background-color: #F49712;
    transition: 0.3s ease-in-out; }
    .btn-pri::after {
      display: none; }
    .btn-pri::before {
      border-radius: 0.4rem; }
    .btn-pri .text {
      color: #ffffff; }
    .btn-pri .icon i {
      color: #ffffff; }
    @media (hover: hover) {
      .btn-pri:hover {
        background-color: #ffffff; }
        .btn-pri:hover .text {
          color: #F49712; }
        .btn-pri:hover .icon i {
          color: #F49712; } }
  .btn-bd {
    background: transparent; }
    .btn-bd::after {
      background: linear-gradient(90deg, rgba(182, 111, 41, 0.1) 0.37%, rgba(220, 154, 39, 0.1) 27.52%, rgba(237, 179, 31, 0.1) 52.76%, rgba(215, 148, 42, 0.1) 80.98%, rgba(176, 104, 40, 0.1) 100%); }
    .btn-bd .text {
      background: linear-gradient(90deg, #FFAF61 0.37%, #DC9A27 27.52%, #EDB31F 52.76%, #D7942A 80.98%, #B06828 100%); }
    .btn-bd .icon i {
      color: #ffffff; }
    @media (hover: hover) {
      .btn-bd:hover .text {
        color: #374151; }
      .btn-bd:hover .icon i {
        color: #374151; } }
  .btn-second {
    background: transparent;
    border-radius: 0.4rem;
    border: 1px solid #F49712; }
    .btn-second::after {
      background: #F49712; }
    .btn-second .text {
      color: #F49712; }
    .btn-second .icon i {
      color: #F49712; }
    @media (hover: hover) {
      .btn-second:hover .text {
        color: #ffffff; }
      .btn-second:hover .icon i {
        color: #ffffff; } }
  .btn-thir {
    background: #3A6BD0;
    border-radius: 3rem;
    border: 1px solid #3A6BD0; }
    .btn-thir::after {
      background: #ffffff; }
    .btn-thir .text {
      color: #ffffff; }
    .btn-thir .icon i {
      color: #ffffff; }
    @media (hover: hover) {
      .btn-thir:hover .text {
        color: #3A6BD0; }
      .btn-thir:hover .icon i {
        color: #3A6BD0; } }
  .btn-four {
    background: transparent;
    border-radius: 3rem;
    border: 1px solid #F49712; }
    .btn-four::after {
      background: #F49712; }
    .btn-four .text {
      color: #F49712; }
    .btn-four .icon i {
      color: #F49712; }
    @media (hover: hover) {
      .btn-four:hover .text {
        color: #ffffff; }
      .btn-four:hover .icon i {
        color: #ffffff; } }
  .btn.full, .full.btn-pri, .full.btn-bd, .full.btn-second, .full.btn-thir, .full.btn-four {
    width: 100%; }

.info-see {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  color: #374151;
  width: fit-content; }
  .info-see i {
    transition: 0.4s;
    margin-top: -0.1rem; }
  .info-see .text {
    font-weight: 700;
    margin-top: 0rem;
    transition: 0.4s; }
    .info-see .text:last-child {
      margin-left: 0.8rem; }
    .info-see .text:first-child {
      margin-right: 0.8rem; }
  @media (hover: hover) {
    .info-see:hover i {
      transform: rotate(360deg);
      color: #DCA200; }
    .info-see:hover .text {
      color: #DCA200; } }

.bd-ln {
  position: relative;
  width: fit-content;
  display: grid;
  place-items: center;
  padding: 0.2rem; }
  .bd-ln::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0.2rem;
    background: linear-gradient(90deg, #FFAF61 0.37%, #DC9A27 27.52%, #EDB31F 52.76%, #D7942A 80.98%, #B06828 100%);
    border-radius: 100rem;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none; }

.back-to-top {
  width: 4rem;
  height: 4rem;
  background: white;
  border-radius: 0.6rem;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 7;
  transform: translateX(3rem);
  opacity: 0;
  transition: 0.5s linear;
  cursor: pointer;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 100rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; }
  .back-to-top img {
    width: 100%;
    height: 100%;
    object-fit: contain; }
  @media screen and (min-width: 1200px) {
    .back-to-top img {
      -webkit-animation: shake-vertical 15s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
      animation: shake-vertical 15s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both; } }
  @media screen and (max-width: 1200px) {
    .back-to-top {
      width: 4rem;
      height: 4rem; } }
  @media screen and (max-width: 768px) {
    .back-to-top {
      right: 1.5rem; } }
  .back-to-top.active {
    visibility: visible;
    transform: translateX(0);
    opacity: 1; }
  .back-to-top .triangle {
    display: block;
    width: 100%;
    height: 0.5rem;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center; }
    .back-to-top .triangle i {
      color: #101828; }
    .back-to-top .triangle:nth-child(1) {
      animation: BTT 1.5s linear 0.6s infinite; }
    .back-to-top .triangle:nth-child(2) {
      animation: BTT 1.5s linear 0.3s infinite; }
    .back-to-top .triangle:nth-child(3) {
      animation: BTT 1.5s linear 0s infinite; }
  @media (hover: hover) {
    .back-to-top:hover .icon {
      -webkit-animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
      animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both; } }
  .back-to-top .icon {
    display: flex; }
  .back-to-top .btn-rotate {
    width: 100%;
    height: 100%; }
    .back-to-top .btn-rotate .rounded-text {
      fill: white; }

@-webkit-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-moz-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-ms-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-o-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

::-webkit-scrollbar {
  width: 0.5rem; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #374151; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #374151; }

.dateTime {
  position: relative;
  height: 4.8rem;
  background: #F9F7F5;
  z-index: 2;
  border: 1px solid  #F3EDEA;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem; }
  .dateTime input {
    border-radius: unset !important;
    border: unset !important;
    background: none !important; }
  .dateTime .icon {
    width: 1.6rem;
    height: 1.6rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }

.dateTimeItem {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  height: 100%; }

.dateTimeText {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
  z-index: 2; }
  .dateTimeText.disable {
    opacity: 0;
    visibility: hidden; }

.dateTimeInput {
  padding: 0.8rem 1.6rem;
  background-color: transparent;
  width: 100%;
  font-family: "SVN-Segoe UI", sans-serif;
  border: none;
  outline: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1; }

.quantity {
  display: flex;
  gap: 2.4rem;
  flex-direction: column; }
  .quantity .count {
    display: flex;
    width: max-content;
    gap: 1rem;
    border: none;
    align-items: center;
    border: 0.1rem solid #F3F3F3; }
    @media screen and (max-width: 1024px) {
      .quantity .count {
        gap: 0.5rem; } }
    @media screen and (max-width: 550px) {
      .quantity .count {
        gap: 0.5rem; }
        .quantity .count-number {
          font-size: 1.4rem; } }
    .quantity .count-number {
      color: #1A1A1A;
      line-height: 1;
      font-weight: 600; }
    .quantity .count-btn {
      width: 3.2rem;
      height: 3.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #F3F3F3; }
      .quantity .count-btn .icon {
        transition: 0.3s ease-in-out;
        color: #000; }
        .quantity .count-btn .icon:hover {
          color: #374151; }

.ip-control {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column; }
  .ip-control.x2 {
    width: 50%; }
  .ip-control.x3 {
    width: calc(100% / 3); }
  .ip-control label {
    font-size: 1.4rem;
    font-weight: 700;
    color: #374151;
    display: block;
    margin-bottom: 0.5rem; }
  .ip-control input {
    width: 100%;
    height: 4.8rem;
    border: 1px solid #d0d5dd;
    background: #fff;
    padding: 0 1.2rem;
    border-radius: 0.8rem; }
    .ip-control input:focus {
      outline: none;
      border-color: black; }
  .ip-control textarea {
    border: 1px solid #d0d5dd;
    background: #fff;
    padding: 1.6rem;
    min-height: 10rem;
    border-radius: 0.8rem;
    width: 100% !important; }
  .ip-control-input {
    position: relative;
    display: flex; }
    .ip-control-input input {
      flex: 1;
      border-radius: 0 0.8rem 0.8rem 0 !important; }
  .ip-control-select {
    width: 7rem; }
    .ip-control-select .select2-selection__rendered,
    .ip-control-select .select2-container .select2-selection--single,
    .ip-control-select .select2-container {
      border-radius: 0.8rem 0 0 0.8rem  !important;
      border-right: none !important; }
  .ip-control .select2-container {
    width: 100% !important;
    height: 4.8rem;
    border-radius: 0.8rem;
    border: 1px solid #d0d5dd; }
  .ip-control .select2-container .select2-selection--single,
  .ip-control .select2-selection__rendered {
    height: 100%;
    display: flex !important;
    align-items: center;
    background: #fff;
    width: 100%;
    border-color: transparent;
    border-radius: 0.8rem; }
  .ip-control .select2-selection__rendered {
    border-radius: 0.8rem; }
  .ip-control .select2-selection__arrow {
    height: 100% !important; }

.hello {
  color: red; }

.t-title {
  font-size: 5.6rem;
  font-family: "SVN-Segoe UI", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -0.2rem; }
  @media only screen and (max-width: 1200px) {
    .t-title {
      font-size: 5.1rem; } }
  @media only screen and (max-width: 800px) {
    .t-title {
      font-size: 4rem; } }
  @media screen and (max-width: 768px) {
    .t-title {
      letter-spacing: 0; } }

.t-title-second {
  font-size: 2.4rem; }
  @media only screen and (max-width: 1200px) {
    .t-title-second {
      font-size: 2rem; } }
  @media only screen and (max-width: 800px) {
    .t-title-second {
      font-size: 1.7rem; } }

.t-center {
  text-align: center; }

.fw-1 {
  font-weight: 100; }

.fw-2 {
  font-weight: 200; }

.fw-3 {
  font-weight: 300; }

.fw-5 {
  font-weight: 500; }

.fw-6 {
  font-weight: 600; }

.fw-7 {
  font-weight: 700; }

.fw-8 {
  font-weight: 800; }

.fw-b {
  font-weight: bold; }

.fsi {
  font-style: italic; }

.t-up {
  text-transform: uppercase; }

.t9 {
  font-size: 0.9rem; }

.t10 {
  font-size: 1rem; }

.t11 {
  font-size: 1.1rem; }

.t12 {
  font-size: 1.2rem; }

.t14 {
  font-size: 1.4rem; }

.t16 {
  font-size: 1.6rem; }

.t18 {
  font-size: 1.8rem; }
  @media only screen and (max-width: 1200px) {
    .t18 {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 800px) {
    .t18 {
      font-size: 1.5rem; } }

.t20 {
  font-size: 2rem; }
  @media only screen and (max-width: 1200px) {
    .t20 {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 800px) {
    .t20 {
      font-size: 1.6rem; } }

.t22 {
  font-size: 2.2rem; }
  @media only screen and (max-width: 1200px) {
    .t22 {
      font-size: 2rem; } }
  @media only screen and (max-width: 800px) {
    .t22 {
      font-size: 1.9rem; } }

.t24 {
  font-size: 2.4rem; }
  @media only screen and (max-width: 1200px) {
    .t24 {
      font-size: 2rem; } }
  @media only screen and (max-width: 800px) {
    .t24 {
      font-size: 1.7rem; } }

.t32 {
  font-size: 3.2rem; }
  @media only screen and (max-width: 1200px) {
    .t32 {
      font-size: 2.7rem; } }
  @media only screen and (max-width: 800px) {
    .t32 {
      font-size: 2.2rem; } }

.t40 {
  font-size: 4rem; }
  @media only screen and (max-width: 1200px) {
    .t40 {
      font-size: 3.5rem; } }
  @media only screen and (max-width: 800px) {
    .t40 {
      font-size: 2.7rem; } }
  @media only screen and (max-width: 500px) {
    .t40 {
      font-size: 2.5rem; } }

.t48 {
  font-size: 4.8rem; }
  @media only screen and (max-width: 1200px) {
    .t48 {
      font-size: 4.3rem; } }
  @media only screen and (max-width: 800px) {
    .t48 {
      font-size: 3.3rem; } }

.t56 {
  font-size: 5.6rem; }
  @media only screen and (max-width: 1200px) {
    .t56 {
      font-size: 5.1rem; } }
  @media only screen and (max-width: 800px) {
    .t56 {
      font-size: 4rem; } }

.t64 {
  font-size: 6.4rem; }
  @media only screen and (max-width: 1200px) {
    .t64 {
      font-size: 3.9rem; } }
  @media only screen and (max-width: 800px) {
    .t64 {
      font-size: 2.9rem; } }

.t72 {
  font-size: 7.2rem; }
  @media only screen and (max-width: 1200px) {
    .t72 {
      font-size: 5rem; } }
  @media only screen and (max-width: 800px) {
    .t72 {
      font-size: 4rem; } }

.page-numbers {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  overflow: auto; }
  .page-numbers::-webkit-scrollbar {
    display: none; }
  .page-numbers > li:not(:first-child) {
    margin-left: 1rem; }
  .page-numbers > li > .page-numbers {
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 0.5rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    color: #858c82;
    font-size: 1.4rem;
    font-weight: 400;
    transition: 0.4s ease;
    line-height: 1.5; }
    .page-numbers > li > .page-numbers::after {
      position: absolute;
      content: "";
      width: 0;
      height: 100%;
      top: 0;
      right: 0;
      background: #F49712;
      transition: all 0.4s ease;
      z-index: 1;
      pointer-events: none; }
    .page-numbers > li > .page-numbers.next, .page-numbers > li > .page-numbers.prev {
      width: max-content;
      padding: 0.7rem 1.4rem;
      background-color: white;
      gap: 0.5rem;
      border: 1px solid  #D0D5DD; }
      .page-numbers > li > .page-numbers.next i, .page-numbers > li > .page-numbers.prev i {
        color: #000000;
        font-weight: 700;
        transition: 0.3s ease-in-out; }
    .page-numbers > li > .page-numbers:after {
      z-index: -1;
      background: #374151; }
    @media (hover: hover) {
      .page-numbers > li > .page-numbers:hover:not(.dot) {
        color: white; }
        .page-numbers > li > .page-numbers:hover:not(.dot) i {
          color: white; }
        .page-numbers > li > .page-numbers:hover:not(.dot)::after {
          left: 0;
          width: 100%; } }
    .page-numbers > li > .page-numbers.current {
      color: #F49712;
      font-weight: 700;
      background-color: rgba(244, 151, 18, 0.1); }
    .page-numbers > li > .page-numbers.dot {
      background-color: transparent; }
    .page-numbers > li > .page-numbers i {
      font-weight: 400; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-2 {
  margin-bottom: 0.2rem; }

.mb-4 {
  margin-bottom: 0.4rem; }

.mb-6 {
  margin-bottom: 0.6rem; }

.mb-8 {
  margin-bottom: 0.8rem; }

.mb-8s:not(:last-child) {
  margin-bottom: 0.8rem; }

.mb-10 {
  margin-bottom: 1rem; }

.mb-10s:not(:last-child) {
  margin-bottom: 1rem; }

.mb-12 {
  margin-bottom: 1.2rem; }

.mb-12s:not(:last-child) {
  margin-bottom: 1.2rem; }

.mb-14 {
  margin-bottom: 1.4rem; }

.mb-14s:not(:last-child) {
  margin-bottom: 1.4rem; }

.mb-15 {
  margin-bottom: 1.5rem; }

.mb-15s:not(:last-child) {
  margin-bottom: 1.5rem; }

.mb-16 {
  margin-bottom: 1.6rem; }

.mb-16s:not(:last-child) {
  margin-bottom: 1.6rem; }

.mb-18 {
  margin-bottom: 1.8rem; }

.mb-18s:not(:last-child) {
  margin-bottom: 1.8rem; }

.mb-20 {
  margin-bottom: 2rem; }

.mb-20s:not(:last-child) {
  margin-bottom: 2rem; }

.mb-24 {
  margin-bottom: 2.4rem; }

.mb-24s:not(:last-child) {
  margin-bottom: 2.4rem; }

.mb-25 {
  margin-bottom: 2.5rem; }

.mb-25s:not(:last-child) {
  margin-bottom: 2.5rem; }

.mb-30 {
  margin-bottom: 3rem; }

.mb-30s:not(:last-child) {
  margin-bottom: 3rem; }

.mb-32 {
  margin-bottom: 3.2rem; }

.mb-32s:not(:last-child) {
  margin-bottom: 3.2rem; }

.mb-40 {
  margin-bottom: 4rem; }

.mb-40s:not(:last-child) {
  margin-bottom: 4rem; }

.mb-48 {
  margin-bottom: 4.8rem; }

.mb-48s:not(:last-child) {
  margin-bottom: 4.8rem; }

.mb-56 {
  margin-bottom: 5.6rem; }

.mb-56s:not(:last-child) {
  margin-bottom: 5.6rem; }

.mb-64 {
  margin-bottom: 6.4rem; }

.mb-64s:not(:last-child) {
  margin-bottom: 6.4rem; }

.mb-72 {
  margin-bottom: 7.2rem; }

.mb-72s:not(:last-child) {
  margin-bottom: 7.2rem; }

.mb-80 {
  margin-bottom: 8rem; }

.mb-80s:not(:last-child) {
  margin-bottom: 8rem; }

.mt-8 {
  margin-top: 0.8rem; }

.ss-mg {
  margin: var(--pd-sc) 0; }

.ss-mg-t {
  margin-top: var(--pd-sc); }

.ss-mg-b {
  margin-bottom: var(--pd-sc); }

.ss-pd {
  padding: var(--pd-sc) 0; }

.ss-pd-t {
  padding-top: var(--pd-sc); }

.ss-pd-b {
  padding-bottom: var(--pd-sc); }

.breadcrumb {
  max-width: 100%;
  white-space: nowrap; }
  .breadcrumb::-webkit-scrollbar {
    height: 0.2rem; }
  .breadcrumb-inner {
    width: fit-content; }
    @media screen and (max-width: 600px) {
      .breadcrumb-inner {
        border-radius: 0;
        border: none;
        padding: 0; } }
  .breadcrumb-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5rem; }
  .breadcrumb-item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    white-space: wrap;
    position: relative; }
    .breadcrumb-item:before {
      position: absolute;
      content: "/";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.2rem;
      display: none; }
    .breadcrumb-item:not(:first-child) {
      column-gap: 0.5rem;
      padding-left: 1rem; }
      .breadcrumb-item:not(:first-child):before {
        display: block; }
    .breadcrumb-item:last-child i,
    .breadcrumb-item:last-child .breadcrumb-link {
      font-weight: 700; }
    .breadcrumb-item:first-child i {
      display: none; }
    .breadcrumb-item:nth-child(2) {
      transition-delay: 0.4s !important; }
    .breadcrumb-item:nth-child(3) {
      transition-delay: 0.6s !important; }
    .breadcrumb-item:nth-child(4) {
      transition-delay: 0.8s !important; }
    .breadcrumb-item:nth-child(5) {
      transition-delay: 1s !important; }
  .breadcrumb-link {
    font-size: 1.4rem;
    line-height: 1;
    color: #374151;
    font-weight: 500; }

.breadcrumb-page {
  margin-top: 1rem; }
  @media screen and (max-width: 1200px) {
    .breadcrumb-page {
      padding-top: 0;
      margin-top: 2.4rem; } }
  .breadcrumb-page .breadcrumb-list {
    justify-content: unset; }
  .breadcrumb-page .breadcrumb-item::after {
    color: #121212; }
  .breadcrumb-page .breadcrumb-link {
    color: rgba(30, 30, 30, 0.5); }

.recheck-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  cursor: pointer; }
  .recheck-item.active .recheck-checkbox {
    background: #374151; }
    .recheck-item.active .recheck-checkbox::before {
      opacity: 1;
      content: "\f00c";
      color: white; }
  .recheck-item.active .recheck-radio::before {
    opacity: 1; }
  .recheck-item.active .recheck-dot::before {
    opacity: 1; }

.recheck-checkbox {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.3rem;
  background-color: transparent;
  border: 0.1rem solid #374151;
  transition: 0.3s ease-in-out;
  position: relative;
  flex-shrink: 0; }
  .recheck-checkbox::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: calc(50%);
    position: absolute;
    color: #374151;
    font-size: 1.4rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    font-family: "Font Awesome 5 Pro";
    transition: 0.3s ease-in-out;
    font-weight: 300;
    line-height: 1; }

.recheck-radio {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100rem;
  border: 0.1rem solid #d9d9d9;
  flex-shrink: 0;
  position: relative; }
  .recheck-radio::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 100rem;
    background-color: #374151;
    opacity: 0; }

.recheck-method {
  width: 4.8rem;
  height: 4.8rem;
  border: 0.1rem solid #ebebeb;
  border-radius: 0.8rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin-left: 0.8rem; }
  .recheck-method img {
    width: 2.4rem;
    height: 2.4rem; }

.recheck-text {
  margin-left: 0.8rem;
  color: #121212;
  line-height: 1; }
  .recheck-text a {
    transition: 0.3s ease-in-out; }
    @media (hover: hover) {
      .recheck-text a:hover {
        color: #374151; } }

.recheck-dot {
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  border: 0.1rem solid #000000;
  position: relative;
  flex-shrink: 0; }
  .recheck-dot::before {
    content: "";
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: inherit;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #374151;
    opacity: 0;
    transition: 0.3s ease-in-out; }

.banner-wrap {
  position: relative; }

.banner-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: -1; }
  .banner-bg img {
    width: 100%;
    height: 100% !important;
    object-fit: cover; }

.banner-inner {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%); }

.banner-ctn {
  padding-top: var(--size-hd);
  min-height: calc((893/1728) * 100vw);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  position: relative;
  flex-direction: column; }

.banner-desc {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  padding: 4rem 0; }
  .banner-desc .title {
    line-height: 1.2; }
    @media screen and (max-width: 768px) {
      .banner-desc .title br {
        display: none; } }
  @media screen and (max-width: 768px) {
    .banner-desc .desc br {
      display: none; } }
  .banner-desc .btn, .banner-desc .btn-pri, .banner-desc .btn-bd, .banner-desc .btn-second, .banner-desc .btn-thir, .banner-desc .btn-four {
    margin-top: 2.4rem; }

.banner-bot {
  position: relative;
  margin-top: auto;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding: 3rem 0; }
  .banner-bot::before {
    position: absolute;
    content: "";
    height: 0.1rem;
    width: 100vw;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: #D1D5DB; }
  .banner-bot-text {
    color: #fff;
    font-size: 2.2rem; }
    @media only screen and (max-width: 1200px) {
      .banner-bot-text {
        font-size: 2rem; } }
    @media only screen and (max-width: 800px) {
      .banner-bot-text {
        font-size: 1.9rem; } }
  .banner-bot-link-link {
    display: flex; }
  .banner-bot-link i {
    color: #fff;
    font-size: 2.2rem;
    -webkit-animation: shake-vertical 15s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
    animation: shake-vertical 15s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both; }
    @media only screen and (max-width: 1200px) {
      .banner-bot-link i {
        font-size: 2rem; } }
    @media only screen and (max-width: 800px) {
      .banner-bot-link i {
        font-size: 1.9rem; } }

.banner.option-2 .banner-desc {
  margin-top: auto; }

.banner.option-2 .banner-bot {
  margin-top: auto; }

.banner:not(.option-2) .banner-bread {
  margin-bottom: auto; }

.banner:not(.option-2) .banner-desc {
  margin-bottom: auto; }

.banner-bread {
  padding-top: 2rem;
  margin-right: auto; }
  .banner-bread .breadcrumb-item:before {
    color: white; }
  .banner-bread .breadcrumb-link {
    color: white; }

.side-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 50;
  transition: 0.5s ease-in-out;
  opacity: 0;
  transform: translateX(110%);
  visibility: hidden; }
  .side-overlay.open {
    opacity: 1;
    transform: translate(0, 0);
    visibility: visible; }

.side-open {
  z-index: 40;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  border-radius: 100rem 0 0 100rem;
  cursor: pointer;
  transition: 0.3s ease-in-out; }
  .side-open .icon {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
  .side-open.close {
    opacity: 0;
    visibility: visible; }
  .side-open-wrap {
    width: 30px;
    height: 30px;
    border-radius: 100rem;
    background-color: white;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    .side-open-wrap .icon {
      color: #F49712;
      font-weight: 600;
      font-size: 1.6rem; }

.side-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  background-color: white;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  cursor: pointer; }
  .side-close:hover .icon {
    transform: rotate(360deg); }
  .side-close .icon {
    font-size: 2.8rem;
    font-weight: 400;
    transition: 0.6s ease-in-out; }

.star {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-content: start; }
  .star-list {
    position: relative;
    height: 2rem; }
  .star-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex; }
    .star-flex .icon {
      width: 1.8rem;
      height: 1.8rem; }
      .star-flex .icon:not(:first-child) {
        margin-left: 0.4rem; }
  .star-filter {
    overflow: hidden;
    position: absolute;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    left: 0;
    height: 100%;
    width: 0; }

.stars input {
  position: absolute;
  clip: rect(0, 0, 0, 0); }

.stars input:nth-child(1):checked ~ label:nth-of-type(-n + 1),
.stars input:nth-child(2):checked ~ label:nth-of-type(-n + 2),
.stars input:nth-child(3):checked ~ label:nth-of-type(-n + 3),
.stars input:nth-child(4):checked ~ label:nth-of-type(-n + 4),
.stars input:nth-child(5):checked ~ label:nth-of-type(-n + 5) {
  background: url(../assets/images/icon-star.svg);
  background-repeat: no-repeat;
  background-size: contain; }

.stars label {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  margin-right: 6px;
  height: 2rem;
  width: 2rem;
  background: url(../assets/images/star-opa.svg);
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: contain;
  margin: 0; }
  .stars label.active {
    background: url(../assets/images/icon-star.svg); }

.stars .wpcf7-list-item {
  margin-left: 0; }

.stars label .wpcf7-list-item-label {
  display: none; }

.srate {
  padding: 6rem 0;
  padding-top: 2rem; }
  @media screen and (max-width: 992px) {
    .srate {
      padding: 5rem 0; } }
  .srate-flex {
    display: flex;
    margin: 0 -1.2rem;
    margin-top: 4rem; }
    @media screen and (max-width: 920px) {
      .srate-flex {
        flex-direction: column;
        row-gap: 2rem; } }
  .srate-left {
    padding: 0 1.2rem;
    width: 50%; }
    @media screen and (max-width: 992px) {
      .srate-left {
        width: 100%; } }
  .srate-right {
    padding: 0 1.2rem;
    width: 50%; }
    @media screen and (max-width: 992px) {
      .srate-right {
        width: 100%; } }
    .srate-right input:checked ~ .dblog-re-list {
      max-height: unset; }
      .srate-right input:checked ~ .dblog-re-list::after {
        opacity: 0;
        visibility: hidden; }
    .srate-right input:checked ~ .btn-drop-text .text:nth-child(1) {
      display: none !important; }
    .srate-right input:checked ~ .btn-drop-text .text:nth-child(2) {
      display: block !important; }
    .srate-right input:checked ~ .btn-drop-text .icon {
      transform: rotate(0deg); }
    .srate-right .btn-drop-text {
      width: max-content;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      margin-top: 2rem; }
      .srate-right .btn-drop-text .btn, .srate-right .btn-drop-text .btn-pri, .srate-right .btn-drop-text .btn-bd, .srate-right .btn-drop-text .btn-second, .srate-right .btn-drop-text .btn-thir, .srate-right .btn-drop-text .btn-four {
        display: flex;
        flex-direction: column; }
        @media (hover: hover) {
          .srate-right .btn-drop-text .btn:hover .icon, .srate-right .btn-drop-text .btn-pri:hover .icon, .srate-right .btn-drop-text .btn-bd:hover .icon, .srate-right .btn-drop-text .btn-second:hover .icon, .srate-right .btn-drop-text .btn-thir:hover .icon, .srate-right .btn-drop-text .btn-four:hover .icon {
            color: #374151; } }
      .srate-right .btn-drop-text .icon {
        color: #374151;
        margin: 0 !important;
        width: 1.5rem;
        height: auto;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        transform: rotate(180deg); }
  .srate-rate-top {
    display: flex;
    align-items: center;
    gap: 1rem; }
    .srate-rate-top .text {
      line-height: 1; }
  .srate-rate-star {
    display: flex;
    flex-direction: column; }
    .srate-rate-star .text {
      color: #344054; }
  .srate-rate-ctn {
    margin-top: 1.2rem; }
  .srate-rate-item {
    display: flex;
    align-items: center;
    gap: 1rem; }
    .srate-rate-item:not(:last-child) {
      margin-bottom: 1rem; }
  .srate-line {
    flex: 1;
    width: 50%;
    position: relative;
    height: 0.6rem;
    border-radius: 0.4rem;
    overflow: hidden; }
    .srate-line::before {
      background: #f6f7f5;
      content: "";
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      inset: 0; }
    .srate-line-inner {
      position: absolute;
      left: 0;
      top: 0;
      background: #F49712;
      border-radius: 0.4rem;
      z-index: 5;
      height: 100%; }

.p-home.ourl .ourl-top-list-box {
  margin-top: 6rem; }
  @media screen and (max-width: 1100px) {
    .p-home.ourl .ourl-top-list-box {
      margin-top: 3rem; } }

.p-home.ourl .ourl-slide-box:not(:first-child) {
  display: none; }

.p-home.ourl .ourl-slide .btn, .p-home.ourl .ourl-slide .btn-pri, .p-home.ourl .ourl-slide .btn-bd, .p-home.ourl .ourl-slide .btn-second, .p-home.ourl .ourl-slide .btn-thir, .p-home.ourl .ourl-slide .btn-four {
  margin: 0 auto;
  margin-top: 6rem; }
  @media screen and (max-width: 1350px) {
    .p-home.ourl .ourl-slide .btn, .p-home.ourl .ourl-slide .btn-pri, .p-home.ourl .ourl-slide .btn-bd, .p-home.ourl .ourl-slide .btn-second, .p-home.ourl .ourl-slide .btn-thir, .p-home.ourl .ourl-slide .btn-four {
      margin-top: 2rem; } }

.hban {
  overflow: hidden; }
  .hban-wrap {
    height: calc((893 / 1728) * 100vw); }
    @media screen and (max-width: 1024px) {
      .hban-wrap {
        height: unset;
        min-height: calc((893 / 1728) * 100vw); } }
  .hban-inner {
    height: 100%; }
  .hban-ctn {
    height: 100%;
    position: relative; }
  .hban-slide {
    height: 100%; }
  .hban-item {
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
    padding-top: var(--size-hd); }
    .hban-item-wrap {
      height: 100%; }
    .hban-item-ctn {
      height: 100%; }
  .hban-bg {
    height: 100%;
    position: absolute;
    width: 100%;
    inset: 0;
    z-index: -1; }
    .hban-bg img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .hban-desc {
    height: 100%;
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 12.5rem;
    transition: 0.3s ease-in-out;
    opacity: 0;
    transform: scale(0.9);
    width: 50%; }
    @media screen and (max-width: 1024px) {
      .hban-desc {
        padding-bottom: 15.5rem; } }
    @media screen and (max-width: 768px) {
      .hban-desc {
        width: 100%; } }
    .hban-desc .title {
      color: white;
      font-size: 6.4rem;
      font-weight: 700;
      line-height: 1.2; }
      @media only screen and (max-width: 1200px) {
        .hban-desc .title {
          font-size: 3.9rem; } }
      @media only screen and (max-width: 800px) {
        .hban-desc .title {
          font-size: 2.9rem; } }
    .hban-desc .txt {
      color: white;
      font-size: 2.2rem;
      margin-top: 1rem; }
      @media only screen and (max-width: 1200px) {
        .hban-desc .txt {
          font-size: 2rem; } }
      @media only screen and (max-width: 800px) {
        .hban-desc .txt {
          font-size: 1.9rem; } }
    .hban-desc .btn, .hban-desc .btn-pri, .hban-desc .btn-bd, .hban-desc .btn-second, .hban-desc .btn-thir, .hban-desc .btn-four {
      margin-top: 2rem; }
  .hban-control {
    position: absolute;
    right: 5rem;
    top: 50%;
    transform: translateY(-50%);
    padding-top: var(--size-hd);
    padding-bottom: 8.5rem;
    width: 5.6rem;
    z-index: 2; }
    @media screen and (max-width: 1024px) {
      .hban-control {
        left: 1.5rem;
        width: calc(100% - 3rem);
        top: unset;
        transform: unset;
        bottom: 0;
        padding-bottom: 8.5rem; }
        .hban-control .hban-control-inner {
          flex-direction: row;
          width: max-content;
          margin: 0 auto; } }
    .hban-control-inner {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      backdrop-filter: blur(1rem);
      padding: 1rem;
      border-radius: 0.8rem; }
  .hban-pagi {
    position: relative;
    inset: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    transform: unset !important; }
    @media screen and (max-width: 1024px) {
      .hban-pagi {
        flex-direction: row;
        gap: 0; } }
    .hban-pagi .swiper-pagination-bullet {
      margin: 0 !important;
      width: max-content;
      aspect-ratio: 1;
      height: unset;
      transition: 0.3s ease-in-out;
      font-weight: 700;
      color: white;
      font-size: 1rem;
      border: none;
      display: flex !important;
      justify-content: center;
      align-items: center;
      line-height: 1;
      padding: 0.3rem;
      position: relative; }
      .hban-pagi .swiper-pagination-bullet:before {
        position: absolute;
        border-radius: 100rem;
        right: -0.5rem;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        border: 0.1rem solid white;
        width: 0.5rem;
        height: 0.5rem;
        opacity: 0;
        transition: 0.3s ease-in-out; }
      .hban-pagi .swiper-pagination-bullet::after {
        position: absolute;
        border-radius: 100rem;
        right: -0.3rem;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        background-color: white;
        width: 0.3rem;
        height: 0.3rem;
        opacity: 0;
        transition: 0.3s ease-in-out; }
      .hban-pagi .swiper-pagination-bullet-active {
        color: white;
        font-size: 1.8rem;
        background-color: transparent; }
        @media only screen and (max-width: 1200px) {
          .hban-pagi .swiper-pagination-bullet-active {
            font-size: 1.6rem; } }
        @media only screen and (max-width: 800px) {
          .hban-pagi .swiper-pagination-bullet-active {
            font-size: 1.5rem; } }
        .hban-pagi .swiper-pagination-bullet-active::after, .hban-pagi .swiper-pagination-bullet-active::before {
          opacity: 1; }
        @media screen and (max-width: 1024px) {
          .hban-pagi .swiper-pagination-bullet-active {
            font-size: 1.4rem; } }
      @media screen and (max-width: 1024px) {
        .hban-pagi .swiper-pagination-bullet {
          min-width: 3rem; }
          .hban-pagi .swiper-pagination-bullet::after {
            left: 50%;
            transform: translateX(-50%);
            right: unset;
            bottom: -0.2rem;
            top: unset; }
          .hban-pagi .swiper-pagination-bullet::before {
            left: 50%;
            transform: translateX(-50%);
            right: unset;
            bottom: -0.4rem;
            top: unset; } }
  .hban-btn-next, .hban-btn-prev {
    height: 3.6rem;
    width: 3.6rem;
    border: 0.1rem solid #F49712;
    border-radius: 0.4rem;
    transition: 0.3s ease-in-out;
    position: relative;
    margin: 0 !important;
    inset: 0 !important;
    transform: rotate(-90deg);
    background: #F49712;
    flex-shrink: 0; }
    @media screen and (max-width: 1024px) {
      .hban-btn-next, .hban-btn-prev {
        transform: unset;
        height: 2.8rem;
        width: 2.8rem; }
        .hban-btn-next::after, .hban-btn-prev::after {
          transform: rotate(180deg); } }
    .hban-btn-next::after, .hban-btn-prev::after {
      color: #ffffff;
      font-size: 1.6rem;
      transition: 0.3s ease-in-out; }
    @media (hover: hover) {
      .hban-btn-next:hover, .hban-btn-prev:hover {
        background: #F49712; }
        .hban-btn-next:hover::after, .hban-btn-prev:hover::after {
          color: white; } }
  .hban .swiper {
    height: 100%; }
    .hban .swiper-slide-active .hban-desc {
      opacity: 1;
      transform: scale(1); }
  .hban-bot {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 1;
    height: 8.5rem; }
    @media screen and (max-width: 1024px) {
      .hban-bot {
        height: 6.5rem; }
        .hban-bot .banner-bot {
          padding: 2rem 0; } }

.hvid-wrap {
  padding: 6rem 0;
  background: #f8f8f8; }
  @media screen and (max-width: 768px) {
    .hvid-wrap {
      padding: 4rem 0; } }

.hvid-inner .title {
  color: #374151;
  margin-bottom: 3rem; }
  @media screen and (max-width: 768px) {
    .hvid-inner .title {
      font-size: 4.2rem; } }
  @media only screen and (max-width: 768px) and (max-width: 1200px) {
    .hvid-inner .title {
      font-size: 3.5rem; } }
  @media only screen and (max-width: 768px) and (max-width: 800px) {
    .hvid-inner .title {
      font-size: 2.7rem; } }

.hvid-vi {
  width: 100%;
  border-radius: 1.2rem;
  overflow: hidden;
  padding-top: calc((660 / 1215) * 100%);
  position: relative; }
  .hvid-vi video,
  .hvid-vi iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover; }

.hvid-bot {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  gap: 3rem; }
  .hvid-bot .txt {
    font-size: 2.8rem;
    color: #374151;
    line-height: 1; }
    @media only screen and (max-width: 1200px) {
      .hvid-bot .txt {
        font-size: 2.4rem; } }
    @media only screen and (max-width: 800px) {
      .hvid-bot .txt {
        font-size: 2.1rem; } }

.htech {
  overflow: hidden; }
  .htech-wrap {
    padding: 6rem 0; }
  .htech-flex {
    margin-top: 3.2rem; }
  .htech-top {
    width: 50%;
    margin-left: auto; }
    @media screen and (max-width: 650px) {
      .htech-top {
        width: 100%; } }
    .htech-top .title {
      font-size: 3.6rem;
      line-height: 1;
      margin-bottom: 2.8rem; }
      @media only screen and (max-width: 1200px) {
        .htech-top .title {
          font-size: 3.1rem; } }
      @media only screen and (max-width: 800px) {
        .htech-top .title {
          font-size: 2.6rem; } }
      @media only screen and (max-width: 500px) {
        .htech-top .title {
          font-size: 2.3rem; } }
    .htech-top .text {
      color: #374151;
      font-size: 2rem; }
      @media only screen and (max-width: 1200px) {
        .htech-top .text {
          font-size: 1.8rem; } }
      @media only screen and (max-width: 800px) {
        .htech-top .text {
          font-size: 1.6rem; } }
    @media screen and (max-width: 992px) {
      .htech-top .title {
        font-size: 2rem; } }
  @media only screen and (max-width: 992px) and (max-width: 1200px) {
    .htech-top .title {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 992px) and (max-width: 800px) {
    .htech-top .title {
      font-size: 1.6rem; } }
    @media screen and (max-width: 992px) {
      .htech-top .text {
        font-size: 1.4rem; } }
  .htech-bot {
    margin-top: -24%;
    width: 50%; }
    @media screen and (max-width: 650px) {
      .htech-bot {
        width: 50%;
        margin-top: 2rem;
        margin-left: -2rem; } }
  .htech-tag {
    background: #F49712;
    padding: 1.2rem 2.4rem;
    border-radius: 0.8rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    line-height: 1;
    color: white;
    font-size: 3.6rem;
    margin-bottom: 2.8rem;
    font-weight: 700;
    width: max-content; }
    @media only screen and (max-width: 1200px) {
      .htech-tag {
        font-size: 3.1rem; } }
    @media only screen and (max-width: 800px) {
      .htech-tag {
        font-size: 2.6rem; } }
    @media only screen and (max-width: 500px) {
      .htech-tag {
        font-size: 2.3rem; } }
    @media screen and (max-width: 992px) {
      .htech-tag {
        font-size: 2rem; } }
  @media only screen and (max-width: 992px) and (max-width: 1200px) {
    .htech-tag {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 992px) and (max-width: 800px) {
    .htech-tag {
      font-size: 1.6rem; } }
    @media screen and (max-width: 768px) {
      .htech-tag {
        margin-bottom: 1.5rem; } }
  .htech-img {
    position: relative;
    width: 100%; }
    .htech-img img {
      width: 100%;
      object-fit: contain; }
  .htech-item {
    position: absolute;
    cursor: pointer;
    z-index: 4; }
    .htech-item.x1 {
      left: 59%;
      top: 55%; }
    .htech-item.x2 {
      left: 56%;
      top: 72%; }
    .htech-item.x3 {
      left: 61%;
      top: 82%; }
      .htech-item.x3 .htech-item-ctn {
        left: 0.8rem; }
    .htech-item.x4 {
      left: 59%;
      top: 92%; }
    @media screen and (max-width: 650px) {
      .htech-item.x1 {
        top: 46%; }
      .htech-item.x2 {
        left: 53%;
        top: 70%; }
      .htech-item.x3 {
        left: 58%;
        top: 78%; }
      .htech-item.x4 {
        left: 59%;
        top: 89%; } }
    .htech-item-disc {
      width: 2rem;
      height: 2rem;
      border-radius: 100rem;
      background-color: #F49712;
      position: relative; }
      @media screen and (max-width: 768px) {
        .htech-item-disc {
          width: 1.8rem;
          height: 1.8rem; } }
      .htech-item-disc::before, .htech-item-disc:after {
        content: "";
        position: absolute;
        inset: 0;
        background: #F49712;
        border-radius: inherit; }
      .htech-item-disc::before {
        animation: 2s ftCircleAni ease infinite; }
      .htech-item-disc::after {
        animation: 2s ftCircleAni2 ease infinite; }
    .htech-item-ctn {
      position: absolute;
      width: 46vw;
      min-height: 5rem;
      display: flex;
      color: #374151;
      font-size: 2rem;
      left: 0.5rem;
      bottom: calc(100% - 0.2rem);
      transition: 0.5s cubic-bezier(0.72, 0.04, 0.49, 0.82);
      gap: 0.5rem;
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%); }
      @media only screen and (max-width: 1200px) {
        .htech-item-ctn {
          font-size: 1.8rem; } }
      @media only screen and (max-width: 800px) {
        .htech-item-ctn {
          font-size: 1.6rem; } }
      .htech-item-ctn p {
        margin: 0 !important; }
      @media screen and (max-width: 1500px) {
        .htech-item-ctn {
          width: 55vw; } }
      @media screen and (max-width: 1400px) {
        .htech-item-ctn {
          width: 65vw; } }
      @media screen and (max-width: 992px) {
        .htech-item-ctn {
          min-height: 3rem;
          gap: 2rem; }
          .htech-item-ctn .text {
            font-size: 1.2rem; } }
      @media screen and (max-width: 768px) {
        .htech-item-ctn {
          width: 68vw; } }
      @media screen and (max-width: 650px) {
        .htech-item-ctn {
          top: -0.2rem;
          gap: 1rem; } }
      .htech-item-ctn .text {
        flex: 1; }
      .htech-item-ctn:before {
        content: "";
        width: 18rem;
        position: relative;
        border: 0.1rem solid #F49712;
        transform: skew(-30deg) translate(-9%, 1.3rem);
        border-bottom: none;
        border-right: none;
        margin-left: 4rem; }
        @media screen and (max-width: 992px) {
          .htech-item-ctn:before {
            width: 12rem;
            transform: skew(-60deg) translate(0, 1rem); } }
        @media screen and (max-width: 768px) {
          .htech-item-ctn:before {
            width: 8rem;
            transform: skew(-65deg) translate(0, 1rem); } }
        @media screen and (max-width: 650px) {
          .htech-item-ctn:before {
            width: 4rem;
            transform: none;
            border-left: none;
            margin-left: 2rem;
            top: 0.7rem; } }
        @media screen and (max-width: 400px) {
          .htech-item-ctn:before {
            width: 3rem;
            margin-left: 1rem; } }
    .htech-item:hover {
      z-index: 3; }
      .htech-item:hover .htech-item-ctn {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important; }
    @media screen and (max-width: 768px) {
      .htech-item .htech-item-ctn {
        min-height: fit-content; } }

@keyframes ftCircleAni {
  0% {
    transform: scale(1); }
  90%,
  91% {
    transform: scale(1.5);
    opacity: 0; }
  100% {
    opacity: 0;
    transform: scale(1); } }

@keyframes ftCircleAni2 {
  0% {
    transform: scale(1); }
  90%,
  91% {
    transform: scale(3);
    opacity: 0; }
  100% {
    opacity: 0;
    transform: scale(1); } }

.hlo {
  overflow: hidden; }
  .hlo-flex {
    display: flex;
    margin: 0 -1.6rem; }
    @media screen and (max-width: 600px) {
      .hlo-flex {
        margin: 0 -0.4rem; } }
  .hlo-left {
    width: calc((3 / 12) * 100%);
    padding: 0 1.6rem; }
    @media screen and (max-width: 600px) {
      .hlo-left {
        padding: 0 0.4rem;
        display: none; } }
    @media screen and (max-width: 500px) {
      .hlo-left {
        width: calc((4 / 12) * 100%); } }
  .hlo-right {
    flex: 1;
    padding: 0 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; }
    @media screen and (max-width: 600px) {
      .hlo-right {
        padding: 0 0.4rem; } }
    .hlo-right .title {
      font-size: 3.5rem;
      line-height: 1.2; }
      @media only screen and (max-width: 1200px) {
        .hlo-right .title {
          font-size: 3rem; } }
      @media only screen and (max-width: 800px) {
        .hlo-right .title {
          font-size: 2.5rem; } }
      @media only screen and (max-width: 500px) {
        .hlo-right .title {
          font-size: 2.2rem; } }
      @media screen and (max-width: 768px) {
        .hlo-right .title br {
          display: none; } }
  .hlo-img {
    width: 100%; }
    .hlo-img img {
      width: 100%;
      object-fit: contain; }
  .hlo-map {
    width: 100%;
    margin-top: 2rem; }
    .hlo-map img {
      width: 100%;
      object-fit: contain; }

.hpart-wrap {
  padding: 6rem 0;
  background: #f8f8f8; }

.hpart-ctn {
  margin-top: 6rem; }

.hpart-slide {
  position: relative;
  padding-bottom: 8rem; }
  @media screen and (max-width: 1350px) {
    .hpart-slide {
      padding-bottom: 4rem; } }

.hpart-item {
  padding: 2rem;
  border-radius: 1.2rem;
  overflow: hidden;
  background: white;
  border: 0.1rem solid #e5e7eb;
  height: 100%; }

.hpart-img {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center; }
  .hpart-img img {
    width: 100%;
    object-fit: contain; }

@media screen and (max-width: 1350px) {
  .hpart-btn {
    position: relative;
    flex-direction: row-reverse;
    gap: 1rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    margin-top: 3rem; }
    .hpart-btn-next, .hpart-btn-prev {
      position: relative;
      inset: 0 !important;
      margin: 0 !important; } }

@media screen and (max-width: 768px) {
  .hpart-btn {
    display: none; } }

.hpart-btn-next, .hpart-btn-prev {
  height: 3.6rem;
  width: 3.6rem;
  border: 0.1rem solid #F49712;
  border-radius: 0.4rem;
  transition: 0.3s ease-in-out;
  margin-top: -5.2rem; }
  .hpart-btn-next::after, .hpart-btn-prev::after {
    color: #F49712;
    font-size: 1.6rem;
    transition: 0.3s ease-in-out; }
  @media (hover: hover) {
    .hpart-btn-next:hover, .hpart-btn-prev:hover {
      background: #F49712; }
      .hpart-btn-next:hover::after, .hpart-btn-prev:hover::after {
        color: white; } }

.hpart-btn-next {
  right: -6rem; }

.hpart-btn-prev {
  left: -6rem; }

.hpart-pagi {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }
  .hpart-pagi .swiper-pagination {
    position: relative;
    inset: 0; }

.hpart .swiper {
  margin: 0 -1.6rem; }
  @media screen and (max-width: 1100px) {
    .hpart .swiper {
      margin: 0 -0.8rem; } }
  .hpart .swiper-slide {
    width: 25%;
    padding: 0 1.6rem;
    height: auto; }
    @media screen and (max-width: 1100px) {
      .hpart .swiper-slide {
        padding: 0 0.8rem; } }
    @media screen and (max-width: 768px) {
      .hpart .swiper-slide {
        width: calc(100% / 3); } }
    @media screen and (max-width: 550px) {
      .hpart .swiper-slide {
        width: 50%; } }

.team-item {
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 768px) {
    .team-item .repro-img {
      padding-top: 100%; } }

.team-desc {
  display: flex;
  flex-direction: column;
  margin-top: 2rem; }

.team-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem; }
  .team-icon .icon {
    height: 4.4rem; }
    .team-icon .icon img {
      height: 100%;
      object-fit: contain; }

.team-txt {
  margin-top: auto;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.8rem;
  color: #374151; }
  @media only screen and (max-width: 1200px) {
    .team-txt {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 800px) {
    .team-txt {
      font-size: 1.5rem; } }

/* ----------------------------------------------
 * Generated by Animista on 2023-12-29 16:47:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
.puff-in-center {
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both; }

@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

.ourl-slide-box {
  position: relative; }
  @media screen and (min-width: 768px) {
    .ourl-slide-box.tabPanel .ourl-pagi {
      display: none; } }

.pcontact {
  padding: 6rem 0; }
  @media screen and (max-width: 768px) {
    .pcontact {
      padding: 4rem 0; } }
  .pcontact-flex {
    display: flex;
    margin: 0 -1rem; }
    @media screen and (max-width: 768px) {
      .pcontact-flex {
        flex-direction: column;
        row-gap: 2rem; } }
  .pcontact-left {
    padding: 0 1rem;
    width: 50%; }
    @media screen and (max-width: 768px) {
      .pcontact-left {
        width: 100%; } }
  .pcontact-right {
    flex: 1;
    padding: 0 1rem; }
  .pcontact-top .title {
    font-size: 4.5rem;
    line-height: 1.2; }
    @media only screen and (max-width: 1200px) {
      .pcontact-top .title {
        font-size: 4rem; } }
    @media only screen and (max-width: 800px) {
      .pcontact-top .title {
        font-size: 3rem; } }
  .pcontact-top .subtitle {
    font-family: 'Univers-Light-Normal'; }
  .pcontact-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 4rem; }
  .pcontact-item {
    font-family: "Inter";
    color: #1F2937; }
    .pcontact-item .link {
      transition: 0.3s ease-in-out; }
      @media (hover: hover) {
        .pcontact-item .link:hover {
          color: #F49712; } }
  .pcontact-map {
    border-radius: 0.8rem;
    border: 0.2rem solid #F49712;
    overflow: hidden; }
    .pcontact-map iframe {
      width: 100%;
      height: 100%;
      min-height: 39rem; }
  .pcontact-img {
    position: relative;
    padding-top: calc((320/481) * 100%);
    overflow: hidden;
    border-radius: 0.8rem;
    transition: 0.3s ease-in-out; }
    .pcontact-img::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      inset: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
      z-index: 1;
      transition: 0.3s ease-in-out; }
    .pcontact-img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .pcontact-slide {
    margin-top: 4rem;
    padding: 3.5rem 0; }
    @media screen and (max-width: 768px) {
      .pcontact-slide {
        padding: 2rem 0;
        margin-top: 2rem; } }
    .pcontact-slide-inner .swiper {
      padding: 0 calc((4/12) * 100%); }
      @media screen and (max-width: 768px) {
        .pcontact-slide-inner .swiper {
          padding: 0 calc((3/12) * 100%); } }
      @media screen and (max-width: 550px) {
        .pcontact-slide-inner .swiper {
          padding: 0 calc((2/12) * 100%); } }
      .pcontact-slide-inner .swiper-slide {
        opacity: 0;
        visibility: hidden;
        transition: 0.3s ease-in-out; }
        .pcontact-slide-inner .swiper-slide-active {
          opacity: 1;
          visibility: visible; }
          .pcontact-slide-inner .swiper-slide-active .pcontact-img {
            border-radius: 0.4rem; }
            .pcontact-slide-inner .swiper-slide-active .pcontact-img::before {
              opacity: 0; }
        .pcontact-slide-inner .swiper-slide-prev, .pcontact-slide-inner .swiper-slide-next {
          opacity: 1;
          visibility: visible; }
  @media screen and (max-width: 550px) {
    .pcontact-btn {
      position: relative;
      flex-direction: row-reverse;
      gap: 1rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      margin-top: 2rem; }
      .pcontact-btn-next, .pcontact-btn-prev {
        position: relative;
        inset: 0;
        margin: 0; } }
  .pcontact-btn-next, .pcontact-btn-prev {
    height: 3.6rem;
    width: 3.6rem;
    border: 0.1rem solid #F49712;
    border-radius: 0.4rem;
    transition: 0.3s ease-in-out; }
    .pcontact-btn-next::after, .pcontact-btn-prev::after {
      color: #F49712;
      font-size: 1.6rem;
      transition: 0.3s ease-in-out; }
    @media (hover: hover) {
      .pcontact-btn-next:hover, .pcontact-btn-prev:hover {
        background: #F49712; }
        .pcontact-btn-next:hover::after, .pcontact-btn-prev:hover::after {
          color: white; } }

.sform {
  overflow: hidden; }
  .sform-wrap {
    padding: 6rem 0;
    background: #F8F8F8; }
  .sform-top {
    display: flex;
    gap: 2.4rem; }
    .sform-top .title {
      font-size: 4.5rem;
      line-height: 1.2; }
      @media only screen and (max-width: 1200px) {
        .sform-top .title {
          font-size: 4rem; } }
      @media only screen and (max-width: 800px) {
        .sform-top .title {
          font-size: 3rem; } }
    @media screen and (max-width: 768px) {
      .sform-top {
        gap: 1.4rem; } }
    @media screen and (max-width: 550px) {
      .sform-top br {
        display: none; } }
    .sform-top .desc .txt {
      color: #111927;
      font-size: 2.2rem;
      font-weight: 500;
      font-family: 'Univers-Light-Normal'; }
      @media only screen and (max-width: 1200px) {
        .sform-top .desc .txt {
          font-size: 2rem; } }
      @media only screen and (max-width: 800px) {
        .sform-top .desc .txt {
          font-size: 1.9rem; } }
  .sform-flex {
    display: flex;
    margin: 0 -1.5rem;
    margin-top: 1.2rem; }
    @media screen and (max-width: 992px) {
      .sform-flex {
        margin: 0 -0.5rem;
        margin-top: 1.2rem; } }
    @media screen and (max-width: 768px) {
      .sform-flex {
        flex-direction: column;
        row-gap: 1rem; } }
  .sform-left {
    padding: 0 1.5rem;
    width: 50%; }
    @media screen and (max-width: 992px) {
      .sform-left {
        padding: 0 0.5rem; } }
    @media screen and (max-width: 768px) {
      .sform-left {
        width: 50%;
        margin: 0 auto; } }
    @media screen and (max-width: 500px) {
      .sform-left {
        width: 60%; } }
    @media screen and (max-width: 400px) {
      .sform-left {
        width: 70%; } }
  .sform-right {
    padding: 0 1.5rem;
    width: 50%; }
    @media screen and (max-width: 992px) {
      .sform-right {
        padding: 0 0.5rem; } }
    @media screen and (max-width: 768px) {
      .sform-right {
        width: 100%; } }
  .sform-img {
    width: 100%; }
    .sform-img img {
      width: 100%;
      object-fit: contain; }
  .sform-form {
    padding: 3.2rem;
    background: #fff;
    border-radius: 1.2rem;
    box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.1), 0px 1px 3px 0px rgba(16, 24, 40, 0.1); }
    @media screen and (max-width: 1100px) {
      .sform-form {
        padding: 2rem 1rem; } }
    .sform-form-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1.6rem;
      row-gap: 2.4rem; }
    .sform-form .ip-control {
      padding: 0 1.6rem; }
    .sform-form-btn {
      margin-top: 3rem; }

.scroll-btn {
  background-color: #F49712;
  display: flex;
  flex-direction: column;
  width: 5.6rem;
  height: 10.2rem;
  border-radius: 0.8rem;
  flex-shrink: 0; }
  @media screen and (max-width: 768px) {
    .scroll-btn {
      width: 4rem;
      height: 8rem; } }
  .scroll-btn-up, .scroll-btn-down {
    height: 50%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    .scroll-btn-up i, .scroll-btn-down i {
      color: white;
      font-size: 2.4rem; }

.ourl-wrap {
  padding: 6rem 0; }

.ourl-row {
  padding-top: 6rem; }

.ourl-top {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 7rem;
  z-index: 99; }
  @media screen and (max-width: 1200px) {
    .ourl-top {
      top: 7.9rem; } }
  .ourl-top-list {
    display: flex;
    gap: 1.2rem;
    overflow-x: auto; }
    .ourl-top-list::-webkit-scrollbar {
      display: none; }
    .ourl-top-list-box {
      border-radius: 0.8rem;
      background: #fef5e7;
      padding: 1.2rem;
      max-width: 100%;
      width: max-content;
      margin: 0 auto; }
      @media screen and (max-width: 768px) {
        .ourl-top-list-box {
          padding: 0.7rem 1.2rem; } }
  .ourl-top-item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    transition: 0.3s;
    border-radius: 0.4rem;
    line-height: 1;
    height: 4.6rem;
    padding: 0.5rem 2.4rem;
    font-weight: 700;
    font-size: 2rem;
    color: #4b5563;
    cursor: pointer;
    width: max-content;
    white-space: nowrap; }
    @media only screen and (max-width: 1200px) {
      .ourl-top-item {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 800px) {
      .ourl-top-item {
        font-size: 1.6rem; } }
    .ourl-top-item.active {
      background-color: #F49712;
      color: white; }
    @media (hover: hover) {
      .ourl-top-item:hover {
        background-color: #F49712;
        color: white; } }
    @media screen and (max-width: 768px) {
      .ourl-top-item {
        padding: 0.5rem 1rem;
        height: 3.6rem; } }

@media screen and (max-width: 1350px) {
  .ourl-btn {
    position: relative;
    flex-direction: row-reverse;
    gap: 1rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    margin-top: 2rem; }
    .ourl-btn-next, .ourl-btn-prev {
      position: relative;
      inset: 0 !important;
      margin: 0; } }

@media screen and (max-width: 768px) {
  .ourl-btn {
    display: none; } }

.ourl-btn-next, .ourl-btn-prev {
  height: 3.6rem;
  width: 3.6rem;
  border: 0.1rem solid #F49712;
  border-radius: 0.4rem;
  transition: 0.3s ease-in-out; }
  .ourl-btn-next::after, .ourl-btn-prev::after {
    color: #F49712;
    font-size: 1.6rem;
    transition: 0.3s ease-in-out; }
  @media (hover: hover) {
    .ourl-btn-next:hover, .ourl-btn-prev:hover {
      background: #F49712; }
      .ourl-btn-next:hover::after, .ourl-btn-prev:hover::after {
        color: white; } }

.ourl-btn-next {
  right: -6rem; }

.ourl-btn-prev {
  left: -6rem; }

.ourl-slide {
  position: relative;
  margin-top: 6rem; }
  @media screen and (max-width: 992px) {
    .ourl-slide {
      margin-top: 3rem; } }
  .ourl-slide .swiper {
    margin: 0 -1.6rem; }
    .ourl-slide .swiper-slide {
      width: calc(100% / 3);
      padding: 0 1.6rem; }
    @media screen and (max-width: 992px) {
      .ourl-slide .swiper {
        margin: 0 -0.4rem; }
        .ourl-slide .swiper-slide {
          padding: 0 0.4rem; } }
    @media screen and (max-width: 768px) {
      .ourl-slide .swiper-slide {
        width: 40%; } }
    @media screen and (max-width: 550px) {
      .ourl-slide .swiper-slide {
        width: 50%; } }
    @media screen and (max-width: 450px) {
      .ourl-slide .swiper-slide {
        width: 70%; } }

.ourl-item {
  width: 100%; }

.ourl-box {
  position: relative;
  border-radius: 1.2rem;
  overflow: hidden; }

.ourl-img {
  position: relative;
  overflow: hidden;
  padding-top: calc((480 / 384) * 100%);
  border-radius: 1.2rem; }
  .ourl-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s ease-in-out; }

.ourl-desc {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.5rem 2rem; }
  .ourl-desc::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    inset: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50.96%, rgba(0, 0, 0, 0.6) 99.86%), linear-gradient(179deg, rgba(0, 0, 0, 0.6) 0.65%, rgba(200, 200, 200, 0) 27.77%); }

.ourl-name {
  z-index: 1; }
  .ourl-name .link {
    color: #f9fafb;
    transition: 0.3s ease-in-out;
    font-size: 2.8rem;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    text-shadow: 1px 1px 5px #000000; }
    @media only screen and (max-width: 1200px) {
      .ourl-name .link {
        font-size: 2.4rem; } }
    @media only screen and (max-width: 800px) {
      .ourl-name .link {
        font-size: 2.1rem; } }
    @media (hover: hover) {
      .ourl-name .link:hover {
        color: #F49712; } }
    .ourl-name .link:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      inset: 0; }

.ourl-bot {
  position: relative;
  z-index: 5; }
  .ourl-bot .desc {
    color: #f9fafb;
    margin-bottom: 0.5rem;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 1px 1px 5px #000000; }
  .ourl-bot .link {
    display: flex;
    justify-content: space-between;
    font-size: 2.4rem;
    color: #f9fafb;
    transition: 0.3s ease-in-out;
    position: relative;
    padding-top: 2.5rem;
    text-shadow: 1px 1px 5px #000000; }
    @media only screen and (max-width: 1200px) {
      .ourl-bot .link {
        font-size: 2rem; } }
    @media only screen and (max-width: 800px) {
      .ourl-bot .link {
        font-size: 1.7rem; } }
    .ourl-bot .link::after {
      position: absolute;
      content: "";
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 4rem);
      height: 0.1rem;
      background-color: #f9fafb;
      opacity: 0.7; }
    @media (hover: hover) {
      .ourl-bot .link:hover {
        color: #F49712; }
        .ourl-bot .link:hover i {
          color: #F49712; } }

.ourl-pagi {
  margin-top: 4rem; }
  @media screen and (max-width: 1350px) {
    .ourl-pagi {
      margin-top: 2rem; } }
  .ourl-pagi .swiper-pagination {
    position: relative;
    inset: 0; }
  @media screen and (max-width: 768px) {
    .ourl-pagi.d-none {
      display: block; } }

.deourl-main {
  background: #f8f8f8;
  padding: 6rem 0; }
  @media screen and (max-width: 992px) {
    .deourl-main {
      padding: 2rem 0;
      padding-top: 1.5rem; } }
  @media screen and (max-width: 768px) {
    .deourl-main {
      overflow: hidden; } }

.deourl-top {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 7rem;
  z-index: 99; }
  @media screen and (max-width: 1200px) {
    .deourl-top {
      top: 7.9rem; } }
  .deourl-top:before {
    position: absolute;
    content: "";
    bottom: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background: #f8f8f8; }
  .deourl-top-list {
    display: flex;
    gap: 1.2rem;
    overflow-x: auto; }
    .deourl-top-list::-webkit-scrollbar {
      display: none; }
    .deourl-top-list-box {
      border-radius: 0.8rem;
      padding: 1.2rem;
      max-width: 100%;
      width: max-content;
      margin: 0 auto;
      position: relative; }
      @media screen and (max-width: 768px) {
        .deourl-top-list-box {
          padding: 0.7rem 1.2rem; } }
  .deourl-top-item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    transition: 0.3s;
    border-radius: 0.4rem;
    line-height: 1;
    height: 4.6rem;
    padding: 0.5rem 2.4rem;
    font-weight: 700;
    font-size: 2rem;
    color: #4b5563;
    cursor: pointer;
    width: max-content;
    white-space: nowrap;
    position: relative; }
    @media only screen and (max-width: 1200px) {
      .deourl-top-item {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 800px) {
      .deourl-top-item {
        font-size: 1.6rem; } }
    .deourl-top-item:before {
      position: absolute;
      content: "";
      bottom: 0;
      width: 0;
      height: 0.2rem;
      left: 50%;
      transform: translateX(-50%);
      transition: 0.3s ease-in-out;
      background: #F49712; }
    .deourl-top-item.active {
      color: #F49712; }
      .deourl-top-item.active:before {
        width: 100%; }
    @media (hover: hover) {
      .deourl-top-item:hover {
        color: #F49712; }
        .deourl-top-item:hover:before {
          width: 100%; } }
    @media screen and (max-width: 768px) {
      .deourl-top-item {
        padding: 0.5rem 1rem;
        height: 3.6rem; } }

.fea {
  padding-top: 6rem;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .fea {
      padding-top: 4rem; } }
  .fea-flex {
    display: flex;
    margin: 0 -1.6rem; }
    @media screen and (max-width: 1100px) {
      .fea-flex {
        margin: 0 -0.4rem; } }
    @media screen and (max-width: 768px) {
      .fea-flex {
        flex-direction: column;
        row-gap: 1rem; } }
  .fea-left {
    padding: 0 1.6rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2.4rem; }
    @media screen and (max-width: 1100px) {
      .fea-left {
        padding: 0 0.4rem; } }
    @media screen and (max-width: 768px) {
      .fea-left {
        width: 100%; } }
  .fea-right {
    width: 50%;
    padding: 0 1.6rem; }
    @media screen and (max-width: 1100px) {
      .fea-right {
        padding: 0 0.4rem; } }
    @media screen and (max-width: 768px) {
      .fea-right {
        width: 100%; } }
  .fea-ctn-box {
    display: flex;
    flex-direction: column;
    border-radius: 1.2rem;
    overflow: hidden;
    box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.1), 0px 1px 3px 0px rgba(16, 24, 40, 0.1); }
  .fea-list {
    display: flex;
    flex-direction: column;
    gap: 2.4rem; }
    @media screen and (max-width: 768px) {
      .fea-list {
        gap: 1rem; } }
  .fea-item {
    display: flex;
    gap: 1.2rem;
    align-items: center; }
    .fea-item .icon {
      width: 2rem;
      height: 2rem;
      flex-shrink: 0; }
      .fea-item .icon img {
        width: 100%;
        object-fit: contain; }
    .fea-item .txt {
      font-family: "Univers-Light-Normal";
      font-weight: 500;
      color: #111927; }
  @media screen and (max-width: 500px) {
    .fea-btn {
      display: none; } }
  @media screen and (max-width: 400px) {
    .fea-btn {
      position: relative;
      flex-direction: row-reverse;
      gap: 1rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      margin-top: 2rem; }
      .fea-btn-next, .fea-btn-prev {
        position: relative;
        inset: 0 !important;
        margin: 0; } }
  .fea-btn-next, .fea-btn-prev {
    height: 2.8rem;
    width: 2.8rem;
    border: 0.1rem solid #F49712;
    border-radius: 0.4rem;
    transition: 0.3s ease-in-out;
    margin-top: -1.2rem; }
    .fea-btn-next::after, .fea-btn-prev::after {
      color: #F49712;
      font-size: 1.6rem;
      transition: 0.3s ease-in-out; }
    @media (hover: hover) {
      .fea-btn-next:hover, .fea-btn-prev:hover {
        background: #F49712; }
        .fea-btn-next:hover::after, .fea-btn-prev:hover::after {
          color: white; } }
  .fea-btn-next {
    right: -3.6rem; }
  .fea-btn-prev {
    left: -3.6rem; }
  .fea-img {
    padding-top: calc((398 / 592) * 100%);
    position: relative;
    overflow: hidden; }
    .fea-img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .fea-slide {
    position: relative; }
  .fea-thumb {
    padding: 2.8rem 4.8rem;
    background-color: #f3f4f6; }
    @media screen and (max-width: 500px) {
      .fea-thumb {
        padding: 1.8rem 0; } }
    .fea-thumb-slide {
      position: relative; }
      @media screen and (max-width: 768px) {
        .fea-thumb-slide .fea-thumb-img {
          display: none; }
        .fea-thumb-slide .fea-btn {
          display: none; } }
    .fea-thumb-img {
      padding-top: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 1.2rem;
      border: 0.1rem solid transparent; }
      .fea-thumb-img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .fea-thumb .swiper-slide {
      width: 20%; }
      .fea-thumb .swiper-slide-thumb-active .fea-thumb-img {
        border: 0.1rem solid #F49712; }
  .fea-pagi {
    margin-top: 1rem; }
    @media screen and (max-width: 768px) {
      .fea-pagi {
        margin-top: 0; } }
    .fea-pagi .swiper-pagination {
      position: relative;
      inset: 0; }
  .fea-desc ul li {
    color: #101828;
    margin-bottom: 1.6rem;
    font-family: "Univers-Light-Normal"; }
    @media screen and (max-width: 768px) {
      .fea-desc ul li {
        margin-bottom: 1rem; } }
    .fea-desc ul li:last-child {
      margin-bottom: 0; }
  .fea-down {
    margin-top: 6rem; }
    @media screen and (max-width: 768px) {
      .fea-down {
        margin-top: 4rem; } }
    .fea-down .btn, .fea-down .btn-pri, .fea-down .btn-bd, .fea-down .btn-second, .fea-down .btn-thir, .fea-down .btn-four {
      margin: 0 auto; }

.tech {
  padding-top: 6rem;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .tech {
      padding-top: 4rem; } }
  .tech-flex {
    display: flex;
    margin: 0 -1.6rem; }
    @media screen and (max-width: 1100px) {
      .tech-flex {
        margin: 0 -0.4rem; } }
    @media screen and (max-width: 768px) {
      .tech-flex {
        flex-direction: column-reverse !important;
        row-gap: 1rem; } }
  .tech-left {
    padding: 0 1.6rem;
    width: 50%; }
    @media screen and (max-width: 1100px) {
      .tech-left {
        padding: 0 0.4rem; } }
    @media screen and (max-width: 768px) {
      .tech-left {
        width: 100%; } }
  .tech-right {
    width: 50%;
    padding: 0 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem; }
    @media screen and (max-width: 1100px) {
      .tech-right {
        padding: 0 0.4rem; } }
    @media screen and (max-width: 768px) {
      .tech-right {
        width: 100%; } }
  .tech-vi {
    width: 100%;
    position: relative;
    border-radius: 1.2rem;
    overflow: hidden;
    display: flex;
    padding-top: 100%; }
    .tech-vi video,
    .tech-vi iframe,
    .tech-vi img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .tech-vi.gItem {
      cursor: pointer; }
  .tech-desc ul li {
    margin-bottom: 1.6rem;
    color: #111927;
    font-family: "Univers-Light-Normal"; }
    @media screen and (max-width: 768px) {
      .tech-desc ul li {
        margin-bottom: 1rem; } }
    .tech-desc ul li:last-child {
      margin-bottom: 0; }
  .tech-slide {
    position: relative; }
  .tech-pagi {
    position: relative;
    margin-top: 4rem; }

.size {
  padding-top: 6rem; }
  @media screen and (max-width: 768px) {
    .size {
      padding-top: 4rem; } }
  .size-inner {
    padding: 2.4rem 1.2rem;
    border-radius: 1.2rem;
    background-color: white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1); }
  .size-table {
    padding-top: 3rem; }
  .size table {
    width: 100%; }
    .size table thead tr th {
      width: calc(100% / 5);
      font-weight: 600;
      color: #F49712;
      padding: 0 1.6rem; }
      .size table thead tr th .title {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 1.2rem;
        background-color: #f9fafb;
        height: 4.4rem; }
    @media screen and (max-width: 768px) {
      .size table thead {
        display: none; } }
    @media screen and (max-width: 768px) {
      .size table tbody tr:not(:last-child) {
        border-bottom: 0.1rem solid #efefef; } }
    .size table tbody tr td {
      color: #374151;
      text-align: center;
      padding: 0 1.6rem; }
      .size table tbody tr td .txt {
        padding: 2.3rem;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        width: 100%;
        height: 100%;
        border-bottom: 0.1rem solid #eaecf0; }
        @media screen and (max-width: 768px) {
          .size table tbody tr td .txt {
            border-bottom: none;
            padding: 0;
            justify-content: end; } }
      @media screen and (max-width: 768px) {
        .size table tbody tr td {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 1.6rem 0;
          position: relative; }
          .size table tbody tr td::before {
            content: attr(data-text);
            width: fit-content;
            font-weight: 600;
            font-size: 1.6rem;
            color: #031230;
            text-align: left;
            white-space: nowrap; } }
  .size-more {
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-top: 2rem; }
    .size-more.load-hide i {
      transform: rotate(180deg); }

.des {
  padding: 6rem 0; }
  @media screen and (max-width: 768px) {
    .des {
      padding: 4rem 0; } }
  .des-top .title {
    line-height: 1.2; }
  .des-top .ourl-top-list-box {
    margin-top: 4rem; }
  .des-slide {
    margin-top: 4rem; }
    .des-slide-box {
      position: relative; }
      .des-slide-box:not(:first-child) {
        display: none; }
  @media screen and (max-width: 1350px) {
    .des-btn {
      position: relative;
      flex-direction: row-reverse;
      gap: 1rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      margin-top: 3rem; }
      .des-btn-next, .des-btn-prev {
        position: relative;
        inset: 0 !important;
        margin: 0; } }
  @media screen and (max-width: 768px) {
    .des-btn {
      display: none; } }
  .des-btn-next, .des-btn-prev {
    height: 3.6rem;
    width: 3.6rem;
    border: 0.1rem solid #F49712;
    border-radius: 0.4rem;
    transition: 0.3s ease-in-out;
    margin-top: -7rem; }
    @media screen and (max-width: 1350px) {
      .des-btn-next, .des-btn-prev {
        margin-top: 0; } }
    .des-btn-next::after, .des-btn-prev::after {
      color: #F49712;
      font-size: 1.6rem;
      transition: 0.3s ease-in-out; }
    @media (hover: hover) {
      .des-btn-next:hover, .des-btn-prev:hover {
        background: #F49712; }
        .des-btn-next:hover::after, .des-btn-prev:hover::after {
          color: white; } }
  .des-btn-next {
    right: -6rem; }
  .des-btn-prev {
    left: -6rem; }
  .des-pagi {
    margin-top: 4rem; }
    .des-pagi .swiper-pagination {
      position: relative;
      inset: 0; }
    @media screen and (max-width: 768px) {
      .des-pagi {
        margin-top: 2rem; } }
  .des-box {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center; }
  .des-img {
    position: relative;
    padding-top: 100%;
    overflow: hidden;
    border-radius: 1.2rem;
    display: block;
    width: 100%; }
    .des-img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .des-name {
    font-size: 2rem;
    font-weight: 500;
    color: #111927;
    transition: 0.3s ease-in-out; }
    @media only screen and (max-width: 1200px) {
      .des-name {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 800px) {
      .des-name {
        font-size: 1.6rem; } }
    @media (hover: hover) {
      .des-name:hover {
        color: #F49712; } }
  .des .swiper {
    margin: 0 -1.6rem; }
    @media screen and (max-width: 1100px) {
      .des .swiper {
        margin: 0 -0.8rem; } }
    .des .swiper-slide {
      width: 25%;
      padding: 0 1.6rem; }
      @media screen and (max-width: 1100px) {
        .des .swiper-slide {
          padding: 0 0.8rem; } }
      @media screen and (max-width: 768px) {
        .des .swiper-slide {
          width: calc(100% / 3); } }
      @media screen and (max-width: 550px) {
        .des .swiper-slide {
          width: 50%; } }

.repro {
  overflow: hidden; }
  .repro-wrap {
    position: relative;
    padding: 6rem 0; }
    @media screen and (max-width: 768px) {
      .repro-wrap {
        padding: 4rem 0; } }
  .repro-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2; }
    .repro-bg img {
      width: 100%;
      object-fit: cover;
      height: 100%; }
    .repro-bg::before {
      position: absolute;
      width: 100%;
      content: "";
      bottom: 0;
      left: 0;
      height: 70%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFF 13.02%); }
  .repro-ctn {
    padding-top: 6rem;
    padding-left: 4rem; }
    @media screen and (max-width: 1200px) {
      .repro-ctn {
        padding-left: 1.5rem; } }
    @media screen and (max-width: 768px) {
      .repro-ctn {
        padding-top: 3rem; } }
    @media screen and (max-width: 550px) {
      .repro-ctn {
        padding: 0 1.5rem; } }
  .repro-flex {
    display: flex;
    margin: 0 -1.6rem; }
    @media screen and (max-width: 768px) {
      .repro-flex {
        margin: 0;
        flex-direction: column;
        row-gap: 2rem; } }
  .repro-left {
    width: calc((5/12) * 100%);
    padding: 0 1.6rem; }
    @media screen and (max-width: 768px) {
      .repro-left {
        width: 100%;
        padding: 0;
        padding-right: 1.5rem; } }
    .repro-left-ctn {
      padding-left: calc((100vw - 123rem) / 2); }
    .repro-left .btn, .repro-left .btn-pri, .repro-left .btn-bd, .repro-left .btn-second, .repro-left .btn-thir, .repro-left .btn-four {
      margin-top: 4rem; }
      @media screen and (max-width: 768px) {
        .repro-left .btn, .repro-left .btn-pri, .repro-left .btn-bd, .repro-left .btn-second, .repro-left .btn-thir, .repro-left .btn-four {
          display: none; } }
  .repro-right {
    padding: 0 1.6rem;
    width: calc((7/12) * 100%); }
    @media screen and (max-width: 768px) {
      .repro-right {
        width: 100%;
        padding: 0; } }
    .repro-right .btn, .repro-right .btn-pri, .repro-right .btn-bd, .repro-right .btn-second, .repro-right .btn-thir, .repro-right .btn-four {
      margin: 0 auto;
      margin-top: 2rem; }
      @media screen and (min-width: 768px) {
        .repro-right .btn, .repro-right .btn-pri, .repro-right .btn-bd, .repro-right .btn-second, .repro-right .btn-thir, .repro-right .btn-four {
          display: none; } }
  .repro-box {
    position: relative;
    border-radius: 1.2rem;
    overflow: hidden; }
  .repro-desc {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    border-radius: 1.2rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 53.02%, rgba(244, 151, 18, 0.6) 83.23%);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    padding: 2rem; }
  .repro-name {
    font-size: 2.4rem;
    font-weight: 700;
    color: white;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media only screen and (max-width: 1200px) {
      .repro-name {
        font-size: 2rem; } }
    @media only screen and (max-width: 800px) {
      .repro-name {
        font-size: 1.7rem; } }
  .repro-txt {
    color: white;
    font-size: 2rem;
    text-shadow: 0px 2px 4px rgba(16, 24, 40, 0.1), 0px 4px 6px rgba(16, 24, 40, 0.1); }
    @media only screen and (max-width: 1200px) {
      .repro-txt {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 800px) {
      .repro-txt {
        font-size: 1.6rem; } }
  .repro-img {
    padding-top: calc((474/280) * 100%);
    position: relative;
    overflow: hidden;
    border-radius: 1.2rem; }
    .repro-img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s ease-in-out; }
  .repro-slide {
    position: relative; }
  .repro-btn {
    position: relative;
    flex-direction: row-reverse;
    gap: 1rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    margin-top: 3rem; }
    @media screen and (max-width: 768px) {
      .repro-btn {
        display: none; } }
    .repro-btn-next, .repro-btn-prev {
      position: relative;
      inset: 0 !important;
      margin: 0 !important; }
    .repro-btn-next, .repro-btn-prev {
      height: 3.6rem;
      width: 3.6rem;
      border: 0.1rem solid #F49712;
      border-radius: 0.4rem;
      transition: 0.3s ease-in-out;
      margin-top: -5.2rem; }
      .repro-btn-next::after, .repro-btn-prev::after {
        color: #F49712;
        font-size: 1.6rem;
        transition: 0.3s ease-in-out; }
      @media (hover: hover) {
        .repro-btn-next:hover, .repro-btn-prev:hover {
          background: #F49712; }
          .repro-btn-next:hover::after, .repro-btn-prev:hover::after {
            color: white; } }
    .repro-btn-next {
      right: -6rem; }
    .repro-btn-prev {
      left: -6rem; }
  .repro-pagi {
    margin-top: 4rem; }
    @media screen and (max-width: 768px) {
      .repro-pagi {
        margin-top: 2rem; } }
    .repro-pagi .swiper-pagination {
      position: relative;
      inset: 0;
      height: 0.2rem;
      background: rgba(237, 224, 204, 0.75); }
      .repro-pagi .swiper-pagination-progressbar-fill {
        background: #F49712; }
  @media screen and (max-width: 550px) {
    .repro .swiper {
      margin: 0 -1.5rem; } }
  .repro .swiper-slide {
    width: calc((100% / 3) -  4rem); }
    @media screen and (max-width: 1100px) {
      .repro .swiper-slide {
        width: calc((100% / 2) -  4rem); } }
    @media screen and (max-width: 768px) {
      .repro .swiper-slide {
        width: calc((100% / 3) -  2rem); } }
    @media screen and (max-width: 550px) {
      .repro .swiper-slide {
        width: 100%;
        padding: 0 1.5rem; } }

.sre-wrap {
  padding: 6rem 0;
  background: #F3F4F6; }
  @media screen and (max-width: 768px) {
    .sre-wrap {
      padding: 4rem 0; } }

.sre-top {
  margin-bottom: 6rem; }
  .sre-top .btn, .sre-top .btn-pri, .sre-top .btn-bd, .sre-top .btn-second, .sre-top .btn-thir, .sre-top .btn-four {
    margin: 0 auto; }

.sre-rate {
  padding: 2rem;
  background-color: white;
  border-radius: 0.8rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; }

.sre .srate-rate {
  display: flex;
  padding: 2rem;
  border-bottom: 0.1rem solid #E2E8F0; }
  @media screen and (max-width: 768px) {
    .sre .srate-rate {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      padding: 2rem 0; } }
  .sre .srate-rate-top {
    flex-direction: column;
    width: 30%;
    border-right: 0.1rem solid  #E2E8F0;
    justify-content: center; }
    @media screen and (max-width: 768px) {
      .sre .srate-rate-top {
        border-right: none;
        width: 100%; } }
    .sre .srate-rate-top .text {
      text-align: center;
      color: #374151; }
    .sre .srate-rate-top .srate-rate-star .text {
      color: #475569;
      margin-top: 1rem; }
  .sre .srate-rate-ctn {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    @media screen and (max-width: 768px) {
      .sre .srate-rate-ctn {
        width: 100%;
        flex: unset; } }
  .sre .srate-rate-item {
    width: 35rem;
    max-width: 100%; }
    .sre .srate-rate-item .text {
      color: #475569; }

.sre-form {
  padding-top: 2rem; }
  .sre-form .sform-form {
    margin-top: 2rem;
    border: 1px solid  #E5E7EB; }

.sre-rv {
  margin-top: 2rem; }
  .sre-rv > .txt {
    color: #475569; }
  .sre-rv-top {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    margin-top: 1.2rem; }
    @media screen and (max-width: 600px) {
      .sre-rv-top {
        gap: 1rem; } }
  .sre-rv-item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    gap: 0.4rem;
    border-radius: 0.4rem;
    border: 0.1rem solid #E5E7EB;
    width: max-content;
    padding: 0.5rem 1rem;
    height: 3.6rem;
    cursor: pointer;
    transition: 0.3s ease-in-out; }
    .sre-rv-item .txt {
      color: #334155;
      font-weight: 500;
      line-height: 1;
      transition: 0.3s ease-in-out; }
    .sre-rv-item .icon {
      display: flex;
      width: 1.4rem;
      height: 1.4rem; }
    .sre-rv-item.active {
      border-color: #F49712;
      background-color: #FEF5E7; }
      .sre-rv-item.active .txt {
        color: #F49712; }
  .sre-rv-main {
    margin-top: 3rem; }
  .sre-rv-ctn:not(:first-child) {
    display: none; }

.swiper-pagination-bullet {
  border: 0.1rem solid #000;
  background: none;
  opacity: 1;
  transition: 0.2s ease-in-out; }
  .swiper-pagination-bullet-active {
    background: #F49712;
    border: 0.1rem solid #F49712; }

.rv-item {
  display: flex;
  flex-direction: column; }
  .rv-item:not(:first-child) {
    margin-top: 3rem; }

.rv-top {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center; }
  .rv-top-left {
    display: flex;
    gap: 2rem;
    align-items: center; }
    @media screen and (max-width: 600px) {
      .rv-top-left {
        gap: 1rem; } }

.rv-avt {
  width: 6rem;
  height: 6rem;
  border-radius: 100rem;
  overflow: hidden; }
  @media screen and (max-width: 600px) {
    .rv-avt {
      width: 4rem;
      height: 4rem; } }
  .rv-avt img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.rv-name {
  color: #041E42;
  font-weight: 600;
  display: flex;
  gap: 0.4rem; }
  .rv-name .icon {
    width: 2rem;
    height: 2rem;
    display: flex; }
    .rv-name .icon img {
      width: 100%;
      height: 100%;
      object-fit: contain; }

.rv-date {
  display: flex;
  align-items: center;
  gap: 0.4rem; }
  .rv-date .icon {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center; }
    .rv-date .icon i {
      color: #475569; }
  .rv-date .txt {
    line-height: 1;
    color: #475569;
    font-weight: 600; }

.rv-ctn {
  padding-left: 8rem; }
  @media screen and (max-width: 600px) {
    .rv-ctn {
      padding-left: 5rem; } }
  @media screen and (max-width: 420px) {
    .rv-ctn {
      padding-left: 0; } }

.rv-control .txt {
  color: #475569;
  font-size: 1.4rem; }

.rv-control .link {
  color: #1D4ED8;
  font-size: 1.4rem; }

.rv-sub {
  margin-top: 2rem;
  display: flex;
  gap: 2rem; }
  @media screen and (max-width: 600px) {
    .rv-sub {
      gap: 1rem; } }
  .rv-sub-ctn {
    padding: 1.2rem;
    border-radius: 0.8rem;
    background: #F1F5F9;
    flex: 1; }

.post-detail .header-nav .menu-nav > .menu-list > .menu-item > .menu-link {
  color: #374151; }
  @media (hover: hover) {
    .post-detail .header-nav .menu-nav > .menu-list > .menu-item > .menu-link:hover {
      color: #F49712; } }

.post-detail .header-nav .menu-nav > .menu-list > .menu-item.current_page_item > a,
.post-detail .header-nav .menu-nav > .menu-list > .menu-item.current-menu-item > a {
  color: #F49712; }

.post-detail .header-search .btn-icon {
  border: 0.1rem solid #374151; }
  .post-detail .header-search .btn-icon i {
    color: #374151; }

.post-detail .main,
.post-detail .site-main {
  padding-top: var(--size-hd); }

@media screen and (max-width: 768px) {
  .post-detail .deourl-top {
    position: relative;
    top: 0; } }

.dpost {
  background: #f8f8f8; }
  .dpost .breadcrumb {
    padding: 1.2rem 0; }
  .dpost .deourl-top {
    margin-top: 1.6rem; }
  .dpost .tech-flex {
    flex-direction: row-reverse; }
  .dpost-ctn {
    padding: 4rem 0; }
    .dpost-ctn-main {
      width: calc((8/12) * 100%);
      margin: 0 auto; }
      @media screen and (max-width: 1200px) {
        .dpost-ctn-main {
          width: calc((10/12) * 100%); } }
      @media screen and (max-width: 992px) {
        .dpost-ctn-main {
          width: 100%; } }
      .dpost-ctn-main .mona-content {
        font-family: "Inter"; }
    .dpost-ctn-title {
      font-size: 3.6rem;
      font-weight: 700;
      color: #374151; }
      @media only screen and (max-width: 1200px) {
        .dpost-ctn-title {
          font-size: 3.1rem; } }
      @media only screen and (max-width: 800px) {
        .dpost-ctn-title {
          font-size: 2.6rem; } }
      @media only screen and (max-width: 500px) {
        .dpost-ctn-title {
          font-size: 2.3rem; } }
    .dpost-ctn .wp-block-image figure {
      width: 100%;
      border-radius: 1.2rem;
      overflow: hidden;
      position: relative;
      padding-top: calc((451/800) * 100%); }
      .dpost-ctn .wp-block-image figure img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .dpost-link {
    display: flex;
    padding-top: 2rem;
    align-items: center;
    gap: 2.4rem;
    border-top: 0.1rem solid #E5E5E5;
    margin-top: 4rem; }
    .dpost-link-list {
      display: flex;
      gap: 1rem; }
    .dpost-link-item {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      width: 3.2rem;
      height: 3.2rem; }
      .dpost-link-item img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: 0.3s linear; }
      @media (hover: hover) {
        .dpost-link-item:hover img {
          transform: translateY(-0.5rem); } }

.swhy {
  padding-top: 4rem;
  overflow: hidden; }
  .swhy-flex {
    display: flex;
    margin: 0 -1.6rem;
    margin-top: 4rem; }
    @media screen and (max-width: 1100px) {
      .swhy-flex {
        margin: 0 -0.4rem;
        margin-top: 3rem; } }
    @media screen and (max-width: 768px) {
      .swhy-flex {
        flex-direction: column;
        row-gap: 1rem; } }
  .swhy-left {
    padding: 0 1.6rem;
    width: 50%; }
    @media screen and (max-width: 1100px) {
      .swhy-left {
        padding: 0 0.4rem; } }
    @media screen and (max-width: 768px) {
      .swhy-left {
        width: 100%; } }
  .swhy-right {
    width: 50%;
    padding: 0 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem; }
    @media screen and (max-width: 1100px) {
      .swhy-right {
        padding: 0 0.4rem; } }
    @media screen and (max-width: 768px) {
      .swhy-right {
        width: 100%; } }
  .swhy-vi {
    width: 100%;
    position: relative;
    border-radius: 1.2rem;
    overflow: hidden;
    display: flex;
    padding-top: 100%;
    border-radius: 1.2rem;
    transition: 0s !important; }
    .swhy-vi video,
    .swhy-vi iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: contain; }
    .swhy-vi img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s ease-in-out; }
    @media (hover: hover) {
      .swhy-vi:hover img {
        transform: translate(-50%, -50%) scale(1.05); } }
    .swhy-vi.tabPanel:not(:first-child) {
      display: none; }
  .swhy-desc-item {
    margin-bottom: 3.2rem;
    cursor: pointer;
    position: relative; }
    @media screen and (max-width: 768px) {
      .swhy-desc-item {
        margin-bottom: 1.2rem; } }
    .swhy-desc-item:last-child {
      margin-bottom: 0; }
    .swhy-desc-item.tabBtn:not(.active) .text {
      display: none; }
    .swhy-desc-item.active .title:before {
      opacity: 1;
      transform: translateX(0);
      visibility: visible; }
    .swhy-desc-item.active .title {
      padding-left: 2rem; }
  .swhy-desc .title {
    font-size: 2.8rem;
    color: #F49712;
    font-weight: 700;
    position: relative;
    width: max-content;
    max-width: 100%;
    transition: 0.3s ease-in-out; }
    @media only screen and (max-width: 1200px) {
      .swhy-desc .title {
        font-size: 2.4rem; } }
    @media only screen and (max-width: 800px) {
      .swhy-desc .title {
        font-size: 2.1rem; } }
    .swhy-desc .title:before {
      content: ">";
      position: absolute;
      left: 0;
      top: 0.5rem;
      color: #F49712;
      font-weight: 700;
      font-size: 2.2rem;
      transition: 0.3s ease-in-out;
      transform: translateX(0.5rem);
      opacity: 0;
      visibility: hidden; }
      @media only screen and (max-width: 1200px) {
        .swhy-desc .title:before {
          font-size: 2rem; } }
      @media only screen and (max-width: 800px) {
        .swhy-desc .title:before {
          font-size: 1.9rem; } }
      @media screen and (max-width: 768px) {
        .swhy-desc .title:before {
          top: 0; } }
  .swhy-desc .text {
    color: #111927;
    font-size: 2rem; }
    @media only screen and (max-width: 1200px) {
      .swhy-desc .text {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 800px) {
      .swhy-desc .text {
        font-size: 1.6rem; } }

.safe {
  padding-top: 6rem; }
  @media screen and (max-width: 768px) {
    .safe {
      padding-top: 4rem; } }
  .safe-wrap {
    padding: 3.4rem 0;
    background-color: #fef5e7; }
  .safe-item {
    display: flex;
    flex-direction: column;
    gap: 2rem; }
  .safe .pcontact-img {
    width: 100%;
    padding-top: calc((320 / 480) * 100%); }
  .safe-name {
    color: #000;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-in-out; }
  .safe-slide-inner {
    position: relative; }
    @media screen and (max-width: 768px) {
      .safe-slide-inner {
        padding-bottom: 3rem; } }
  .safe-pagi {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
    @media screen and (max-width: 768px) {
      .safe-pagi {
        display: block !important; } }
    .safe-pagi .swiper-pagination {
      position: relative;
      inset: 0; }
  @media screen and (max-width: 768px) {
    .safe .pcontact-btn {
      display: none; } }
  .safe .swiper-slide-active .pcontact-img {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; }
  .safe .swiper-slide-active .safe-name {
    opacity: 1;
    visibility: visible; }

.lnews-wrap {
  padding: 6rem 0; }
  @media screen and (max-width: 768px) {
    .lnews-wrap {
      padding: 4rem 0; } }

.lnews-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  margin-top: 3rem; }
  @media screen and (max-width: 1100px) {
    .lnews-list {
      grid-column-gap: 1rem;
      grid-row-gap: 2rem; } }
  @media screen and (max-width: 768px) {
    .lnews-list {
      display: flex;
      flex-wrap: wrap;
      row-gap: 2rem;
      margin: 0 -0.5rem;
      margin-top: 2rem;
      grid-column-gap: 0; } }

.lnews-item {
  display: flex;
  flex-direction: column;
  gap: 0.8rem; }
  @media screen and (min-width: 768.99px) {
    .lnews-item:nth-child(2), .lnews-item:nth-child(3), .lnews-item:nth-child(4), .lnews-item:nth-child(5), .lnews-item:nth-child(7), .lnews-item:nth-child(8), .lnews-item:nth-child(9), .lnews-item:nth-child(10) {
      flex-direction: row; } }
  @media screen and (min-width: 768.99px) and (min-width: 1200px) {
    .lnews-item:nth-child(2), .lnews-item:nth-child(3), .lnews-item:nth-child(4), .lnews-item:nth-child(5), .lnews-item:nth-child(7), .lnews-item:nth-child(8), .lnews-item:nth-child(9), .lnews-item:nth-child(10) {
      gap: 2.5rem; } }
  @media screen and (min-width: 768.99px) {
      .lnews-item:nth-child(2) .lnews-img, .lnews-item:nth-child(3) .lnews-img, .lnews-item:nth-child(4) .lnews-img, .lnews-item:nth-child(5) .lnews-img, .lnews-item:nth-child(7) .lnews-img, .lnews-item:nth-child(8) .lnews-img, .lnews-item:nth-child(9) .lnews-img, .lnews-item:nth-child(10) .lnews-img {
        width: 40%; }
      .lnews-item:nth-child(2) .lnews-desc, .lnews-item:nth-child(3) .lnews-desc, .lnews-item:nth-child(4) .lnews-desc, .lnews-item:nth-child(5) .lnews-desc, .lnews-item:nth-child(7) .lnews-desc, .lnews-item:nth-child(8) .lnews-desc, .lnews-item:nth-child(9) .lnews-desc, .lnews-item:nth-child(10) .lnews-desc {
        justify-content: center;
        gap: 0; }
      .lnews-item:nth-child(2) .lnews-name, .lnews-item:nth-child(3) .lnews-name, .lnews-item:nth-child(4) .lnews-name, .lnews-item:nth-child(5) .lnews-name, .lnews-item:nth-child(7) .lnews-name, .lnews-item:nth-child(8) .lnews-name, .lnews-item:nth-child(9) .lnews-name, .lnews-item:nth-child(10) .lnews-name {
        font-size: 1.6rem; }
      .lnews-item:nth-child(2) .lnews-txt, .lnews-item:nth-child(3) .lnews-txt, .lnews-item:nth-child(4) .lnews-txt, .lnews-item:nth-child(5) .lnews-txt, .lnews-item:nth-child(7) .lnews-txt, .lnews-item:nth-child(8) .lnews-txt, .lnews-item:nth-child(9) .lnews-txt, .lnews-item:nth-child(10) .lnews-txt {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; } }
  .lnews-item:nth-child(1) {
    grid-area: 1 / 1 / 5 / 3; }
    .lnews-item:nth-child(1) .lnews-img .box {
      padding-top: calc((490/800) * 100%); }
  .lnews-item:nth-child(2) {
    grid-area: 1 / 3 / 2 / 4; }
  .lnews-item:nth-child(3) {
    grid-area: 2 / 3 / 3 / 4; }
  .lnews-item:nth-child(4) {
    grid-area: 3 / 3 / 4 / 4; }
  .lnews-item:nth-child(5) {
    grid-area: 4 / 3 / 5 / 4; }
  .lnews-item:nth-child(6) {
    grid-area: 5 / 2 / 9 / 4; }
    .lnews-item:nth-child(6) .lnews-img .box {
      padding-top: calc((490/800) * 100%); }
  .lnews-item:nth-child(7) {
    grid-area: 5 / 1 / 6 / 2; }
  .lnews-item:nth-child(8) {
    grid-area: 6 / 1 / 7 / 2; }
  .lnews-item:nth-child(9) {
    grid-area: 7 / 1 / 8 / 2; }
  .lnews-item:nth-child(10) {
    grid-area: 8 / 1 / 9 / 2; }
  @media screen and (max-width: 768px) {
    .lnews-item {
      padding: 0 0.5rem; }
      .lnews-item:not(:nth-child(1)) {
        width: 50%; }
      .lnews-item:nth-child(1) {
        width: 100%; }
      .lnews-item:nth-child(6) {
        width: 100%; } }

.lnews-img .box {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 1.2rem;
  padding-top: 100%; }
  .lnews-img .box img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s ease-in-out; }
  @media (hover: hover) {
    .lnews-img .box:hover img {
      transform: translate(-50%, -50%) scale(1.05); } }

.lnews-desc {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  flex: 1; }

.lnews-date {
  color: #F49712; }

.lnews-name {
  font-size: 2.4rem;
  font-weight: 700;
  color: #383838;
  transition: 0.3s ease-in-out;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media only screen and (max-width: 1200px) {
    .lnews-name {
      font-size: 2rem; } }
  @media only screen and (max-width: 800px) {
    .lnews-name {
      font-size: 1.7rem; } }
  @media (hover: hover) {
    .lnews-name:hover {
      color: #F49712; } }

.lnews-txt {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.4rem; }

.lnews-btn {
  margin-top: 4rem; }
  .lnews-btn .btn, .lnews-btn .btn-pri, .lnews-btn .btn-bd, .lnews-btn .btn-second, .lnews-btn .btn-thir, .lnews-btn .btn-four {
    margin: 0 auto; }

#ez-toc-container {
  font-family: "Univers-Light-Normal";
  width: 100% !important;
  border-radius: 12px !important;
  background: #E6F1F2 !important;
  border: none !important; }
  #ez-toc-container .ez-toc-title {
    font-weight: 700 !important;
    color: #F49712; }

.news-wrap {
  background: #FCFAF6;
  padding: 6rem 0; }
  @media screen and (max-width: 768px) {
    .news-wrap {
      padding: 4rem 0; } }

.news-top-tag {
  border-radius: 0.8rem;
  background: #FEF5E7;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  color: #4B5563;
  height: 7rem;
  padding: 1rem 2.4rem;
  width: max-content;
  font-weight: 700;
  font-size: 2rem;
  margin: 0 auto; }
  @media only screen and (max-width: 1200px) {
    .news-top-tag {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 800px) {
    .news-top-tag {
      font-size: 1.6rem; } }

.news-pagi {
  margin-top: 3rem;
  padding: 3rem 0;
  border-top: 0.1rem solid  #EAECF0; }

.pro {
  padding: 6rem 0; }
  @media screen and (max-width: 768px) {
    .pro {
      padding: 4rem 0; } }
  .pro-top {
    padding-top: 2rem; }
    @media screen and (min-width: 1200px) {
      .pro-top {
        width: calc((11/12) * 100%);
        margin: 0 auto; } }
  .pro-form {
    padding: 1.2rem;
    border-radius: 0.8rem;
    background: #FFF;
    box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.1), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    border: 0.1rem solid #D0D5DD; }
    .pro-form-list {
      display: flex;
      margin: 0  -1.6rem; }
      .pro-form-list .ip-control {
        width: calc(100% / 3);
        padding: 0 1.6rem; }
        @media screen and (max-width: 1100px) {
          .pro-form-list .ip-control {
            padding: 0 0.5rem; } }
      @media screen and (max-width: 1100px) {
        .pro-form-list {
          margin: 0 -0.5rem; } }
      @media screen and (max-width: 768px) {
        .pro-form-list {
          flex-wrap: wrap;
          row-gap: 1rem;
          justify-content: center; }
          .pro-form-list .ip-control {
            width: 50%; } }
      @media screen and (max-width: 550px) {
        .pro-form-list .ip-control {
          width: 100%; } }
      .pro-form-list .btn, .pro-form-list .btn-pri, .pro-form-list .btn-bd, .pro-form-list .btn-second, .pro-form-list .btn-thir, .pro-form-list .btn-four {
        border-radius: 0.8rem;
        height: 4.8rem; }
  .pro-main {
    padding-top: 4rem; }
  .pro-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.6rem;
    padding-top: 4rem;
    padding-bottom: 1rem;
    row-gap: 4rem; }
    @media screen and (max-width: 1100px) {
      .pro-list {
        margin: 0 -0.5rem;
        row-gap: 2rem; } }
  .pro-item {
    width: 25%;
    padding: 0 1.6rem; }
    @media screen and (max-width: 1100px) {
      .pro-item {
        padding: 0 0.5rem; } }
    @media screen and (max-width: 992px) {
      .pro-item {
        width: calc(100% / 3); } }
    @media screen and (max-width: 768px) {
      .pro-item {
        width: 50%; } }
  .pro-box {
    border-radius: 1.2rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 0.1rem solid  #E5E7EB;
    background: #FAFAFA;
    height: 100%; }
  .pro-img {
    position: relative; }
    .pro-img .box {
      position: relative;
      overflow: hidden;
      padding-top: calc((205/280) * 100%);
      display: block; }
      .pro-img .box img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s ease-in-out; }
      @media (hover: hover) {
        .pro-img .box:hover img {
          transform: translate(-50%, -50%) scale(1.05); } }
    .pro-img-tag {
      position: absolute;
      left: 0;
      top: 1rem;
      background: #F49712;
      border-radius: 0 0.4rem 0.4rem 0;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      line-height: 1;
      padding: 0.5rem 1rem;
      z-index: 1;
      color: white;
      font-size: 1.4rem; }
  .pro-desc {
    flex: 1;
    padding: 2rem 1.6rem; }
    @media screen and (max-width: 768px) {
      .pro-desc {
        padding: 1.6rem 1rem; } }
  .pro-name {
    color: #374151;
    font-size: 1.8rem;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    padding-bottom: 1.2rem;
    display: block; }
    @media only screen and (max-width: 1200px) {
      .pro-name {
        font-size: 1.6rem; } }
    @media only screen and (max-width: 800px) {
      .pro-name {
        font-size: 1.5rem; } }
    @media (hover: hover) {
      .pro-name:hover {
        color: #F49712; } }
  .pro-more {
    display: flex;
    flex-direction: column;
    gap: 1.2rem; }
    .pro-more-item {
      display: flex;
      gap: 0.8rem; }
      .pro-more-item .icon {
        width: 2rem;
        height: 2rem;
        flex-shrink: 0;
        display: flex; }
        .pro-more-item .icon img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .pro-more-item .txt {
        line-height: 1;
        color: #1F2937;
        font-size: 1.4rem;
        display: flex;
        align-items: center; }
  .pro .news-pagi {
    padding-bottom: 0; }

.banner.aban .banner-desc {
  align-items: flex-start;
  width: 50%;
  margin-right: auto; }
  @media screen and (max-width: 992px) {
    .banner.aban .banner-desc {
      width: 80%; } }
  @media screen and (max-width: 768px) {
    .banner.aban .banner-desc {
      width: 100%; } }
  .banner.aban .banner-desc br {
    content: "";
    display: block;
    margin: 1.5rem 0; }

.about-ctn {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  gap: 6rem; }
  @media screen and (max-width: 768px) {
    .about-ctn {
      row-gap: 4rem; } }

.about-flex {
  display: flex;
  margin: 0 -1.6rem; }
  .about-flex:nth-child(even) {
    flex-direction: row-reverse; }
    @media screen and (max-width: 768px) {
      .about-flex:nth-child(even) {
        flex-direction: column; } }
  @media screen and (max-width: 768px) {
    .about-flex {
      flex-direction: column;
      row-gap: 2rem; } }

.about-left {
  padding: 0 1.6rem;
  width: 50%; }
  @media screen and (max-width: 768px) {
    .about-left {
      width: 70%;
      margin: 0 auto; } }
  @media screen and (max-width: 600px) {
    .about-left {
      width: 85%; } }
  @media screen and (max-width: 500px) {
    .about-left {
      width: 100%; } }

.about-right {
  padding: 0 1.6rem;
  flex: 1; }
  @media screen and (max-width: 768px) {
    .about-right {
      width: 100%; } }
  .about-right .title {
    color: #374151;
    font-size: 3.6rem;
    font-weight: 700;
    text-transform: uppercase; }
    @media only screen and (max-width: 1200px) {
      .about-right .title {
        font-size: 3.1rem; } }
    @media only screen and (max-width: 800px) {
      .about-right .title {
        font-size: 2.6rem; } }
    @media only screen and (max-width: 500px) {
      .about-right .title {
        font-size: 2.3rem; } }
  .about-right .desc {
    font-weight: 500;
    color: #101828;
    font-size: 2.2rem;
    font-family: 'Univers-Light-Normal'; }
    @media only screen and (max-width: 1200px) {
      .about-right .desc {
        font-size: 2rem; } }
    @media only screen and (max-width: 800px) {
      .about-right .desc {
        font-size: 1.9rem; } }
    @media screen and (max-width: 768px) {
      .about-right .desc {
        font-size: 1.8rem; } }
  @media only screen and (max-width: 768px) and (max-width: 1200px) {
    .about-right .desc {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 768px) and (max-width: 800px) {
    .about-right .desc {
      font-size: 1.5rem; } }

.about-img {
  position: relative;
  padding-top: calc((351/592) * 100%);
  overflow: hidden;
  border-radius: 1.2rem; }
  .about-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s ease-in-out; }
  @media (hover: hover) {
    .about-img:hover img {
      transform: translate(-50%, -50%) scale(1.05); } }

.about-map {
  margin-bottom: 2rem; }
  .about-map-inner {
    margin-top: 1rem; }
  .about-map-img {
    margin: 0 auto; }
    @media screen and (min-width: 768px) {
      .about-map-img {
        width: calc((10/12) * 100%); } }
    .about-map-img img {
      width: 100%;
      object-fit: contain; }

.no-scroll {
  overflow-y: hidden !important; }

.event-none {
  pointer-events: none; }

.f-title {
  font-family: 'Alumni Sans', sans-serif; }

.t-end {
  text-align: end; }

.o-hidden {
  overflow: hidden; }

.c-pri {
  color: #374151; }

.c-second {
  color: #F49712; }

.c-four {
  color: #111927; }

.c-five {
  color: #f05123; }

.c-text {
  color: #121212; }

.c-white {
  color: #ffffff; }

.c-black {
  color: #000000; }

.c-grey {
  color: #344054; }

.c-red {
  color: #E52346; }

.c-blue {
  color: #1454D3; }

.c-green {
  color: #0BAE62; }

.fw-4 {
  font-weight: 400; }

.d-block {
  display: block; }

.d-flex {
  display: flex; }

.j-beweent {
  justify-content: space-between; }

.flex-end {
  justify-content: flex-end; }

.flex-center {
  justify-content: center; }

.align-center {
  align-items: center; }

.d-none {
  display: none; }

.c-gap-1 {
  column-gap: 1rem; }

.btnc {
  cursor: pointer; }

.btn.trans, .trans.btn-pri, .trans.btn-bd, .trans.btn-second, .trans.btn-thir, .trans.btn-four {
  border: 0.1rem solid #374151; }

.mt-24 {
  margin-top: 2.4rem; }

.mt-50 {
  margin-top: 5rem; }
  @media screen and (max-width: 768px) {
    .mt-50 {
      margin-top: 3rem; } }

.m-auto {
  margin: 0 auto; }

.mt-10 {
  margin-top: 1rem; }

.mt-12 {
  margin-top: 1.2rem; }

.mt-15 {
  margin-top: 1.5rem; }

.mt-20 {
  margin-top: 2rem; }

.mt-30 {
  margin-top: 3rem; }

.mt-40 {
  margin-top: 4rem; }
  @media screen and (max-width: 768px) {
    .mt-40 {
      margin-top: 2rem; } }

.mt-60 {
  margin-top: 6rem; }
  @media screen and (max-width: 768px) {
    .mt-60 {
      margin-top: 3rem; } }

.mt-80 {
  margin-top: 8rem; }
  @media screen and (max-width: 1024px) {
    .mt-80 {
      margin-top: 4rem; } }

.mt-100 {
  margin-top: 10rem; }
  @media screen and (max-width: 1024px) {
    .mt-100 {
      margin-top: 6rem; } }

.mb-15 {
  margin-bottom: 1.5rem; }

.mb-50 {
  margin-bottom: 5rem; }

.mb-60 {
  margin-bottom: 6rem; }
  @media screen and (max-width: 768px) {
    .mb-60 {
      margin-bottom: 3rem; } }

.mb-100 {
  margin-bottom: 10rem; }
  @media screen and (max-width: 768px) {
    .mb-100 {
      margin-bottom: 5rem; } }

.pt-30 {
  padding-top: 3rem; }

.pt-10 {
  padding-top: 1rem; }

.pb-200 {
  padding-bottom: 20rem; }

.pb-250 {
  padding-bottom: 25rem; }
  @media screen and (max-width: 768px) {
    .pb-250 {
      padding-bottom: 20rem; } }

.sec-py {
  padding: 10rem 0; }
  @media screen and (max-width: 1300px) {
    .sec-py {
      padding: 8rem 0; } }
  @media screen and (max-width: 1024px) {
    .sec-py {
      padding: 6rem 0; } }
  @media screen and (max-width: 768px) {
    .sec-py {
      padding: 4rem 0; } }

a.under {
  position: relative; }
  a.under:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 0.1rem;
    width: 0;
    transition: 0.3s ease-in-out;
    background-color: black; }
  @media (hover: hover) {
    a.under:hover {
      color: black; }
      a.under:hover:before {
        width: 100%; } }

.btn.bg-sec, .bg-sec.btn-pri, .bg-sec.btn-bd, .bg-sec.btn-second, .bg-sec.btn-thir, .bg-sec.btn-four {
  background-color: #F49712;
  transition: 0.3s ease-in-out; }
  .btn.bg-sec .text, .bg-sec.btn-pri .text, .bg-sec.btn-bd .text, .bg-sec.btn-second .text, .bg-sec.btn-thir .text, .bg-sec.btn-four .text {
    color: #374151;
    transition: 0.3s ease-in-out; }
  .btn.bg-sec:hover .text, .bg-sec.btn-pri:hover .text, .bg-sec.btn-bd:hover .text, .bg-sec.btn-second:hover .text, .bg-sec.btn-thir:hover .text, .bg-sec.btn-four:hover .text {
    color: #fff; }

.t36 {
  font-size: 3.6rem; }
  @media screen and (max-width: 1200px) {
    .t36 {
      font-size: 2.8rem; } }

.t52 {
  font-size: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .t52 {
      font-size: 3.5rem; } }

.t64 {
  font-size: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .t64 {
      font-size: 4.8rem; } }

.none-events {
  pointer-events: none; }

.hv-item {
  position: relative;
  overflow: hidden; }

@media (hover: hover) {
  .hv-item:hover::before {
    -webkit-animation: shine 1.25s;
    animation: shine 1.25s; } }

.hv-item::before {
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg); }

.ip-control {
  width: 100%;
  position: relative; }
  .ip-control.x2 {
    width: 50%; }
  .ip-control.x3 {
    width: calc(100% / 3); }

.wp-block-embed__wrapper iframe {
  width: 100%;
  height: 40rem; }
  @media screen and (max-width: 768px) {
    .wp-block-embed__wrapper iframe {
      height: 30rem; } }

.ourl-top-txt {
  margin-top: 2rem;
  text-align: center; }

.fixed-nav {
  margin-right: 1.1rem !important; }

@media screen and (max-width: 768px) {
  .post-detail .dpost {
    overflow: hidden; } }
