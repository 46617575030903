.pro {
    padding: 6rem 0;

    @include max(768px) {
        padding: 4rem 0;
    }
    &-top {
        padding-top: 2rem;

        @include min(1200px) {
            width:calc((11/12) * 100%);
            margin: 0 auto;
        }
    }
    &-form {
        padding:  1.2rem;
        border-radius: 0.8rem;
        background: #FFF;
        box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.10), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
        border: 0.1rem solid #D0D5DD;
        &-list {
            display: flex;
            margin: 0  -1.6rem;

            .ip-control {
                width: calc(100% / 3);
                padding: 0 1.6rem;

                @include max(1100px) {
                    padding: 0 0.5rem;
                }
            }

            @include max(1100px) {
                margin: 0 -0.5rem;
            }
            @include max(768px) {
                flex-wrap: wrap;
                row-gap: 1rem;
                justify-content:center;
                .ip-control {
                    width: 50%;
                }
            }
            @include max(550px) {
                .ip-control {
                    width: 100%;
                }
            }

            

            .btn {
                border-radius: 0.8rem;
                height: 4.8rem;
            }
        }
    }
    &-main {
        padding-top: 4rem;
    }
    &-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1.6rem;
        padding-top: 4rem;
        padding-bottom: 1rem;
        row-gap: 4rem;

        @include max(1100px) {
            margin: 0 -0.5rem;
            row-gap: 2rem;
        }
    }
    &-item {
        width: 25%;
        padding: 0 1.6rem;

        @include max(1100px) {
            padding: 0 0.5rem;
        }
        @include max(992px) {
            width: calc(100% / 3);
        }
        @include max(768px) {
            width: 50%;
        }
    }
    &-box {
        border-radius: 1.2rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border: 0.1rem solid  #E5E7EB;
        background: #FAFAFA;
        height: 100%;

    }
    &-img {
        position: relative;
        .box {
            position: relative;
            overflow: hidden;
            padding-top: calc((205/280) * 100%);
            display: block;

            img {
                @include imgCover;
                transition: 0.3s ease-in-out;
            }

            @include hover {
                img {
                    transform: translate(-50%,-50%) scale(1.05);
                }
            }
        }
        &-tag {
            position: absolute;
            left: 0;
            top: 1rem;
            background:$color-second;
            border-radius: 0 0.4rem 0.4rem 0;
            @include mid-flex;line-height: 1;
            padding: 0.5rem 1rem;
            z-index: 1;
            color:white;
            @include fz-14;
        }
    }
    &-desc {
        flex: 1;
        padding: 2rem 1.6rem;
        @include max(768px) {
            padding: 1.6rem 1rem;
        }
    }
    &-name {
        color: #374151;
        @include fz-18;
        font-weight: 700;
        transition:0.3s ease-in-out;
        padding-bottom: 1.2rem;display: block;

        @include hover{
            color: $color-second;
        }
    }
    &-more {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        &-item {
            display: flex;
            gap: 0.8rem;

            .icon {
                width: 2rem;
                height: 2rem;
                flex-shrink: 0;
                display: flex;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .txt {
                line-height: 1;
                color: #1F2937;
                @include fz-14;
                display: flex;
                align-items: center;
            }
        }
    }
    .news-pagi {
        padding-bottom: 0;
    }
}